import { ActionContext } from 'vuex'
import omit from 'lodash/omit'
import { IFetchQuery, IDashboardState, IDashboardFilters } from './interfaces'
import { IRootState } from '@/interfaces'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from '@/modules/dashboard/rules'
import router from '@/router'

const fetchQuery = (state: IDashboardState) => {
  const query: IFetchQuery = {}

  if (state.filters.dateRange) {
    query.from = state.filters.dateRange[0]
    query.to = state.filters.dateRange[1]
  }

  if (state.filters.organisationIds) {
    query.organisationIds = state.filters.organisationIds
  }

  if (state.filters.materialTypeCodes) {
    query.materialTypeCodes = state.filters.materialTypeCodes
  }

  if (state.filters.isShippingIncluded) {
    query.isShippingIncluded = state.filters.isShippingIncluded
  }

  if (state.filters.transactionType) {
    query.transactionType = state.filters.transactionType
  }

  if (state.filters.contractType) {
    query.contractType = state.filters.contractType
  }

  return query
}

export const actions = {
  async fetchFiltersFromQuery(
    { commit }: ActionContext<IDashboardState, IRootState>,
    query: IDashboardFilters
  ): Promise<void> {
    await commit('SET_FILTERS', filterNormalizer(query, normalizeFilterRules))
  },
  async fetchMaterialTypeCodeFilterItems(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    await commit('SET_IS_MATERIAL_TYPE_CODES_FILTER_ITEMS_LOADING', true)

    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/materials`,
        method: HttpMethod.Get,
        query: { ...omit(fetchQuery(state), 'materialTypeCodes') },
      })

      await commit(
        'SET_MATERIAL_TYPE_CODES_FILTER_ITEMS_DATA',
        response.results
      )
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }

    await commit('SET_IS_MATERIAL_TYPE_CODES_FILTER_ITEMS_LOADING', false)
  },
  async fetchPartnerFilterItems(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    await commit('SET_IS_PARTNER_FILTER_ITEMS_LOADING', true)

    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/partners`,
        method: HttpMethod.Get,
        query: { ...omit(fetchQuery(state), 'organisationIds') },
      })

      await commit('SET_PARTNER_FILTER_ITEMS_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }

    await commit('SET_IS_PARTNER_FILTER_ITEMS_LOADING', false)
  },
  async fetchAllCharts({
    rootState,
    commit,
    dispatch,
  }: ActionContext<IDashboardState, IRootState>): Promise<any> {
    await commit('SET_IS_LOADING', true)
    try {
      const organisationId =
        router.currentRoute.params.id || rootState.auth.user.organisationId
      await Promise.all([
        dispatch('fetchMaterialTypeCodeFilterItems', { organisationId }),
        dispatch('fetchPartnerFilterItems', { organisationId }),
        dispatch('fetchAmountPerMonth', { organisationId }),
        dispatch('fetchAmountPerOrganisation', { organisationId }),
        dispatch('fetchContractType', { organisationId }),
        dispatch('fetchPricePerMaterialType', { organisationId }),
        dispatch('fetchPricePerRegion', { organisationId }),
        dispatch('fetchPricePerPartner', { organisationId }),
      ])
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
    await commit('SET_IS_LOADING', false)
  },
  async fetchAmountPerMonth(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/amounts/monthly`,
        method: HttpMethod.Get,
        query: { ...fetchQuery(state) },
      })
      await commit('SET_AMOUNT_PER_MONTH_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
  },
  async fetchAmountPerOrganisation(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/amounts/partner`,
        method: HttpMethod.Get,
        query: { ...fetchQuery(state) },
      })
      await commit('SET_AMOUNT_PER_ORGANISATION_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
  },
  async fetchContractType(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/amounts/contract-type`,
        method: HttpMethod.Get,
        query: { ...fetchQuery(state) },
      })
      await commit('SET_CONTRACT_TYPE_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
  },
  async fetchPricePerMaterialType(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/price-material-type`,
        method: HttpMethod.Get,
        query: { ...fetchQuery(state) },
      })
      await commit('SET_PRICE_PER_MATERIAL_TYPE_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
  },
  async fetchPricePerRegion(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    if (!state.filters.materialTypeCodes) return

    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/price-regions`,
        method: HttpMethod.Get,
        query: { ...fetchQuery(state) },
      })
      await commit('SET_PRICE_PER_REGION_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
  },
  async fetchPricePerPartner(
    { commit, state }: ActionContext<IDashboardState, IRootState>,
    { organisationId }: { organisationId: string }
  ): Promise<void> {
    if (!state.filters.materialTypeCodes) return

    try {
      const response = await HttpService.request({
        path: `/dashboards/${organisationId}/price-partners`,
        method: HttpMethod.Get,
        query: { ...fetchQuery(state) },
      })
      await commit('SET_PRICE_PER_PARTNER_DATA', response.results)
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
  },
}
