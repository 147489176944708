import {
  IAddAgreementAttemptPayload,
  IAgreementAttempt,
  IContract,
  IContractState,
  ISetAgreementAttemptsPayload,
} from './state'
import { IBidAttemptOptions } from './actions'
import { IBid } from '@/interfaces'
import { BidStatus } from '@/enums'

export const mutations = {
  ADD_CONTRACT: (state: IContractState, contract: IContract) => {
    state.contracts = [...state.contracts, contract]
  },
  SET_CONTRACTS: (state: IContractState, contracts: IContract[]) => {
    state.contracts = contracts
  },
  SET_CONTRACTS_ADMIN: (state: IContractState, contracts: IContract[]) => {
    state.contractsAdmin = contracts
  },
  ADD_AGREEMENT_ATTEMPT: (
    state: IContractState,
    { contractId, attempt }: IAddAgreementAttemptPayload
  ) => {
    const { agreementAttempts } = state

    state.agreementAttempts = {
      ...agreementAttempts,
      [contractId]: [...(agreementAttempts[contractId] || []), attempt],
    }
  },
  SET_AGREEMENT_ATTEMPTS: (
    state: IContractState,
    { contractId, attempts }: ISetAgreementAttemptsPayload
  ) => {
    state.agreementAttempts = {
      ...state.agreementAttempts,
      [contractId]: attempts,
    }
  },
  SET_AGREEMENT_ATTEMPTS_ADMIN: (
    state: IContractState,
    { contractId, attempts }: ISetAgreementAttemptsPayload
  ) => {
    state.agreementAttemptsAdmin = {
      ...state.agreementAttemptsAdmin,
      [contractId]: attempts,
    }
  },
  CANCEL_BID_AGREEMENT_ATTEMPT: (
    state: IContractState,
    { bid, contractId }: IBidAttemptOptions
  ) => {
    const contractAttempts = state.agreementAttempts[contractId]
    const attempt = contractAttempts.find(
      (attempt: IAgreementAttempt) => (attempt.body as IBid).id === bid.id
    )

    if (attempt) {
      ;(attempt.body as IBid).bidStatus = BidStatus.Cancelled
    }
    state.agreementAttempts[contractId] = [...contractAttempts]
  },
  SET_ERROR: (state: IContractState, error: Error) => {
    state.error = error
  },
  SET_IS_ATTEMPT_LOADING: (
    state: IContractState,
    isAttemptLoading: boolean
  ) => {
    state.isAttemptLoading = isAttemptLoading
  },
  SET_IS_CONTRACTS_LOADING: (
    state: IContractState,
    isContractsLoading: boolean
  ) => {
    state.isContractsLoading = isContractsLoading
  },
  SET_IS_ATTEMPTS_LOADING: (
    state: IContractState,
    isAttemptsLoading: boolean
  ) => {
    state.isAttemptsLoading = isAttemptsLoading
  },
  SET_DEFAULT_STATE: (state: IContractState) => {
    state.contracts = []
    state.agreementAttempts = {}
    state.selectedContractId = null
    state.contractsAdmin = []
    state.agreementAttemptsAdmin = {}
    state.selectedContractIdAdmin = null
  },
  SET_SELECTED_CONTRACT_ID: (
    state: IContractState,
    contractId: string | null
  ) => {
    state.selectedContractId = contractId
  },
  SET_SELECTED_CONTRACT_ID_ADMIN: (
    state: IContractState,
    contractId: string | null
  ) => {
    state.selectedContractIdAdmin = contractId
  },
}
