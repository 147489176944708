import { IUser } from '@/interfaces'
import { i18n } from '@/i18n'

export const getUserFullName = (user?: IUser | null) => {
  if (user?.isAnonymous) {
    return i18n.t('user.anonymousMember')
  }

  if (!user || (!user.firstName && !user.lastName)) {
    return i18n.t('common.unknown')
  }

  const firstName = user.firstName ? `${user.firstName} ` : ''

  return firstName + (user.lastName || '')
}
