import { IUserState } from './interfaces'

export const getDefaultState = (): IUserState => {
  return {
    next: null,
    users: [],
    isLoading: false,
    isLoadingNext: false,
    error: null,
  }
}
