import { IRatingState } from './state'
import { IRelationPartner, IRelationRating } from '@/interfaces'

export const mutations = {
  SET_IS_LOADING: (state: IRatingState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_IS_PUBLIC_RATINGS_LOADING: (state: IRatingState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_IS_TRADING_PARTNERS_LOADING: (
    state: IRatingState,
    isLoading: boolean
  ) => {
    state.isTradingPartnersLoading = isLoading
  },
  SET_IS_OWN_RATING_LOADING: (state: IRatingState, isLoading: boolean) => {
    state.isOwnRatingLoading = isLoading
  },
  SET_OWN_RATING: (state: IRatingState, ownRating: IRelationRating) => {
    state.ownRating = ownRating
  },
  SET_TRADING_PARTNERS: (
    state: IRatingState,
    tableData: IRelationPartner[]
  ) => {
    state.tradingPartners = tableData
  },
  SET_IS_PUBLIC_RATING_LOADING: (state: IRatingState, isLoading: boolean) => {
    state.isPublicRatingLoading = isLoading
  },
  SET_PUBLIC_RATINGS: (
    state: IRatingState,
    publicRatings: IRelationRating[]
  ) => {
    state.publicRatings = publicRatings.reduce(
      (acc, rating) => ({ ...acc, [rating.toOrganisationId]: rating }),
      {}
    )
  },
  SET_ERROR: (state: IRatingState, error: Error) => {
    state.error = error
  },
}
