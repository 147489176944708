





















import { Footer } from '@/enums'
import { PropType } from 'vue'
import { mapGetters } from 'vuex'
import { LocaleSelect } from '@/components/common/LocaleSelect'

export default {
  name: 'FooterComponent',
  components: {
    LocaleSelect,
  },
  inject: ['enums'],
  props: {
    type: {
      type: String as PropType<Footer>,
      default: '',
    },
  },
  data() {
    return {
      footerKeys: ['termsAndConditions', 'privacyPolicy', 'imprint', 'fees'],
    }
  },
  computed: {
    ...mapGetters('auth', {
      isSignedIn: 'getIsSignedIn',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
  },
  methods: {
    getFooterUrl(key: string) {
      const link = this.$t(`common.footerLinks.${key}.url`)
      return `${this.$config.webUrl}/${link}`
    },
  },
}
