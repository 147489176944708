import { ActionContext } from 'vuex'
import {
  IAgreementAttempt,
  IContract,
  IContractState,
  IOffer,
} from '@/modules/contract/state'
import { IRootState, IBid } from '@/interfaces'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { AttemptType } from '@/enums'
import { toUnixTime } from '@/utils/utils'

interface ISaveAgreementAttemptOptions {
  contractId: string | null
  attempt: string
  offer: IOffer
}

export interface IBidAttemptOptions {
  contractId: string
  bid: IBid
}

export const actions = {
  async addContract(
    { commit }: ActionContext<IContractState, IRootState>,
    offerId: string
  ): Promise<void> {
    try {
      const response: IContract = await HttpService.request({
        path: '/contracts',
        method: HttpMethod.Post,
        body: {
          offerId,
        },
      })
      commit('ADD_CONTRACT', response)
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async fetchContracts(
    { commit }: ActionContext<IContractState, IRootState>,
    offer: IOffer
  ): Promise<void> {
    try {
      commit('SET_IS_CONTRACTS_LOADING', true)

      const response: IContract[] = await HttpService.request({
        path: '/contracts',
        query: {
          offerId: offer?.id,
        },
      })

      commit('SET_CONTRACTS', response)
    } catch (error) {
      commit('SET_ERROR', error)
    }

    commit('SET_IS_CONTRACTS_LOADING', false)
  },
  async fetchContractsAdmin(
    { commit }: ActionContext<IContractState, IRootState>,
    offer: IOffer
  ): Promise<void> {
    try {
      commit('SET_IS_CONTRACTS_LOADING', true)

      const response: IContract[] = await HttpService.request({
        path: '/contracts',
        query: {
          offerId: offer?.id,
          admin: true,
        },
      })

      commit('SET_CONTRACTS_ADMIN', response)
    } catch (error) {
      commit('SET_ERROR', error)
    }

    commit('SET_IS_CONTRACTS_LOADING', false)
  },
  async addBidAttempt(
    { commit, rootState }: ActionContext<IContractState, IRootState>,
    { bid, contractId }: IBidAttemptOptions
  ) {
    if (contractId) {
      await commit('ADD_AGREEMENT_ATTEMPT', {
        contractId: contractId,
        attempt: {
          createdAt: toUnixTime(Date.now()),
          type: AttemptType.Bid,
          body: bid,
          sender: rootState.auth.user,
        },
      })
    }
  },
  cancelBidAttempt(
    { commit }: ActionContext<IContractState, IRootState>,
    options: IBidAttemptOptions
  ) {
    commit('CANCEL_BID_AGREEMENT_ATTEMPT', options)
  },
  async addAgreementAttempt(
    { commit, rootState, dispatch }: ActionContext<IContractState, IRootState>,
    options: ISaveAgreementAttemptOptions
  ): Promise<void> {
    if (options.contractId) {
      await commit('ADD_AGREEMENT_ATTEMPT', {
        contractId: options.contractId,
        attempt: {
          createdAt: toUnixTime(Date.now()),
          type: AttemptType.Text,
          body: options.attempt,
          sender: rootState.auth.user,
        },
      })
    }
    await dispatch('saveAgreementAttempt', options)
  },
  async saveAgreementAttempt(
    {
      commit,
      rootState,
      dispatch,
      state,
    }: ActionContext<IContractState, IRootState>,
    options: ISaveAgreementAttemptOptions
  ): Promise<void> {
    try {
      const { organisationId } = rootState.auth.user

      commit('SET_IS_ATTEMPT_LOADING', true)

      const isOfferOwner = Boolean(
        state.contracts.find((contract: IContract) => {
          return contract.offer.organisationId === organisationId
        })
      )

      const hasContract = Boolean(
        state.contracts.find((contract: IContract) => {
          return contract.organisationId === organisationId
        })
      )

      if (!isOfferOwner && !hasContract) {
        await dispatch('addContract', options.offer.id)
      }

      const contract = state.contracts.find((contract: IContract) => {
        return contract.organisationId === organisationId
      })

      if (contract) {
        await dispatch('setSelectedContractId', contract.id)
      }

      const contractId = options.contractId || (contract && contract.id)

      await HttpService.request({
        path: `/contracts/${contractId}/attempts`,
        method: HttpMethod.Post,
        body: {
          type: AttemptType.Text,
          body: options.attempt,
        },
      })
      commit('SET_IS_ATTEMPT_LOADING', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_ATTEMPT_LOADING', false)
    }
  },
  async fetchAgreementAttempts(
    { commit }: ActionContext<IContractState, IRootState>,
    params: { contractId: string; filter?: { type: AttemptType } }
  ): Promise<void> {
    try {
      const type = params.filter?.type
      const query = type ? { type } : undefined

      if (!params.contractId) return

      commit('SET_IS_ATTEMPTS_LOADING', true)
      const response: IAgreementAttempt[] = await HttpService.request({
        path: `/contracts/${params.contractId}/attempts`,
        query,
      })
      commit('SET_AGREEMENT_ATTEMPTS', {
        contractId: params.contractId,
        attempts: response,
      })
      commit('SET_IS_ATTEMPTS_LOADING', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_ATTEMPTS_LOADING', false)
    }
  },
  async fetchAgreementAttemptsAdmin(
    { commit }: ActionContext<IContractState, IRootState>,
    params: { contractId: string }
  ): Promise<void> {
    try {
      const query = {
        type: AttemptType.Bid,
        admin: true,
      }

      commit('SET_IS_ATTEMPTS_LOADING', true)
      const response: IAgreementAttempt[] = await HttpService.request({
        path: `/contracts/${params.contractId}/attempts`,
        query,
      })
      commit('SET_AGREEMENT_ATTEMPTS_ADMIN', {
        contractId: params.contractId,
        attempts: response,
      })
      commit('SET_IS_ATTEMPTS_LOADING', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_ATTEMPTS_LOADING', false)
    }
  },
  setSelectedContractId(
    { commit }: ActionContext<IContractState, IRootState>,
    contractId: string
  ) {
    commit('SET_SELECTED_CONTRACT_ID', contractId)
  },
  setSelectedContractIdAdmin(
    { commit }: ActionContext<IContractState, IRootState>,
    contractId: string
  ) {
    commit('SET_SELECTED_CONTRACT_ID_ADMIN', contractId)
  },
  resetContractsState({ commit }: ActionContext<IContractState, IRootState>) {
    commit('SET_DEFAULT_STATE')
  },
}
