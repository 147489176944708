import { IDashboardFilters, IDashboardState } from './interfaces'

export const mutations = {
  SET_IS_LOADING: (state: IDashboardState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_FILTERS: (state: IDashboardState, filters: IDashboardFilters) => {
    state.filters = filters
  },
  SET_ERROR: (state: IDashboardState, error: Error) => {
    state.error = error
  },
  SET_AMOUNT_PER_MONTH_DATA: (
    state: IDashboardState,
    amountPerMonthChartData: any
  ) => {
    state.amountPerMonthChartData = amountPerMonthChartData
  },
  SET_PARTNER_FILTER_ITEMS_DATA: (
    state: IDashboardState,
    partnerFilterItems: any
  ) => {
    state.partnerFilterItems = partnerFilterItems
  },
  SET_IS_PARTNER_FILTER_ITEMS_LOADING: (
    state: IDashboardState,
    isPartnerFilterItemsLoading: boolean
  ) => {
    state.isPartnerFilterItemsLoading = isPartnerFilterItemsLoading
  },
  SET_MATERIAL_TYPE_CODES_FILTER_ITEMS_DATA: (
    state: IDashboardState,
    materialTypeCodesFilterItems: any
  ) => {
    state.materialTypeCodesFilterItems = materialTypeCodesFilterItems
  },
  SET_IS_MATERIAL_TYPE_CODES_FILTER_ITEMS_LOADING: (
    state: IDashboardState,
    isMaterialTypeCodesFilterItemsLoading: boolean
  ) => {
    state.isMaterialTypeCodesFilterItemsLoading = isMaterialTypeCodesFilterItemsLoading
  },
  SET_AMOUNT_PER_ORGANISATION_DATA: (
    state: IDashboardState,
    amountPerOrganisationChartData: any
  ) => {
    state.amountPerOrganisationChartData = amountPerOrganisationChartData
  },
  SET_CONTRACT_TYPE_DATA: (
    state: IDashboardState,
    contractTypeChartData: any
  ) => {
    state.contractTypeChartData = contractTypeChartData
  },
  SET_PRICE_PER_MATERIAL_TYPE_DATA: (
    state: IDashboardState,
    pricePerMaterialType: any
  ) => {
    state.pricePerMaterialTypeChartData = pricePerMaterialType
  },
  SET_PRICE_PER_REGION_DATA: (state: IDashboardState, pricePerRegion: any) => {
    state.pricePerRegionChartData = pricePerRegion
  },
  SET_PRICE_PER_PARTNER_DATA: (
    state: IDashboardState,
    pricePerPartner: any
  ) => {
    state.pricePerPartnerChartData = pricePerPartner
  },
}
