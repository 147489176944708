































import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    icon: {
      type: String as PropType<string>,
      default: '',
    },
    to: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hasButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit, attrs }) {
    const handleClick = () => {
      emit('click')
    }
    return { handleClick, attrs }
  },
})
