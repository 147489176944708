import { IFilters, IMarketplaceState } from './interfaces'
import { OfferStatus, OfferType } from '@/enums'

export const getDefaultFilters = (): IFilters => {
  return {
    offerType: [OfferType.Buy, OfferType.Sell],
    materialId: '',
    materialTypeId: [],
    materialFormatGroupId: [],
    countryCode: [],
    postCode: '',
    unitAmount: [undefined, undefined],
    expiresAt: null,
    deliveryDate: [undefined, undefined],
    offerStatus: OfferStatus.Open,
    organisationId: [],
    unitPrice: [undefined, undefined],
    isShippingIncluded: undefined,
    radius: null,
    showActive: false,
  }
}

export const getDefaultState = (): IMarketplaceState => {
  return {
    filters: getDefaultFilters(),
    filterPresets: [],
    activeFilterPresetId: null,
    offers: null,
    next: null,
    isLoading: false,
    isLoadingNext: false,
    isLoadingFilters: false,
    error: null,
  }
}
