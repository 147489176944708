import { ActionContext } from 'vuex'
import {
  IOrganisation,
  IPaymentCondition,
  IRootState,
  IUser,
  IMeta,
} from '@/interfaces'
import { IFetchQuery } from './interfaces'
import {
  IOrganisationState,
  getDefaultFilters,
} from '@/modules/organisation/state'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'
import { ISaveQuery } from '@/utils/PaymentConditionUtil/PaymentConditionUtil'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { OrganisationStatus } from '@/enums'
import { deleteUser } from '@/modules/user/resources'

export interface IQuery {
  status?: OrganisationStatus // organisation status
}

export const actions = {
  async fetchOrganisations(
    { state, commit }: ActionContext<IOrganisationState, IRootState>,
    next?: string | null
  ): Promise<void> {
    try {
      await commit('SET_IS_LOADING', true)

      if (next === undefined) {
        next = null
      } else {
        await commit(next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING', true)
      }

      const fetchQuery = {} as IFetchQuery

      if (state.filters.status) {
        fetchQuery.status = state.filters.status
      }

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: '/organisations',
        })

        return response as {
          organisations: IOrganisation[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next,
        fetchRows,
        rowsKey: 'organisations',
        limit: Number.MAX_SAFE_INTEGER,
      })

      await commit('SET_TABLE_DATA', { tableData, previousNext: next })
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    }
  },
  async fetchFiltersFromQuery(
    { commit }: ActionContext<IOrganisationState, IRootState>,
    query: IQuery
  ): Promise<void> {
    const filters = getDefaultFilters()

    if (query.status) {
      filters.status = query.status
    }

    await commit('SET_FILTERS', filters)
  },
  async fetchOrganisationUsers(
    { commit }: ActionContext<IOrganisationState, IRootState>,
    option: {
      organisationId: string
      next?: string | null
    }
  ): Promise<void> {
    try {
      await commit('SET_USERS_IS_LOADING', true)

      if (option.next === undefined) {
        option.next = null
      } else {
        await commit(
          option.next ? 'SET_USERS_IS_LOADING_NEXT' : 'SET_USERS_IS_LOADING',
          true
        )
      }

      const fetchQuery = {} as IFetchQuery

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: `/organisations/${option.organisationId}/users`,
        })

        return response as {
          users: IUser[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next: option.next,
        fetchRows,
        rowsKey: 'users',
      })

      await commit('SET_USERS_TABLE_DATA', {
        tableData,
        previousNext: option.next,
      })
      await commit('SET_USERS_IS_LOADING', false)
      await commit('SET_USERS_IS_LOADING_NEXT', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_USERS_IS_LOADING', false)
      await commit('SET_USERS_IS_LOADING_NEXT', false)
    }
  },
  async deleteOrganisationUser(
    { commit, dispatch }: ActionContext<IOrganisationState, IRootState>,
    userId: string
  ) {
    await commit('SET_IS_LOADING', true)
    try {
      const user = await deleteUser(userId)
      await dispatch('fetchOrganisationUsers', {
        organisationId: user.organisationId,
      })
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
    }
  },
  async fetchPaymentConditions(
    { commit }: ActionContext<IOrganisationState, IRootState>,
    option: {
      organisationId: string
      next?: string | null
    }
  ): Promise<void> {
    try {
      await commit('SET_PAYMENT_CONDITIONS_IS_LOADING', true)

      if (option.next === undefined) {
        option.next = null
      } else {
        await commit(
          option.next
            ? 'SET_PAYMENT_CONDITIONS_IS_LOADING_NEXT'
            : 'SET_PAYMENT_CONDITIONS_IS_LOADING',
          true
        )
      }

      const fetchQuery = {} as IFetchQuery

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: `/organisations/${option.organisationId}/payment-conditions`,
        })

        return response as {
          paymentConditions: IPaymentCondition[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next: option.next,
        fetchRows,
        rowsKey: 'paymentConditions',
      })

      await commit('SET_PAYMENT_CONDITIONS_TABLE_DATA', {
        tableData,
        previousNext: option.next,
      })
      await commit('SET_PAYMENT_CONDITIONS_IS_LOADING', false)
      await commit('SET_PAYMENT_CONDITIONS_IS_LOADING_NEXT', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_PAYMENT_CONDITIONS_IS_LOADING', false)
      await commit('SET_PAYMENT_CONDITIONS_IS_LOADING_NEXT', false)
    }
  },
  async removePaymentCondition(
    { commit }: ActionContext<IOrganisationState, IRootState>,
    paymentCondition: IPaymentCondition
  ) {
    const response = await HttpService.request({
      path: `/payment-conditions/${paymentCondition.id}`,
      method: HttpMethod.Delete,
    })
    commit('REMOVE_PAYMENT_CONDITION', response)
  },
  async savePaymentCondition(
    { commit }: ActionContext<IOrganisationState, IRootState>,
    query: ISaveQuery
  ) {
    try {
      const response = await HttpService.request({
        path: `/payment-conditions/${query?.id || ''}`,
        method: query?.id ? HttpMethod.Put : HttpMethod.Post,
        body: query,
      })

      await commit(
        `${query?.id ? 'UPDATE' : 'ADD'}_PAYMENT_CONDITION`,
        response
      )

      return response
    } catch (error) {
      await commit('SET_ERROR', error)
    }
  },
  async fetchPartnersOrganisations(
    { commit }: ActionContext<IOrganisationState, IRootState>,
    id: string
  ) {
    try {
      const response = await HttpService.request({
        path: `/organisation/${id}/partners`,
      })
      commit('PARTNERS_ORGANISATIONS', response)
    } catch (error) {
      await commit('SET_ERROR', error)
    }
  },
}
