import { FilterType } from '@/enums'

export const normalizeFilterRules: { [name: string]: any } = {
  dateRange: { type: FilterType.Range },
  organisationIds: { type: FilterType.List },
  materialTypeCodes: { type: FilterType.List },
  isShippingIncluded: { type: FilterType.Boolean },
  contractType: { type: FilterType.Input },
  transactionType: { type: FilterType.Input },
}
