import { Module } from 'vuex'
import {
  IMaterialFormatGroupState,
  getDefaultState,
} from '@/modules/materialFormatGroup/state'
import { IRootState } from '@/interfaces'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'

export const materialFormatGroups: Module<
  IMaterialFormatGroupState,
  IRootState
> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getDefaultState(),
}
