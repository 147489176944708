import { IUser, ISubscription, IMeta, ITableData } from '@/interfaces'
import { CreatedBy } from '@/enums'
import { IFilters, IFetchQuery } from '@/modules/subscription/interfaces'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'
import HttpService from '@/services/HttpService/HttpService'

const fetchSubscriptions = async (query?: IFetchQuery) => {
  const response = await HttpService.request({
    query,
    path: '/subscriptions',
  })

  return response as {
    offers: ISubscription[]
    meta?: IMeta
  }
}

const fetchSubscriptionsTableData = (
  filters: IFilters,
  user: IUser | null,
  next: string | null
): Promise<ITableData> => {
  const fetchQuery = {} as IFetchQuery

  if (filters.offerTypes.length) {
    fetchQuery.offerType = filters.offerTypes.join()
  }

  if (filters.materialTypes.length) {
    fetchQuery.materialTypeId = filters.materialTypes.join()
  }

  if (filters.offerStatus) {
    fetchQuery.offerStatus = filters.offerStatus
  }

  if (filters.createdBy && user) {
    if (filters.createdBy === CreatedBy.User) {
      fetchQuery.userId = user.id
    }
  }

  return getTableData({
    fetchQuery,
    next,
    fetchRows: fetchSubscriptions,
    rowsKey: 'offers',
  })
}

export default { fetchSubscriptions, fetchSubscriptionsTableData }
