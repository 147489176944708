

























import { mapActions } from 'vuex'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'UserPasswordForm',
  components: { TextField },
  data() {
    return {
      isSaveLoading: false,
      formItems: {
        oldPassword: '',
        newPassword: '',
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: this.$t('passwordChange.validation.oldPassword.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\S{6,}$/,
            message: this.$t('passwordChange.validation.oldPassword.pattern'),
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            required: true,
            message: this.$t('passwordChange.validation.newPassword.required'),
            trigger: 'blur',
          },
          {
            pattern: /^\S{6,}$/,
            message: this.$t('passwordChange.validation.newPassword.pattern'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    async validateForm() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.isSaveLoading = true

        try {
          await this.changePassword(this.formItems)
          this.$message.success(this.$t('passwordChange.successMessage'))
          this.isSaveLoading = false
          this.$emit('close-dialog')
        } catch (error) {
          this.isSaveLoading = false
          this.$message.error(error)
        }
      })
    },
  },
}
