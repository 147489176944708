import { IDelivery } from '@/interfaces'
import { IDeliveryState } from './state'
import moment from 'moment-timezone'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

const nest = (
  seq: any[],
  keys: Array<string | ((obj: any) => string)>
): any => {
  if (!keys.length) {
    return seq.length
  }
  const [first, ...rest] = keys

  return mapValues(groupBy(seq, first), (value) => nest(value, rest))
}

export const aggregate = (deliveries: IDelivery[]) =>
  nest(deliveries, [
    (delivery: IDelivery) =>
      moment(delivery.expectedDeliveryDate)
        .tz('Europe/Berlin')
        .format('DD.MM.YYYY'),
    'deliverySyncStatus',
  ])

export const mutations = {
  SET_DELIVERIES: (state: IDeliveryState, deliveries: IDelivery[]) => {
    state.deliveries = deliveries
    state.deliveriesAggregated = aggregate(deliveries)
  },
  SET_DEFAULT_STATE: (state: IDeliveryState) => {
    state.deliveries = []
    state.deliveriesAggregated = {}
  },
  SET_IS_LOADING: (state: IDeliveryState, isDeliveriesLoading: boolean) => {
    state.isLoading = isDeliveriesLoading
  },
  SET_ERROR: (state: IDeliveryState, error: Error) => {
    state.error = error
  },
}
