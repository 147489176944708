






export default {
  name: 'AvatarUploadMaskComponent',
}
