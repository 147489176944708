import { ActionContext } from 'vuex'
import { IRootState } from '@/interfaces'
import { IMaterialTypesState } from './state'
import { materialTypesResource } from './resources'

export const actions = {
  async getMaterialTypes({
    commit,
    state,
  }: ActionContext<IMaterialTypesState, IRootState>): Promise<void> {
    if (state.materialTypes.length > 0) {
      return
    }

    await commit('SET_IS_LOADING', true)
    try {
      await commit('SET_MATERIAL_TYPES', await materialTypesResource())
    } catch (e) {
      await commit('SET_ERROR', new Error(e))
    }
    await commit('SET_IS_LOADING', false)
  },
}
