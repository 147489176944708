import { ActionContext } from 'vuex'
import { IOffer, IRootState, IMeta } from '@/interfaces'
import { IFetchQuery, IFilterPreset, IMarketplaceState } from './interfaces'
import { getDefaultFilters } from './state'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'
import router from '@/router'
import isEqual from 'lodash/isEqual'
import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from '@/modules/marketplace/rules'

export interface IFilterCreate {
  name: string
  emailNotifications: boolean
}

export const actions = {
  async setFiltersFromQuery({
    commit,
    dispatch,
  }: ActionContext<IMarketplaceState, IRootState>) {
    commit(
      'SET_FILTERS',
      Object.assign(getDefaultFilters(), router.currentRoute.query)
    )
    await dispatch('fetchOffers')
  },
  async fetchOffers(
    { commit, state }: ActionContext<IMarketplaceState, IRootState>,
    next?: string | null
  ): Promise<void> {
    if (next === undefined) {
      next = null
    }

    if (!state?.offers?.length) {
      await commit(next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING', true)
    }

    try {
      const fetchQuery: IFetchQuery = filterNormalizer(
        state.filters,
        normalizeFilterRules
      ) as IFetchQuery

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: '/offers',
        })

        const shouldFetchShadow =
          !next &&
          isEqual(state.filters, getDefaultFilters()) &&
          state.filters.showActive === false &&
          response.offers.length < 10

        const shadowOffers = shouldFetchShadow
          ? (
              await HttpService.request({
                query: { shadow: true },
                path: '/offers',
              })
            ).offers
          : []

        return {
          ...response,
          offers: response.offers.concat(shadowOffers),
        } as {
          offers: IOffer[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next,
        fetchRows,
        rowsKey: 'offers',
      })

      const filters = filterNormalizer(state.filters, normalizeFilterRules)
      const activeFilter = state?.filterPresets?.find((filter) =>
        isEqual(filter.settings, filters)
      )

      commit('SET_ACTIVE_FILTER_PRESET', activeFilter?.id || null)
      commit('SET_TABLE_DATA', { tableData, previousNext: next })
      commit('SET_IS_LOADING', false)
      commit('SET_IS_LOADING_NEXT', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_LOADING', false)
      commit('SET_IS_LOADING_NEXT', false)
    }
  },
  async createFilterPreset(
    { commit, state, rootState }: ActionContext<IMarketplaceState, IRootState>,
    { name, emailNotifications }: IFilterCreate
  ): Promise<void> {
    const preset = {
      name,
      settings: filterNormalizer(state.filters, normalizeFilterRules),
      emailNotifications,
    }

    try {
      commit('SET_IS_LOADING_FILTERS', true)
      const response = await HttpService.request({
        path: `/users/${rootState.auth?.user?.id}/filters`,
        method: HttpMethod.Post,
        body: preset,
      })
      commit('ADD_FILTER_PRESET', response)
      commit('SET_ACTIVE_FILTER_PRESET', response.id)
      commit('SET_IS_LOADING_FILTERS', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_LOADING_FILTERS', false)
    }
  },
  async updateFilterPreset(
    { commit, rootState }: ActionContext<IMarketplaceState, IRootState>,
    preset: IFilterPreset
  ): Promise<void> {
    try {
      commit('SET_IS_LOADING_FILTERS', true)
      const response = await HttpService.request({
        path: `/users/${rootState.auth?.user?.id}/filters/${preset.id}`,
        method: HttpMethod.Put,
        body: preset,
      })
      commit('UPDATE_FILTER_PRESET', response)
      commit('SET_IS_LOADING_FILTERS', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_LOADING_FILTERS', false)
    }
  },
  async deleteFilterPreset(
    { commit, rootState }: ActionContext<IMarketplaceState, IRootState>,
    presetId: string
  ): Promise<void> {
    try {
      commit('SET_IS_LOADING_FILTERS', true)
      await HttpService.request({
        path: `/users/${rootState.auth?.user?.id}/filters/${presetId}`,
        method: HttpMethod.Delete,
      })
      commit('DELETE_FILTER_PRESET', presetId)
      commit('SET_IS_LOADING_FILTERS', false)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_LOADING_FILTERS', false)
    }
  },
  async fetchFilterPresets({
    commit,
    state,
    rootState,
  }: ActionContext<IMarketplaceState, IRootState>): Promise<void> {
    try {
      commit('SET_IS_LOADING_FILTERS', true)
      const response = await HttpService.request({
        path: `/users/${rootState.auth?.user?.id}/filters`,
      })
      commit('SET_FILTER_PRESETS', response && response.filters)
      commit('SET_IS_LOADING_FILTERS', false)

      const filters = filterNormalizer(state.filters, normalizeFilterRules)
      const activeFilter = state.filterPresets.find((filter) =>
        isEqual(filter.settings, filters)
      )
      commit('SET_ACTIVE_FILTER_PRESET', activeFilter?.id || null)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_IS_LOADING_FILTERS', false)
    }
  },
  async setActiveFilterPreset(
    {
      commit,
      state,
      dispatch,
      getters,
    }: ActionContext<IMarketplaceState, IRootState>,
    presetId: string | null
  ) {
    commit('SET_ACTIVE_FILTER_PRESET', presetId)
    const preset = state.filterPresets.find((filter) => filter.id === presetId)

    if (preset) {
      commit('SET_FILTERS', Object.assign(getDefaultFilters(), preset.settings))

      if (router.currentRoute.name === 'marketplace') {
        await router.push({
          query: getters.getQueryFromFilters,
        })
      }

      await dispatch('fetchOffers', null)
    }
  },
}
