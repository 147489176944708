import { ITransactionsByOffers, ITransactionState } from './state'
import { ICalculation, ITransaction } from './resources'
import { IOrganisation } from '@/interfaces'

import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from '@/modules/transaction/rules'

export const getters = {
  getTransactions(state: ITransactionState) {
    return state.transactions
  },
  getIsLoading(state: ITransactionState) {
    return state.isLoading
  },
  getError(state: ITransactionState) {
    return state.error
  },
  getIsLoadingNext(state: ITransactionState) {
    return state.isLoadingNext
  },
  getIsLoadingExport(state: ITransactionState) {
    return state.isLoadingExport
  },
  getFiltersFromQuery(state: ITransactionState) {
    return state.filters
  },
  getQueryFromFilters(state: ITransactionState) {
    return filterNormalizer(state.filters, normalizeFilterRules)
  },
  getTableDataNext(state: ITransactionState): string | null {
    return state.next
  },
  getOrganisation(state: ITransactionState): IOrganisation | null {
    return state.organisation
  },
  transactionPreCalculation(state: ITransactionState): ICalculation | null {
    return state.preCalculation?.calculation || null
  },
  offerTransactions(state: ITransactionState): ITransaction[] {
    return state.offerTransactions
  },
  transactionsByOffers(state: ITransactionState): ITransactionsByOffers {
    return state.transactionsByOffers
  },
  isLoadingTransactions(state: ITransactionState): boolean {
    return state.isLoadingTransactions
  },
}
