import { ThemeOptions } from 'vuetify/types/services/theme'

export default {
  light: {
    primary: '#00466e',
    secondary: '#424242',
    error: '#f56c6c',
    info: '#2391e1',
    success: '#00af7d',
    warning: '#e6a23c',
  },
} as ThemeOptions['themes']
