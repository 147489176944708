






















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { VSelect, VAutocomplete } from 'vuetify/lib'

type ICustomValue = string | ((obj: Record<string, any>) => string)

export default defineComponent({
  components: {
    VSelect,
    VAutocomplete,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    // "value" is used for internal use of v-model
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    items: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasSmallText: {
      type: Boolean,
      default: false,
    },
    customText: {
      type: [String, Function] as PropType<ICustomValue>,
      default: 'text',
    },
    customValue: {
      type: [String, Function] as PropType<ICustomValue>,
      default: 'value',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    isFilterable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, { emit }: any) {
    const modelValue = computed({
      get: () => {
        return props.value
      },
      set: (newValue: string | number | boolean) => {
        emit('input', newValue)
      },
    })

    const handleChange = (newValue: string) => {
      emit('change', newValue)
    }

    return { modelValue, handleChange }
  },
} as any)
