import { Auth } from '@aws-amplify/auth'
import { RestAPI as API } from '@aws-amplify/api-rest'

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}

export interface IHttpParams {
  path: string
  apiName?: string
  method?: HttpMethod
  headers?: object
  query?: object
  body?: object
  responseType?: string
}

export class HttpError extends Error {
  public statusCode: number

  constructor(message: string, statusCode: number) {
    super(message)

    this.statusCode = statusCode
  }
}

export enum StatusCode {
  Ok = 200,
  Unauthorized = 401,
  Forbidden = 403,
  PageNotFound = 404,
  ClientClosedRequest = 499,
  InternalServerError = 500,
  Conflict = 409,
}

function normalizeQuery(query: any) {
  return Object.keys(query).reduce((result: object, key: string) => {
    const value: any = query[key]

    return {
      ...result,
      [key]: Array.isArray(value) ? value.join(',') : value,
    }
  }, {})
}

export default {
  async request(
    { apiName, path, method, headers, query, body, responseType }: IHttpParams,
    useIAMAuthorizer: boolean = false
  ): Promise<any> {
    const requestApiName = apiName || 'RecyfyAPI'
    const requestMethod = method || HttpMethod.Get
    const composedHeaders = useIAMAuthorizer
      ? headers
      : {
          ...headers,
          Authorization: (await Auth.currentSession())
            .getIdToken()
            .getJwtToken(),
        }

    switch (requestMethod) {
      case HttpMethod.Get: {
        return API.get(requestApiName, path, {
          headers: composedHeaders,
          queryStringParameters: query && normalizeQuery(query),
          ...(responseType ? { responseType } : {}),
        })
      }
      case HttpMethod.Post: {
        return API.post(requestApiName, path, {
          headers: composedHeaders,
          body,
        })
      }
      case HttpMethod.Put: {
        return API.put(requestApiName, path, {
          headers: composedHeaders,
          body,
        })
      }
      case HttpMethod.Delete: {
        return API.del(requestApiName, path, {
          headers: composedHeaders,
          queryStringParameters: query && normalizeQuery(query),
          ...(body ? { body } : {}),
        })
      }
      case HttpMethod.Patch: {
        return API.patch(requestApiName, path, {
          headers: composedHeaders,
          body,
        })
      }
    }
  },
}
