import { IFilters } from './interfaces'
import { ISubscription } from '@/interfaces'
import { OfferStatus, OfferType } from '@/enums'

export interface ISubscriptionState {
  next: string | null
  filters: IFilters
  subscriptions: ISubscription[] | null
  isLoading: boolean
  isLoadingNext: boolean
  error: Error | null
}

export const getDefaultFilters = (): IFilters => {
  return {
    materialTypes: [],
    offerTypes: [OfferType.Buy, OfferType.Sell],
    offerStatus: OfferStatus.Open,
    createdBy: null,
  }
}

export const getDefaultState = (): ISubscriptionState => {
  return {
    next: null,
    filters: getDefaultFilters(),
    subscriptions: null,
    isLoading: false,
    isLoadingNext: false,
    error: null,
  }
}
