import { IMaterialFormatGroup } from '@/interfaces'
import { IMaterialFormatGroupState } from './state'

export const getters = {
  getMaterialFormatGroups(
    state: IMaterialFormatGroupState
  ): IMaterialFormatGroup[] {
    return state.materialFormatGroups
  },

  getIsLoading(state: IMaterialFormatGroupState): boolean {
    return state.isLoading
  },
}
