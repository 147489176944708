import { config } from '@/config/config'
import { IOffer, IUser, IOrganisation, ISubscription } from '@/interfaces'

export const getOfferImageUrls = (
  offer?: IOffer | ISubscription,
  hasOrganisationFallback?: boolean
) => {
  const defaultOfferUrls = [require('@/assets/offer.png')]

  if (offer) {
    if (offer.images && offer.images.length) {
      return offer.images.map((id) => {
        return `${config.aws.assetUrl}/offers/${offer.id}/images/${id}.png`
      })
    } else if (hasOrganisationFallback && offer?.organisation?.hasAvatar) {
      return [getOrganisationAvatarUrl(offer.organisation)]
    } else {
      return defaultOfferUrls
    }
  } else {
    return defaultOfferUrls
  }
}

export const getUserAvatarUrl = ({
  user,
  hasOrganisationFallback = true,
}: {
  user?: IUser
  hasOrganisationFallback?: boolean
}) => {
  if (user && user.hasAvatar) {
    return `${config.aws.assetUrl}/users/${user.id}/avatar.png?${user.updatedAt}`
  } else if (
    hasOrganisationFallback &&
    user &&
    user.organisation &&
    user.organisation.hasAvatar
  ) {
    return `${config.aws.assetUrl}/organisations/${user.organisation.id}/avatar.png?${user.organisation.updatedAt}`
  } else {
    return require('@/assets/avatar-user.png')
  }
}

export const getOrganisationAvatarUrl = (organisation?: IOrganisation) => {
  if (organisation && organisation.hasAvatar) {
    return `${config.aws.assetUrl}/organisations/${organisation.id}/avatar.png?${organisation.updatedAt}`
  } else {
    return require('@/assets/avatar-organisation.png')
  }
}
