import { IStation } from '@/interfaces'

export interface IStationState {
  next: string | null
  stations: IStation[] | null
  isLoading: boolean
  isLoadingNext: boolean
  error: Error | null
}

export const getDefaultState = (): IStationState => {
  return {
    next: null,
    stations: null,
    isLoading: false,
    isLoadingNext: false,
    error: null,
  }
}
