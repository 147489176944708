import { IPriceIndices } from '@/interfaces'

export interface IPriceIndexState {
  priceIndices: IPriceIndices
  isLoading: boolean
}

export const getDefaultState = (): IPriceIndexState => {
  return {
    priceIndices: {},
    isLoading: false,
  }
}
