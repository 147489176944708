




import { AvatarSize } from '@/enums'
import { IOffer, ISubscription } from '@/interfaces'
import Thumbnail from '@/components/common/Thumbnail.vue'
import { PropType } from 'vue'
import { getOfferImageUrls } from '@/utils/AssetUtil/AssetUtil'

export default {
  name: 'OfferThumbnail',
  components: {
    Thumbnail,
  },
  props: {
    offer: {
      type: Object as PropType<IOffer | ISubscription>,
      default: null,
    },
    size: {
      type: Number,
      default: AvatarSize.Default,
    },
    hasOrganisationFallback: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    offerFirstImageUrl() {
      const urls = getOfferImageUrls(this.offer, this.hasOrganisationFallback)

      return urls.length ? urls[0] : null
    },
  },
}
