import AuthService, {
  IChangePasswordFormItems,
  IForgotPasswordFormItems,
} from '@/services/AuthService/AuthService'
import { ActionContext } from 'vuex'
import { IRootState, IUser } from '@/interfaces'
import { IAuthState } from './state'
import {
  fetchUser,
  saveUserFromFormItems,
  IFormItems,
} from '@/modules/user/resources'
import { fetchOrganisation } from '@/utils/OrganisationUtil/OrganisationUtil'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import router from '@/router'

interface ICredentials {
  email: string
  password: string
}

interface IConfirmCredentials {
  email: string
  code: string
}

interface ISignUpCompanyFormData {
  contactEmail: string
  companyName: string
  gender: string
  academicRank: string
  firstName: string
  lastName: string
  contactPhoneNumber: string
  termsAndConditions: string
  sendEmailNotifications: string
}

interface ISignUpData extends ICredentials {
  sendEmailNotifications: boolean
}

export const actions = {
  async signIn(
    { commit }: ActionContext<IAuthState, IRootState>,
    credentials: ICredentials
  ): Promise<void> {
    commit('SET_IS_LOADING', true)

    if (!credentials.email && !credentials.password) {
      await commit('SET_IS_LOADING', false)

      return Promise.resolve()
    }

    try {
      const user = await AuthService.signIn(
        credentials.email,
        credentials.password
      )
      const { currentRoute } = router
      await commit('SET_USER', user)
      await commit('SET_IS_SIGNED_IN', true)
      await router.push(
        currentRoute?.params?.redirect
          ? currentRoute.params.redirect
          : { name: 'marketplace' }
      )
      await commit('SET_IS_LOADING', false)
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        commit('SET_NOT_CONFIRMED_USER_EMAIL', credentials.email)
        commit('SET_IS_USER_SIGNED_UP', true)
      }
      commit('SET_IS_LOADING', false)

      return Promise.reject(error)
    }
  },
  async refreshSession({
    commit,
  }: ActionContext<IAuthState, IRootState>): Promise<void> {
    commit('SET_IS_LOADING', true)

    try {
      const user = await AuthService.refreshSession()

      commit('SET_USER', user)
      commit('SET_IS_LOADING', false)
      commit('SET_IS_SIGNED_IN', true)
    } catch (e) {
      commit('SET_IS_LOADING', false)
    }
  },
  async signOut({
    commit,
  }: ActionContext<IAuthState, IRootState>): Promise<void> {
    await AuthService.signOut()
    await commit('SET_IS_SIGNED_IN', false)
    await commit('SET_IS_USER_SIGNED_UP', false)
    await commit('SET_USER', null)
  },
  async refreshUserAvatarUrl(
    { commit }: ActionContext<IAuthState, IRootState>,
    params: { userId: string }
  ): Promise<void> {
    const user = await fetchUser(params.userId)

    commit('SET_USER', user)
  },
  async refreshOrganisationAvatarUrl(
    { commit }: ActionContext<IAuthState, IRootState>,
    params: { organisationId: string }
  ): Promise<void> {
    const organisation = await fetchOrganisation(params.organisationId)

    commit('SET_USER_ORGANISATION', organisation)
  },
  async saveUser(
    { commit, state }: ActionContext<IAuthState, IRootState>,
    formItems: IFormItems
  ): Promise<void> {
    commit('SET_IS_USER_LOADING', true)

    try {
      const user = await saveUserFromFormItems(formItems)

      if (state.user && user.id === state.user.id) {
        commit('SET_USER', user)
      }
      commit('SET_IS_USER_LOADING', false)
    } catch (error) {
      await commit('SET_USER_ERROR_MESSAGE', error.message)
      commit('SET_IS_USER_LOADING', false)

      return Promise.reject(error)
    }
  },
  async signUp(
    { commit }: ActionContext<IAuthState, IRootState>,
    signUpData: ISignUpData
  ): Promise<void> {
    const { email, password, sendEmailNotifications } = signUpData
    await commit('SET_NOT_CONFIRMED_USER_EMAIL', email)

    try {
      await AuthService.signUp(email, password, sendEmailNotifications)
      await commit('SET_IS_USER_SIGNED_UP', true)
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        commit('SET_IS_USER_SIGNED_UP', false)
      }

      return Promise.reject(error)
    }
  },
  async confirmSignUp(
    _: ActionContext<IAuthState, IRootState>,
    credentials: IConfirmCredentials
  ): Promise<void> {
    try {
      await AuthService.confirmSignUp(credentials.email, credentials.code)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async resendConfirmationCode(
    _: ActionContext<IAuthState, IRootState>,
    email: string
  ): Promise<void> {
    await AuthService.resendConfirmationCode(email)
  },
  async changePassword(
    { state }: ActionContext<IAuthState, IRootState>,
    changePasswordFormData: IChangePasswordFormItems
  ): Promise<void> {
    try {
      if (state.user) {
        await AuthService.changePassword(changePasswordFormData)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async forgotPassword(
    { state }: ActionContext<IAuthState, IRootState>,
    formItems: IForgotPasswordFormItems
  ): Promise<void> {
    if (!state.user) {
      await AuthService.forgotPassword(formItems)
    }
  },
  async signUpCompany(
    // eslint-disable-next-line no-empty-pattern
    { commit }: ActionContext<IAuthState, IRootState>,
    options: ISignUpCompanyFormData
  ): Promise<ISignUpCompanyFormData> {
    try {
      const response = await HttpService.request(
        {
          path: '/registrations',
          method: HttpMethod.Post,
          body: options,
        },
        true
      )
      await commit('SET_IS_USER_SIGNED_UP', true)

      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async toggleUserProfileModal({
    state,
    commit,
  }: ActionContext<IAuthState, IRootState>) {
    const { gender, firstName, lastName } = state.user as IUser
    return new Promise((resolve: any) => {
      if (!gender || !firstName || !lastName) {
        commit(
          'SET_USER_PROFILE_MODAL_VISIBILITY',
          !state.isUserProfileModalVisible
        )
      } else {
        return resolve()
      }
    })
  },
}
