import { IUser } from '@/interfaces'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { AcademicRank, Gender, LocaleCode } from '@/enums'
import { locale } from '@/i18n'

interface IKey {
  id: string
}

export interface IMeta {
  lastEvaluatedKey?: IKey
}

export interface IFormItems {
  id?: string
  gender: Gender | null
  academicRank: AcademicRank | null
  firstName: string
  lastName: string
  position: string
  contactEmail: string
  contactPhoneNumber: string
  contactMobilePhoneNumber: string
  contactFaxNumber: string
  sendEmailNotifications: boolean
  sendTransactionConfirmationEmails: boolean
  locale: LocaleCode
}

const defaultFormItems: IFormItems = {
  gender: null,
  academicRank: null,
  firstName: '',
  lastName: '',
  position: '',
  contactEmail: '',
  contactPhoneNumber: '',
  contactMobilePhoneNumber: '',
  contactFaxNumber: '',
  sendEmailNotifications: false,
  sendTransactionConfirmationEmails: false,
  locale,
}

export interface ISaveQuery {
  id?: string
  gender: Gender
  academicRank: AcademicRank
  firstName: string | null
  lastName: string | null
  position: string | null
  contactEmail: string | null
  contactPhoneNumber: string | null
  contactMobilePhoneNumber: string | null
  contactFaxNumber: string | null
  sendEmailNotifications: boolean | null
  sendTransactionConfirmationEmails: boolean | null
  locale: LocaleCode
}

export const saveUserFromFormItems = async (
  formItems: IFormItems
): Promise<IUser> => {
  const saveQuery = {
    gender: formItems.gender || null,
    academicRank: formItems.academicRank || null,
    firstName: formItems.firstName || null,
    lastName: formItems.lastName || null,
    position: formItems.position || null,
    contactEmail: formItems.contactEmail
      ? formItems.contactEmail.toLowerCase()
      : null,
    contactPhoneNumber: formItems.contactPhoneNumber || null,
    contactMobilePhoneNumber: formItems.contactMobilePhoneNumber || null,
    contactFaxNumber: formItems.contactFaxNumber || null,
    sendEmailNotifications: formItems.sendEmailNotifications || null,
    sendTransactionConfirmationEmails:
      formItems.sendTransactionConfirmationEmails || null,
    locale: formItems.locale,
  } as ISaveQuery

  if (formItems.id) {
    saveQuery.id = formItems.id
  }

  return saveUser(saveQuery)
}

export const fetchUser = async (id: string): Promise<IUser> => {
  const response = await HttpService.request(
    {
      path: `/users/${id}`,
    },
    true
  )

  return response as IUser
}

export const saveUser = async (query: ISaveQuery): Promise<IUser> => {
  const response = await HttpService.request({
    path: `/users/${query.id || ''}`,
    method: query.id ? HttpMethod.Put : HttpMethod.Post,
    body: query,
  })

  return response as IUser
}

export const getDefaultFormItems = (): IFormItems => {
  return Object.assign({}, defaultFormItems)
}

export const fetchOrganisationUsers = async (
  organisationId: string,
  query?: {
    startKey?: string
    limit?: number
  }
) => {
  const response = await HttpService.request({
    query,
    path: `/organisations/${organisationId}/users`,
  })

  return response as {
    users: IUser[]
    meta?: IMeta
  }
}

export const deleteUser = async (id: string): Promise<IUser> => {
  const response = await HttpService.request({
    path: `/users/${id}`,
    method: HttpMethod.Delete,
  })

  return response as IUser
}

export default { saveUserFromFormItems }
