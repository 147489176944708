import { IStationState } from './state'

export const getters = {
  getStations(state: IStationState) {
    return state.stations
  },
  getIsLoading(state: IStationState) {
    return state.isLoading
  },
  getIsLoadingNext(state: IStationState) {
    return state.isLoadingNext
  },
  getTableDataNext(state: IStationState): string | null {
    return state.next
  },
}
