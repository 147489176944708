




import { AvatarSize } from '@/enums'
import { PropType } from 'vue'
import { IUser } from '@/interfaces'
import { getUserAvatarUrl } from '@/utils/AssetUtil/AssetUtil'
import Thumbnail from '../Thumbnail.vue'

export default {
  name: 'UserAvatar',
  components: {
    Thumbnail,
  },
  props: {
    user: {
      type: Object as PropType<IUser>,
      required: true,
    },
    size: {
      type: Number as PropType<AvatarSize>,
      default: AvatarSize.Default,
    },
    hasOrganisationFallback: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    imageUrl() {
      const { user, hasOrganisationFallback } = this
      return getUserAvatarUrl({ user, hasOrganisationFallback })
    },
  },
}
