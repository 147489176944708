import HttpService from '@/services/HttpService/HttpService'
import { IMaterialFormatGroup } from '@/interfaces'
import { appConfig } from '@/config/config'

export const fetchMaterialFormatGroupsResource = async (): Promise<
  IMaterialFormatGroup[]
> => {
  const response = await HttpService.request({
    path: `/materials/${appConfig.paperMaterialId}/format-groups`,
  })

  return response.materialFormatGroups as IMaterialFormatGroup[]
}
