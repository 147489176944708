import { IUser, IOrganisation } from '@/interfaces'
import { IAuthState } from './state'
import { getDefaultFormItems } from '@/modules/user/resources'
import { LocaleCode } from '@/enums'

export const mutations = {
  SET_IS_SIGNED_IN: (state: IAuthState, isSignedIn: boolean) => {
    state.isSignedIn = isSignedIn
  },
  SET_IS_LOADING: (state: IAuthState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_IS_USER_LOADING: (state: IAuthState, isUserLoading: boolean) => {
    state.isUserLoading = isUserLoading
  },
  SET_USER: (state: IAuthState, user: IUser | null) => {
    state.user =
      user === null ? null : Object.assign(getDefaultFormItems(), user)
  },
  SET_USER_ORGANISATION: (state: IAuthState, organisation: IOrganisation) => {
    if (state.user) {
      state.user = {
        ...state.user,
        organisation: organisation,
      }
    }
  },
  SET_USER_LOCALE: (state: IAuthState, locale: LocaleCode) => {
    if (state.user) {
      state.user = {
        ...state.user,
        locale,
      }
    }
  },
  SET_NOT_CONFIRMED_USER_EMAIL: (state: IAuthState, email: string) => {
    state.notConfirmedUserEmail = email
  },
  SET_IS_USER_SIGNED_UP: (state: IAuthState, isSignedUp: boolean) => {
    state.isSignedUp = isSignedUp
  },
  SET_USER_PROFILE_MODAL_VISIBILITY: (
    state: IAuthState,
    isUserProfileModalVisible: boolean
  ) => {
    state.isUserProfileModalVisible = isUserProfileModalVisible
  },
}
