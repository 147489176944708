export enum EmailConfirmationStatus {
  NoParams = 'noParams',
  InProgress = 'inProgress',
  Error = 'error',
  Success = 'success',
}

export enum OfferStatus {
  Draft = 'draft',
  Open = 'open',
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Expired = 'expired',
  Rejected = 'rejected',
  Closed = 'closed',
}

export enum DeliveryStatus {
  Open = 'open',
  Assigned = 'assigned',
  Scheduled = 'scheduled',
  Delivered = 'delivered',
  Canceled = 'canceled',
  Failed = 'failed',
  Closed = 'closed',
}

export enum DeliveryRawSyncStatus {
  Open = 'open',
  Synced = 'synced',
  Unplanned = 'unplanned',
  Insert = 'insert',
  Import = 'import',
  Dispatched = 'dispatched',
  Unloaded = 'unloaded',
  Deleted = 'deleted',
  Invoiced = 'invoiced',
  Planned = 'planned',
}

export enum DeliverySystemSyncStatus {
  Unplanned = 'unplanned',
  Planned = 'planned',
  NotDelivered = 'notDelivered',
  Unloaded = 'unloaded',
  Invoiced = 'invoiced',
}

export enum TransactionSyncStatus {
  Open = 'open',
  Done = 'done',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export enum DeliveryMatchingParticipants {
  Factory = 'factory',
  Supplier = 'supplier',
}

export enum DeliveryMatchingStatus {
  Auto = 'auto',
  Manual = 'manual',
  Confirmed = 'confirmed',
  NoMatch = 'noMatch',
}

export enum DeliveryMatchingSort {
  Date = 'dueDate',
  MaterialTypeCode = 'materialTypeCode',
  DeliveryId = 'id',
}

export enum OfferType {
  Sell = 'sell',
  Buy = 'buy',
}

export enum OfferContractType {
  Spot = 'spot',
  Recurring = 'recurring',
}

export enum UnitType {
  Ton = 't',
}

export enum AcademicRank {
  Dr = 'Dr',
  Prof = 'Prof',
  DrDr = 'DrDr',
  ProfDr = 'ProfDr',
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
}

export enum OriginType {
  Offer = 'offer',
  Template = 'template',
}

export enum CountryCode {
  BE = 'BE',
  FR = 'FR',
  DE = 'DE',
  IT = 'IT',
  LU = 'LU',
  NL = 'NL',
  DK = 'DK',
  IE = 'IE',
  GB = 'GB',
  GR = 'GR',
  PT = 'PT',
  ES = 'ES',
  AT = 'AT',
  FI = 'FI',
  SE = 'SE',
  CY = 'CY',
  CZ = 'CZ',
  EE = 'EE',
  HU = 'HU',
  LV = 'LV',
  LT = 'LT',
  MT = 'MT',
  PL = 'PL',
  SK = 'SK',
  SI = 'SI',
  BG = 'BG',
  RO = 'RO',
  HR = 'HR',
  CH = 'CH',
}

export enum LocaleCode {
  EN = 'EN',
  DE = 'DE',
}

export enum DialogSize {
  Small = 450,
  Default = 750,
}

export enum AvatarSize {
  Tiny = 15,
  Small = 30,
  Default = 40,
  Huge = 160,
}

export enum BidStatus {
  Open = 'open',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  Closed = 'closed',
  Expired = 'expired',
}

export enum ImageType {
  User = 'user',
  Organisation = 'organisation',
  Offer = 'offer',
  OfferDelete = 'offerDelete',
}

export enum FileAction {
  Upload = 'upload',
  Download = 'download',
  Delete = 'delete',
}

export enum FileIconType {
  Doc = 'doc',
  Docx = 'docx',
  Pdf = 'pdf',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Csv = 'csv',
}

export enum ImageUploadStatus {
  Ready = 'ready',
  Success = 'success',
}

export enum CreatedBy {
  User = 'user',
}

export enum NotificationAction {
  Published = 'published',
  Rejected = 'rejected',
  Created = 'created',
  Cancelled = 'cancelled',
  Accepted = 'accepted',
  Closed = 'closed',
  Updated = 'updated',
  Validated = 'validated',
  TextAttemptCreated = 'textAttemptCreated',
  BidAttemptCreated = 'bidAttemptCreated',
}

export enum NotificationResource {
  Offers = 'offers',
  Users = 'users',
  Bids = 'bids',
  Organisations = 'organisations',
  Contracts = 'contracts',
  Transactions = 'transactions',
  Deliveries = 'deliveries',
}

export enum S3DocumentType {
  Organisation = 'organisation',
  ConfirmedDelivery = 'confirmed-delivery',
  Station = 'station',
  Offer = 'offer',
  Transaction = 'transaction',
  Delivery = 'delivery',
  DeliveryWeightNote = 'delivery-weight-note',
  DeliveryWeightNoteAll = 'delivery-weight-note-all',
}

export enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Cancelled = 'cancelled',
}

export enum NotificationPermissions {
  Default = 'default',
  Granted = 'granted',
  Denied = 'denied',
}

export enum Env {
  Development = 'development',
  Testing = 'testing',
  Production = 'production',
}

export enum BankAccountType {
  Iban = 'iban',
}

export enum TransactionStatus {
  Pending = 'pending',
  AwaitingPrice = 'awaitingPrice',
  Open = 'open',
  Invoiced = 'invoiced',
  Cancelled = 'cancelled',
  Free = 'free',
}

export enum Footer {
  Small = 'Small',
}

export enum OrganisationStatus {
  Draft = 'DRAFT',
  Ready = 'READY',
  Validated = 'VALIDATED',
  Disabled = 'DISABLED',
  Restricted = 'RESTRICTED',
}

export enum ValidatedType {
  Organisation = 'organisation',
  User = 'user',
}

export enum AttemptType {
  Text = 'TEXT',
  Bid = 'BID',
}

export enum FilterType {
  List = 'list',
  Input = 'input',
  Range = 'range',
  Date = 'date',
  Flag = 'flag',
  Boolean = 'boolean',
}

export enum Radius {
  Fifty = '50',
  Hundred = '100',
  HundredFifty = '150',
  TwoHundred = '200',
  TwoHundredFifty = '250',
  ThreeHundred = '300',
  FourHundred = '400',
  FiveHundred = '500',
}

export enum OrganisationType {
  Mill = 'MILL',
  Supplier = 'SUPPLIER',
  Dealer = 'DEALER',
  Printing = 'PRINTING',
  Commune = 'COMMUNE',
  Other = 'OTHER',
}

export enum DeliveryType {
  FreeDelivery = 'freeDelivery',
  FromStation = 'fromStation',
}

export enum DeliveryFileType {
  WeightNote = 'wheightNote',
}

export enum OfferVisibility {
  Private = 'Private',
  Anonymous = 'Anonymous',
}

export enum TradingStatus {
  Seller = 'seller',
  Buyer = 'buyer',
  ToSeller = 'toSeller',
  ToBuyer = 'toBuyer',
}

export enum SellerCategories {
  delivery = 'seller:delivery',
  quality = 'seller:quality',
  communication = 'seller:communication',
  pricing = 'seller:pricing',
}

export enum BuyerCategories {
  delivery = 'buyer:delivery',
  quality = 'buyer:quality',
  communication = 'buyer:communication',
  pricing = 'buyer:pricing',
}

export enum CommonCategories {
  delivery = 'delivery',
  communication = 'communication',
  pricing = 'pricing',
  quality = 'quality',
}

export enum ErrorMessage {
  UsernameExistsException = 'UsernameExistsException',
  UserNotFoundException = 'UserNotFoundException',
  InvalidParameterException = 'InvalidParameterException',
  NotAuthorizedException = 'NotAuthorizedException',
  LimitExceededException = 'LimitExceededException',
  UserNotConfirmedException = 'UserNotConfirmedException',
}

export enum TooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ChartType {
  Area = 'area',
  Bar = 'bar',
  Pie = 'pie',
  Donut = 'donut',
  Scatter = 'scatter',
}

export enum YAxisFormat {
  Currency = 'currency',
  Amount = 'amount',
}

export enum Emitter {
  Restricted = 'emmit:restricted',
  MatchingDeliveries = 'emmit:matching',
}

export interface IEnums {
  OfferStatus: typeof OfferStatus
  OfferType: typeof OfferType
  UnitType: typeof UnitType
  AcademicRank: typeof AcademicRank
  Gender: typeof Gender
  CountryCode: typeof CountryCode
  AvatarSize: typeof AvatarSize
  BidStatus: typeof BidStatus
  ImageType: typeof ImageType
  CreatedBy: typeof CreatedBy
  NotificationAction: typeof NotificationAction
  NotificationResource: typeof NotificationResource
  S3DocumentType: typeof S3DocumentType
  InviteStatus: typeof InviteStatus
  NotificationPermissions: typeof NotificationPermissions
  Env: typeof Env
  TransactionStatus: typeof TransactionStatus
  Footer: typeof Footer
  OrganisationStatus: typeof OrganisationStatus
  ValidatedType: typeof ValidatedType
  AttemptType: typeof AttemptType
  FilterType: typeof FilterType
  EmailConfirmationStatus: typeof EmailConfirmationStatus
  OfferContractType: typeof OfferContractType
  Radius: typeof Radius
  OrganisationType: typeof OrganisationType
  DeliveryType: typeof DeliveryType
  OfferVisibility: typeof OfferVisibility
  FileAction: typeof FileAction
  ImageUploadStatus: typeof ImageUploadStatus
  ErrorMessage: typeof ErrorMessage
  ChartType: typeof ChartType
  YAxisFormat: typeof YAxisFormat
  Emitter: typeof Emitter
}

const enums: IEnums = {
  OfferStatus,
  OfferType,
  UnitType,
  AcademicRank,
  Gender,
  CountryCode,
  AvatarSize,
  BidStatus,
  ImageType,
  CreatedBy,
  NotificationAction,
  NotificationResource,
  S3DocumentType,
  InviteStatus,
  NotificationPermissions,
  Env,
  TransactionStatus,
  Footer,
  OrganisationStatus,
  ValidatedType,
  AttemptType,
  FilterType,
  EmailConfirmationStatus,
  OfferContractType,
  Radius,
  OrganisationType,
  DeliveryType,
  OfferVisibility,
  FileAction,
  ImageUploadStatus,
  ErrorMessage,
  ChartType,
  YAxisFormat,
  Emitter,
}

export default enums
