import { Module } from 'vuex'
import { actions } from './actions'
import { getAppState, IAppState } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { IRootState } from '@/interfaces'

export const app: Module<IAppState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getAppState(),
}
