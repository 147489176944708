




import { AvatarSize } from '@/enums'
import { PropType } from 'vue'
import { IOrganisation } from '@/interfaces'
import { getOrganisationAvatarUrl } from '@/utils/AssetUtil/AssetUtil'
import Thumbnail from '../Thumbnail.vue'

export default {
  name: 'OrganisationAvatar',
  components: {
    Thumbnail,
  },
  props: {
    organisation: {
      type: Object as PropType<IOrganisation>,
      required: true,
    },
    size: {
      type: Number as PropType<AvatarSize>,
      default: AvatarSize.Default,
    },
  },
  computed: {
    imageUrl() {
      return getOrganisationAvatarUrl(this.organisation)
    },
  },
}
