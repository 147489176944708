import { ActionContext } from 'vuex'
import { IMaterialFormatGroupState } from '@/modules/materialFormatGroup/state'
import { IRootState } from '@/interfaces'
import { fetchMaterialFormatGroupsResource } from './resources'
import {
  SET_IS_MATERIAL_FORMAT_GROUPS_LOADING,
  SET_MATERIAL_FORMAT_GROUPS,
} from '@/modules/materialFormatGroup/consts'

export const actions = {
  async getMaterialFormatGroups({
    commit,
    state,
  }: ActionContext<IMaterialFormatGroupState, IRootState>): Promise<void> {
    if (state.materialFormatGroups.length > 0) {
      return
    }

    await commit(SET_IS_MATERIAL_FORMAT_GROUPS_LOADING, true)

    try {
      await commit(
        SET_MATERIAL_FORMAT_GROUPS,
        await fetchMaterialFormatGroupsResource()
      )
      await commit(SET_IS_MATERIAL_FORMAT_GROUPS_LOADING, false)
    } catch (error) {
      await commit(SET_IS_MATERIAL_FORMAT_GROUPS_LOADING, false)
    }
  },
}
