import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { ThemeOptions } from 'vuetify/types/services/theme'

import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'

import recyfyTheme from '@/plugins/vuetify/themes/recyfy'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: recyfyTheme as ThemeOptions['themes'],
  },
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { en, de },
  },
})
