





















































import { INotification, IUser } from '@/interfaces'
import { PropType } from 'vue'
import OfferThumbnail from '@/components/common/OfferThumbnail.vue'
import SocketService from '@/services/SocketService/SocketService'
import {
  getNotificationDescription,
  redirectToNotificationRoute,
} from '@/utils/NotificationUtil/NotificationUtil'
import { mapActions, mapGetters } from 'vuex'
import { NOTIFICATIONS_TO_SHOW } from '@/modules/notification/consts'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'
import { ListItem } from '@/components/common/ListItem'

export default {
  name: 'NotificationComponent',
  components: {
    OfferThumbnail,
    FloatingMenu,
    ListItem,
  },
  props: {
    user: {
      type: Object as PropType<IUser>,
      default: null,
    },
  },
  data() {
    return {
      messageEvent: null,
    }
  },
  computed: {
    ...mapGetters('notification', ['notifications']),
    hasUnreadNotifications() {
      return !!this.notifications?.length
    },
    notificationsToShow() {
      return this.notifications.slice(0, NOTIFICATIONS_TO_SHOW)
    },
  },
  async created() {
    await this.fetchPersonalNotifications()
  },
  async destroyed() {
    await SocketService.unsubscribeMessageEvent(this.messageEvent)
  },
  methods: {
    ...mapActions('notification', [
      'fetchPersonalNotifications',
      'deleteNotification',
      'deleteAllNotifications',
    ]),
    getDescriptionMessage(notification: INotification) {
      const description = getNotificationDescription(notification, this.user)

      return description
        ? description.message
        : `${notification.resource} ${notification.action}`
    },
    clearNotifications() {
      this.deleteAllNotifications()
    },
    redirectToNotificationRoute(notification: INotification) {
      redirectToNotificationRoute(notification)

      this.deleteNotification(notification.id)
    },
  },
}
