import { ITableData } from '@/interfaces'
import { IFilters } from './interfaces'
import { ISubscriptionState } from './state'
import { sortBy } from '@/utils/utils'

export const mutations = {
  SET_IS_LOADING: (
    state: ISubscriptionState,
    isSubscriptionsLoading: boolean
  ) => {
    state.isLoading = isSubscriptionsLoading
  },
  SET_FILTERS: (state: ISubscriptionState, filters: IFilters) => {
    state.filters = filters
  },
  SET_IS_LOADING_NEXT: (
    state: ISubscriptionState,
    isSubscriptionsLoading: boolean
  ) => {
    state.isLoadingNext = isSubscriptionsLoading
  },
  SET_ERROR: (state: ISubscriptionState, error: Error) => {
    state.error = error
  },
  SET_TABLE_DATA: (
    state: ISubscriptionState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    const subscriptions = options.previousNext
      ? (state.subscriptions || []).concat(options.tableData.rows)
      : options.tableData.rows
    state.subscriptions = subscriptions.map((item) => {
      if (!item.bids) {
        return item
      }

      return { ...item, bids: sortBy(item.bids, 'createdAt') }
    })
  },
}
