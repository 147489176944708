import { getDefaultState } from './state'
import { IMarketplaceState, IFilters, IFilterPreset } from './interfaces'
import { ITableData } from '@/interfaces'

export const mutations = {
  SET_ERROR: (state: IMarketplaceState, error: Error) => {
    state.error = error
  },
  SET_FILTERS: (state: IMarketplaceState, filters: IFilters) => {
    state.filters = filters
  },
  DELETE_FILTER_PRESET: (state: IMarketplaceState, presetId: string) => {
    state.filterPresets = state.filterPresets.filter(
      (preset) => preset.id !== presetId
    )
  },
  UPDATE_FILTER_PRESET: (
    state: IMarketplaceState,
    updatedPreset: IFilterPreset
  ) => {
    const filterPresets = state.filterPresets
    const indexToUpdate = filterPresets.findIndex(
      (preset) => preset.id === updatedPreset.id
    )

    if (indexToUpdate >= 0) {
      state.filterPresets.splice(indexToUpdate, 1, updatedPreset)
    }
  },
  ADD_FILTER_PRESET: (state: IMarketplaceState, preset: IFilterPreset) => {
    state.filterPresets.push(preset)
  },
  SET_FILTER_PRESETS: (
    state: IMarketplaceState,
    filterPresets: IFilterPreset[]
  ) => {
    state.filterPresets = filterPresets
  },
  SET_TABLE_DATA: (
    state: IMarketplaceState = getDefaultState(),
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    const offers = options.previousNext
      ? (state.offers || []).concat(options.tableData.rows)
      : options.tableData.rows

    return (state.offers = offers)
  },
  SET_IS_LOADING: (state: IMarketplaceState, isOffersLoading: boolean) => {
    state.isLoading = isOffersLoading
  },
  SET_IS_LOADING_NEXT: (
    state: IMarketplaceState,
    isOffersLoadingNext: boolean
  ) => {
    state.isLoadingNext = isOffersLoadingNext
  },
  SET_IS_LOADING_FILTERS: (
    state: IMarketplaceState,
    isFiltersLoading: boolean
  ) => {
    state.isLoadingFilters = isFiltersLoading
  },
  SET_ACTIVE_FILTER_PRESET: (state: IMarketplaceState, presetId: string) => {
    state.activeFilterPresetId = presetId
  },
}
