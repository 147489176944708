import moment from 'moment'
import {
  FilterType,
  UnitType,
  ErrorMessage,
  DeliverySystemSyncStatus,
  DeliveryRawSyncStatus,
  LocaleCode,
} from '@/enums'
import { appConfig } from '@/config/config'
import { IDeliveriesByDate, IErrorMessage } from '@/interfaces'
import group from 'lodash/groupBy'
import { i18n } from '@/i18n'

enum Currency {
  Eur = 'EUR',
}

const getCurrencySymbol = (currency: Currency): string => {
  switch (currency) {
    case Currency.Eur:
      return '€'
  }
}

const getLanguage = (): string => {
  return process.env.VUE_APP_I18N_LOCALE || navigator.language || 'en'
}

export const currencyPerUnitType = (unitType: UnitType) => {
  return `${getCurrencySymbol(Currency.Eur)}/${unitType}`
}

export const formatPrice = (
  value: number | null,
  isSuperUnit?: boolean
): string | null => {
  if (value === null) {
    return null
  }

  const STYLE = 'currency'
  const MINIMUM_FRACTION_DIGITS = 2
  const MAXIMUM_FRACTION_DIGITS = 2

  const currency = Currency.Eur
  const formatter = new Intl.NumberFormat(getLanguage(), {
    style: STYLE,
    currency: Currency.Eur,
    minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
  })

  return formatter
    .format(isSuperUnit ? value : value / 100)
    .replace(currency, getCurrencySymbol(currency))
}

export const isNumber = (value: any): boolean =>
  typeof value === 'number' &&
  !Number.isNaN(value) &&
  value !== Infinity &&
  value !== -Infinity

export const formatNumber = (
  value: number,
  minimumFractionDigits = 0
): string => {
  if (!isNumber(value)) {
    return ''
  }

  return value.toLocaleString(getLanguage(), {
    minimumFractionDigits,
  })
}

export function toUnixTime(timestamp: number): number {
  return timestamp > 1e10 ? Math.floor(timestamp * 0.001) : timestamp
}

export const formatDateFromNow = (unixTimestamp?: number): string => {
  if (!unixTimestamp) {
    return ''
  }

  return moment(unixTimestamp).fromNow()
}

export const formatDate = (unixTimestamp?: number): string => {
  if (!unixTimestamp) {
    return ''
  }

  return moment.unix(unixTimestamp).format(appConfig.date.formatMomentDate)
}

export const formatDateTime = (unixTimestamp?: number): string => {
  if (!unixTimestamp) {
    return ''
  }

  return moment.unix(unixTimestamp).format(appConfig.date.formatMomentDateTime)
}

export const formatDateMonth = (unixTimestamp?: number): string => {
  if (!unixTimestamp) {
    return ''
  }

  return moment
    .unix(unixTimestamp)
    .format(appConfig.date.formatRecurringOfferDate)
}

export const formatDateFromNowUnix = (unixTimestamp?: number): string => {
  if (!unixTimestamp) {
    return ''
  }

  return moment.unix(unixTimestamp).fromNow()
}

export const formatDayOfWeek = (date: string): string => {
  return moment(date, appConfig.date.formatMomentDate).format('dddd')
}

export const getWeekNumber = (unixTimestamp: number): number => {
  return moment.unix(unixTimestamp).week()
}

export const replaceItemWithSameId = (
  items: any[],
  newItem: any,
  id?: string
): any[] => {
  const key = id || 'id'
  const index = items.findIndex((item) => item[key] === newItem[key])

  if (index > -1) {
    items.splice(index, 1, newItem)
  }

  return items
}

export const removeItemWithSameId = (
  items: any[],
  newItem: any,
  id?: string
) => {
  const key = id || 'id'
  const index = items.findIndex((item) => item[key] === newItem[key])

  if (index > -1) {
    items.splice(index, 1)
  }

  return items
}

export const groupBy = (items: any[], param: string) => {
  return items.reduce((a, b) => {
    ;(a[b[param]] = a[b[param]] || []).push(b)

    return a
  }, {})
}

export const sortBy = <T>(items: T[], key: string): T[] => {
  return items.sort((a: any, b: any) => a[key] - b[key]).reverse()
}

export const randomNumber = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max))
}

export const filterNormalizer = (
  filters: { [name: string]: any },
  normalizeRules: { [name: string]: any }
) => {
  return Object.keys(filters).reduce((acc: any, attr: string) => {
    const rule = normalizeRules[attr] || {}
    const value = filters[attr]
    switch (rule.type) {
      case FilterType.Input: {
        if (
          value === undefined ||
          value === null ||
          (typeof value === 'string' && value.length === 0)
        ) {
          return acc
        }

        return {
          ...acc,
          [attr]: value,
        }
      }
      case FilterType.List: {
        if (!value || value.length === 0) {
          return acc
        }

        return {
          ...acc,
          [attr]: value,
        }
      }
      case FilterType.Range: {
        if (
          value === undefined ||
          value === null ||
          !Array.isArray(value) ||
          (value[0] === undefined && value[1] === undefined)
        ) {
          return acc
        }

        return {
          ...acc,
          [attr]: [
            isNaN(Number(value[0])) ? rule.min : value[0],
            isNaN(Number(value[1])) ? rule.max : value[1],
          ],
        }
      }
      case FilterType.Flag: {
        if (value === undefined || value === null) {
          return acc
        }

        return {
          ...acc,
          [attr]: rule.convert ? Number(value) : value,
        }
      }
      case FilterType.Boolean: {
        if (value === undefined || value === null) {
          return acc
        }

        if (value === 'true' || value) {
          return {
            ...acc,
            [attr]: value === 'true' || value,
          }
        } else {
          return acc
        }
      }
      case FilterType.Date: {
        if (value === undefined || value === null || !value.length) {
          return acc
        }

        let formattedDate

        if (Array.isArray(value)) {
          formattedDate = value
            .map((timestamp: Date) =>
              moment(timestamp).format(appConfig.date.queryDateFormat)
            )
            .join('_')
        } else if (typeof value === 'string') {
          formattedDate = value
            .split('_')
            .map((value: string) => moment(value).toDate())
        }

        return {
          ...acc,
          [attr]: formattedDate,
        }
      }
      default:
        return acc
    }
  }, {})
}

export const groupByMonth = (deliveries: IDeliveriesByDate) =>
  Object.entries(
    group(Object.keys(deliveries), (date) =>
      moment(date, appConfig.date.formatMomentDate).format('MMMM/YYYY')
    )
  ).reduce((result, groupedMonth) => {
    const [key, value] = groupedMonth

    return {
      ...result,
      [key]: value
        .sort()
        .reduce((res, day) => ({ ...res, [day]: deliveries[day] }), {}),
    }
  }, {})

export const errorMessageRenderer = (
  errorMessage: IErrorMessage,
  fallbackErrorMessage: string = i18n.t('login.message.noUser') as string
) => {
  if (Object.keys(ErrorMessage).includes(errorMessage.code)) {
    if (errorMessage.code === ErrorMessage.UserNotFoundException) {
      const listItem = ['email', 'password', 'time']
        .map((solution) => `<li>${i18n.t('error.list.' + solution)}</li>`)
        .join('')

      const list = `<ol>${listItem}</ol>`

      return `<span>
          <span>${i18n.t('error.list.title')}</span>
          ${list}
          <span>${i18n.t('error.list.suffix')}</span>
        </span>`
    }

    return `${i18n.t(`error.${errorMessage.code}`)} ${i18n.t('error.suffix')}`
  }

  return fallbackErrorMessage
}

export const deliverySystemStatusColour = {
  [DeliverySystemSyncStatus.Unplanned]: '#c0392b',
  [DeliverySystemSyncStatus.Planned]: '#f39c12',
  [DeliverySystemSyncStatus.NotDelivered]: '#f56c6c',
  [DeliverySystemSyncStatus.Unloaded]: '#018b80',
  [DeliverySystemSyncStatus.Invoiced]: '#2391e1',
} as Record<DeliverySystemSyncStatus, string>

export const deliveryRawStatusColour = {
  [DeliveryRawSyncStatus.Unplanned]: '#c0392b',
  [DeliveryRawSyncStatus.Insert]: '#f39c12',
  [DeliveryRawSyncStatus.Planned]: '#f39c12',
  [DeliveryRawSyncStatus.Import]: '#fff2c1',
  [DeliveryRawSyncStatus.Dispatched]: '#76a2d6',
  [DeliveryRawSyncStatus.Unloaded]: '#018b80',
  [DeliveryRawSyncStatus.Deleted]: '#f56c6c',
  [DeliveryRawSyncStatus.Invoiced]: '#2391e1',
} as { [DeliveryRawSyncStatus: string]: string }

export const countryFlagEmoji = {
  [LocaleCode.DE]: '&#x1F1E9;&#x1F1EA;',
  [LocaleCode.EN]: '&#x1F1EC;&#x1F1E7;',
}

export const stringToRGB = (str: string): string => {
  let hash = 0

  if (str.length === 0) return ''

  str.split('').forEach((char: string) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
    hash = hash & hash
  })

  const rgb = [...new Array(3)].map((_, j) => (hash >> (j * 2)) & 255)

  return `rgb(${rgb.join(',')})`
}

export const fileDownload = (fileName: string, csv: BlobPart): void => {
  const blob: Blob = new Blob([csv], {
    type: 'data:text/csv;charset=utf-8;',
  })
  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)

  link.click()
}
