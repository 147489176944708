import tracking from '@/tracking'
import Vue from 'vue'
import Router, { RawLocation, Route } from 'vue-router'
import { HttpError, StatusCode } from '@/services/HttpService/HttpService'
import store from '@/store'
import {
  isProtected,
  toMarketplace,
  isGlobalAdmin,
} from '@/navigationGuards/auth'
import { parse, stringify } from 'query-string'
import { i18n } from '@/i18n'

Vue.use(Router)

// Suppress "NavigationDuplicated" https://github.com/vuejs/vue-router/commit/5ef5d73

const routerPush = Router.prototype.push

Router.prototype.push = function push(location: any) {
  // @ts-expect-error
  return routerPush.call(this, location).catch((error: Error) => {
    if (error?.name === 'NavigationDuplicated') {
      return
    }
    return error
  })
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: async () =>
        import(
          /* webpackChunkName: "home" */ './components/page/Home/Home.vue'
        ),
      beforeEnter: toMarketplace,
    },
    {
      path: '/login',
      name: 'signIn',
      component: async () =>
        import(
          /* webpackChunkName: "signIn" */ './components/common/SignIn/SignIn.vue'
        ),
      beforeEnter: toMarketplace,
    },
    {
      path: '/registration',
      name: 'signUp',
      component: async () =>
        import(
          /* webpackChunkName: "signUp" */ './components/common/SignUp.vue'
        ),
    },
    {
      path: '/confirm',
      name: 'confirmEmail',
      component: async () =>
        import(
          /* webpackChunkName: "confirm" */ './components/common/ConfirmEmail.vue'
        ),
    },
    {
      path: '/marketplace',
      name: 'marketplace',
      component: async () =>
        import(
          /* webpackChunkName: "marketplace" */ './components/offer/Offers.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: async () =>
        import(
          /* webpackChunkName: "notification" */ './components/notification/Notifications.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/organisations/:id',
      name: 'organisation',
      component: async () =>
        import(
          /* webpackChunkName: "organisation" */ './components/organisation/Organisation.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/organisations',
      name: 'organisations',
      component: async () =>
        import(
          /* webpackChunkName: "organisations" */ './components/organisation/Organisations.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/users',
      name: 'users',
      component: async () =>
        import(/* webpackChunkName: "users" */ './components/user/Users.vue'),
      beforeEnter: isProtected,
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: async () =>
        import(
          /* webpackChunkName: "transactions" */ './components/transaction/Transactions/Transactions.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/transactions/:id',
      name: 'transaction',
      component: async () =>
        import(
          /* webpackChunkName: "transaction" */ './components/transaction/TransactionDetail/TransactionDetailPage/TransactionDetailPage.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/organisations/:id/transactions',
      name: 'organisationTransactions',
      component: async () =>
        import(
          /* webpackChunkName: "organisationTransactions" */ './components/transaction/Transactions/Transactions.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/users/:id',
      name: 'user',
      component: async () =>
        import(/* webpackChunkName: "user" */ './components/user/User.vue'),
      beforeEnter: isProtected,
    },
    {
      path: '/trading',
      name: 'trading',
      component: async () =>
        import(
          /* webpackChunkName: "trading" */ './components/trading/TradingDashboard/TradingDashboard.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/offers/:id',
      component: async () =>
        import(
          /* webpackChunkName: "trading" */ './components/offerDetail/OfferDetailsPage/OfferDetailsPage.vue'
        ),
      beforeEnter: isProtected,
      children: [
        {
          path: 'contracts',
          name: 'contracts',
          component: async () =>
            import(
              /* webpackChunkName: "trading" */ './components/offerDetail/OfferTrading/OfferTrading.vue'
            ),
        },
        {
          path: '',
          name: 'offer',
          component: async () =>
            import(
              /* webpackChunkName: "trading" */ './components/offerDetail/OfferDetails/OfferDetails.vue'
            ),
        },
        {
          path: 'deliveries',
          name: 'deliveryPlanner',
          component: async () =>
            import(
              /* webpackChunkName: "trading" */ './components/offerDetail/OfferDetails/OfferDetails.vue'
            ),
        },
        {
          path: 'bids',
          name: 'bids',
          component: async () =>
            import(
              /* webpackChunkName: "trading" */ './components/offerDetail/OfferBids/OfferBids.vue'
            ),
          beforeEnter: isGlobalAdmin,
        },
      ],
    },
    {
      path: '/partners',
      name: 'partners',
      component: async () =>
        import(
          /* webpackChunkName: "tradingPartners" */ './components/rating/RatingsPage/RatingsPage.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: async () =>
        import(
          /* webpackChunkName: "dashboard" */ './components/dashboard/Dashboard/Dashboard.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/deliveries',
      name: 'deliveries',
      component: async () =>
        import(
          /* webpackChunkName: "dashboard" */ './components/delivery/DeliveryManagement/DeliveryManagementPage/DeliveryManagementPage.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/organisations/:id/dashboard',
      name: 'organisationDashboard',
      component: async () =>
        import(
          /* webpackChunkName: "organisationDashboard" */ './components/dashboard/Dashboard/Dashboard.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '/matching',
      name: 'deliveryMatching',
      component: async () =>
        import(
          /* webpackChunkName: "dashboard" */ './components/delivery/DeliveryMatching/DeliveryMatchingPage/DeliveryMatchingPage.vue'
        ),
      beforeEnter: isProtected,
    },
    {
      path: '*',
      name: 'errorComponent',
      component: async () =>
        import(
          /* webpackChunkName: "errorComponent" */ './components/common/ErrorComponent.vue'
        ),
      props: (route: Route) => {
        return {
          error: new HttpError(
            route.params.message,
            Number(route.params.statusCode) || StatusCode.PageNotFound
          ),
        }
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    }

    return savedPosition || { x: 0, y: 0 }
  },
  parseQuery(query: string) {
    return parse(query, {
      arrayFormat: 'bracket',
      parseNumbers: true,
      parseBooleans: true,
    })
  },
  stringifyQuery(query: any) {
    return `?${stringify(query, { arrayFormat: 'bracket' })}`
  },
})

router.onError((error: Error) => {
  if (/Loading chunk [a-zA-z~]* failed/i.test(error.message)) {
    window.location.reload()
  }
})

router.beforeEach(
  async (
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) => {
    const { query } = to

    if (!store.getters['auth/getIsSignedIn']) {
      await store.dispatch('auth/refreshSession')
    }

    const userLocale = await store.getters['auth/getUserLocale']

    if (userLocale) {
      i18n.locale = userLocale
    }

    next({ query })
  }
)

router.afterEach((to: Route) => {
  tracking.trackPageView(to.path)
})

export default router
