import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import App from '@/App/App.vue'
import router from '@/router'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import VueScrollBehavior from 'vue-scroll-behavior'
import { i18n, locale } from '@/i18n'
import { Emitter } from 'mitt'
import '@/filters'
import '@/tracking'
import '@/element-ui'

import '@mdi/font/css/materialdesignicons.css'
import '@/styles/element-variables.scss'
import '@/styles/common.scss'

import {
  config,
  IConfig,
  appConfig,
  IAppConfig,
  env,
  awsConfig,
} from '@/config/config'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueBrowserUpdate from 'vue-browserupdate'
import { Env } from '@/enums'
import { NotificationService } from '@/services/NotificationService'
import Amplify, * as AmplifyModules from '@aws-amplify/core'

// @ts-ignore
import { AmplifyPlugin } from 'aws-amplify-vue'
import { emitter } from '@/emitter'

import vuetify from './plugins/vuetify'

Amplify.configure(awsConfig)

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(VueApexCharts)

Vue.use(AmplifyPlugin, AmplifyModules)

Vue.use(VueGoogleMaps, {
  load: {
    key: appConfig.googleMaps.key,
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})

Vue.use(VueBrowserUpdate, {
  options: {
    l: locale,
    text: i18n.t('common.browserUpdate'),
    insecure: true,
    container: document.body,
  },
})

declare module 'vue/types/vue' {
  // tslint:disable-next-line
  interface Vue {
    $config: IConfig
    $appConfig: IAppConfig
    $env: Env
    $emitter: Emitter
  }
}

Object.defineProperties(Vue.prototype, {
  $config: {
    value: config,
  },
  $appConfig: {
    value: appConfig,
  },
  $env: {
    value: env,
  },
  $emitter: {
    value: emitter,
  },
})
Vue.use(NotificationService)
Vue.component('Apexchart', VueApexCharts)
Vue.use(VueScrollBehavior, { router: router, maxLength: 100 })

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
