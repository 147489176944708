import { ActionContext } from 'vuex'
import { IRootState } from '@/interfaces'
import { ITransactionState } from './state'
import { fetchOrganisation } from '@/utils/OrganisationUtil/OrganisationUtil'
import moment from 'moment'
import HttpService from '@/services/HttpService/HttpService'
import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from '@/modules/transaction/rules'
import resources, {
  IFetchQuery,
  ITransaction,
  ITransactionFilters,
} from './resources'

export const actions = {
  async fetchOrganisation(
    { commit }: ActionContext<ITransactionState, IRootState>,
    organisationId: string
  ) {
    await commit('SET_IS_LOADING', true)
    try {
      const result = await fetchOrganisation(organisationId)
      await commit('SET_IS_LOADING', false)
      await commit('SET_ORGANISATION', result)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
    }
  },
  async fetchTransactions(
    { commit, state }: ActionContext<ITransactionState, IRootState>,
    options: {
      organisationId: string
      next?: string | null
    }
  ): Promise<void> {
    await commit('SET_IS_LOADING', true)

    if (options.next === undefined) {
      options.next = null
    } else {
      await commit(
        options.next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING',
        true
      )
    }

    try {
      const tableData = await resources.fetchOrganisationTransactionsTableData(
        state.filters,
        options.organisationId,
        options.next
      )

      await commit('SET_TABLE_DATA', { tableData, previousNext: options.next })
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    }
  },
  async fetchCSVExport(
    { commit, state }: ActionContext<ITransactionState, IRootState>,
    options: { organisationId: string }
  ): Promise<void> {
    const fetchQuery = filterNormalizer(
      state.filters,
      normalizeFilterRules
    ) as IFetchQuery
    await commit('SET_IS_LOADING_EXPORT', true)

    if (state.filters.deliveryFrom && state.filters.deliveryFrom.length) {
      fetchQuery.deliveryFrom = (state.filters.deliveryFrom as Date[])
        .map((timestamp: Date) => moment(timestamp, 'YYYY-MM-DD').unix())
        .join(',')
    }

    try {
      const response = await HttpService.request({
        responseType: 'text',
        query: { csv: 1, ...fetchQuery },
        path: `/organisations/${options.organisationId}/transactions`,
      })

      await commit('SET_IS_LOADING_EXPORT', false)

      return response
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING_EXPORT', false)
    }
  },
  async fetchFiltersFromQuery(
    { commit }: ActionContext<ITransactionState, IRootState>,
    query: ITransactionFilters
  ): Promise<void> {
    await commit('SET_FILTERS', filterNormalizer(query, normalizeFilterRules))
  },
  async fetchTransactionPreCalculation(
    { commit }: ActionContext<ITransactionState, IRootState>,
    id: string
  ): Promise<void> {
    try {
      const transactionPreCalculation = await resources.precalculateTransactionForOffer(
        id
      )

      await commit(
        'SET_IS_TRANSACTION_PRE_CALCULATION',
        transactionPreCalculation
      )
    } catch (error) {
      await commit('SET_ERROR', error)
    }
  },
  async fetchOfferTransactions(
    { commit }: ActionContext<ITransactionState, IRootState>,
    id: string
  ): Promise<void> {
    await commit('SET_IS_LOADING_OFFER_TRANSACTIONS', true)

    try {
      const response = (
        await HttpService.request({
          path: `/offers/${id}/transactions`,
        })
      ).sort(
        (a: ITransaction, b: ITransaction) => a.deliveryFrom - b.deliveryFrom
      )

      await commit('SET_OFFER_TRANSACTIONS', response)
      await commit('SET_IS_LOADING_OFFER_TRANSACTIONS', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING_OFFER_TRANSACTIONS', false)
    }
  },
  async fetchTransactionsByOffers(
    { commit, state }: ActionContext<ITransactionState, IRootState>,
    id: string
  ): Promise<void> {
    if (Object.keys(state.transactionsByOffers).includes(id)) {
      return
    }

    await commit('SET_IS_LOADING_OFFER_TRANSACTIONS', true)

    try {
      const response = (
        await HttpService.request({
          path: `/offers/${id}/transactions`,
        })
      ).sort(
        (a: ITransaction, b: ITransaction) => a.deliveryFrom - b.deliveryFrom
      )

      await commit('SET_TRANSACTIONS_BY_OFFERS', { id, response })
      await commit('SET_IS_LOADING_OFFER_TRANSACTIONS', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING_OFFER_TRANSACTIONS', false)
    }
  },
  async resetOfferTransactions({
    commit,
  }: ActionContext<ITransactionState, IRootState>): Promise<void> {
    await commit('RESET_OFFER_TRANSACTIONS')
  },
}
