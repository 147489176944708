import { IMarketplaceState } from './interfaces'
import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from '@/modules/marketplace/rules'

export const QUERY_DATE_FORMAT = 'YYYY-MM-DD'

export const getters = {
  getOffers(state: IMarketplaceState) {
    return state?.offers
  },
  getIsLoading(state: IMarketplaceState) {
    return state.isLoading
  },
  getIsLoadingFilters(state: IMarketplaceState) {
    return state.isLoadingFilters
  },
  getError(state: IMarketplaceState) {
    return state.error
  },
  getIsLoadingNext(state: IMarketplaceState) {
    return state.isLoadingNext
  },
  getFilters(state: IMarketplaceState) {
    return state.filters
  },
  getFilterPresets(state: IMarketplaceState) {
    return state.filterPresets
  },
  getActiveFilterPresetId(state: IMarketplaceState) {
    return state.activeFilterPresetId
  },
  getQueryFromFilters(state: IMarketplaceState) {
    return filterNormalizer(state.filters, normalizeFilterRules)
  },
  getTableDataNext(state: IMarketplaceState): string | null {
    return state.next
  },
}
