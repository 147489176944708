import { ActionContext } from 'vuex'
import { IRootState } from '@/interfaces'
import { IPriceIndexState } from './state'
import { getPriceIndicesResource } from './resources'

export const actions = {
  async loadPriceIndices({
    commit,
    state,
  }: ActionContext<IPriceIndexState, IRootState>): Promise<void> {
    if (Object.keys(state.priceIndices).length > 0) {
      return
    }

    await commit('SET_IS_LOADING', true)

    try {
      await commit('SET_PRICE_INDICES', await getPriceIndicesResource())
      await commit('SET_IS_LOADING', false)
    } catch (error) {
      await commit('SET_IS_LOADING', false)
    }
  },
}
