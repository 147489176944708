import { ActionContext } from 'vuex'
import { IOffer, IRootState } from '@/interfaces'
import { IOfferState, IOfferTemplate } from './interfaces'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { OfferContractType, OfferVisibility } from '@/enums'

interface IVisibilityType {
  isPrivate?: boolean
  isAnonymous?: boolean
}

export const actions = {
  async fetchOfferTemplates({
    commit,
    rootState,
  }: ActionContext<IOfferState, IRootState>): Promise<void> {
    try {
      const offerTemplates: IOfferTemplate[] = await HttpService.request({
        path: `/organisations/${rootState.auth.user.organisationId}/templates`,
      })
      commit('SET_OFFER_TEMPLATES', offerTemplates)
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async saveOfferTemplate(
    { commit }: ActionContext<IOfferState, IRootState>,
    params: {
      organisationId: string
      offer: IOffer
    }
  ): Promise<void> {
    try {
      const {
        sourceStation,
        destinationStation,
        paymentCondition,
        materialType,
        materialFormat,
        offerVisibility,
      } = params.offer

      const visibilityType: IVisibilityType = {}

      if (offerVisibility === OfferVisibility.Private) {
        visibilityType.isPrivate = true
      } else if (params.offer.offerVisibility === OfferVisibility.Anonymous) {
        visibilityType.isAnonymous = true
      }

      Object.assign(
        params.offer,
        {
          sourceStationId: sourceStation?.id,
          destinationStationId: destinationStation?.id,
          paymentConditionId: paymentCondition.id,
          materialTypeId: materialType.id,
          materialFormatId: materialFormat.id,
        },
        visibilityType
      )

      await HttpService.request({
        method: HttpMethod.Post,
        path: `/organisations/${params.organisationId}/templates`,
        body: params.offer,
      })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async fetchOffer(
    { commit }: ActionContext<IOfferState, IRootState>,
    offerId: string
  ): Promise<void> {
    if (!offerId) return

    await commit('SET_IS_LOADING', true)
    try {
      const response: IOffer = await HttpService.request({
        path: `/offers/${offerId}`,
      })

      if (response.contractType === OfferContractType.Recurring) {
        commit('SET_OFFER', { unitPriceIndex: null, ...response })

        return
      }

      commit('SET_OFFER', response)
    } catch (error) {
      commit('SET_ERROR', error)
    }
    await commit('SET_IS_LOADING', false)
  },
}
