import { Auth } from '@aws-amplify/auth'
import { CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js'
import { IUser } from '@/interfaces'
import { fetchUser } from '@/modules/user/resources'
import SocketService from '@/services/SocketService/SocketService'
import { i18n, refreshLibsLocale } from '@/i18n'

export interface IChangePasswordFormItems {
  oldPassword: string
  newPassword: string
}

export interface IForgotPasswordFormItems {
  email: string
  code: string
  password: string
}

const defaultForgotPasswordFormItems: IForgotPasswordFormItems = {
  email: '',
  code: '',
  password: '',
}

export const getDefaultForgotPasswordFormItems = (): IForgotPasswordFormItems => {
  return Object.assign({}, defaultForgotPasswordFormItems)
}

export const errorHandling = (error: any) => {
  if (!error.code) {
    return error || JSON.stringify(error) // TODO: find out why here is a stringify fallback;
  }

  let errorCode: string = error.code

  if (error.message) {
    const errorCodes = /"code":"([a-zA-Z.]+)"/gm.exec(error.message)

    if (errorCodes) {
      errorCode = errorCodes[1]
    }
  }

  const errorCodeLocaleKey = `cognito.error.${errorCode}`
  const errorCodeLocale = i18n.t(errorCodeLocaleKey).toString()

  if (errorCodeLocale !== errorCodeLocaleKey) {
    error.message = errorCodeLocale
  }

  return error
}

export default {
  async signIn(email: string, password: string): Promise<IUser> {
    try {
      const session = await Auth.signIn(email, password)
      const user = await this.setupAuth(session.getSignInUserSession())

      return user
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async signUp(
    email: string,
    password: string,
    notifications?: boolean
  ): Promise<ISignUpResult> {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
      clientMetadata: {
        metadata: JSON.stringify({ sendEmailNotifications: notifications }),
      },
    })
  },

  async confirmSignUp(email: string, code: string): Promise<string> {
    return Auth.confirmSignUp(email, code)
  },

  async changePassword({ oldPassword, newPassword }: IChangePasswordFormItems) {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.changePassword(user, oldPassword, newPassword)
    )
  },

  async forgotPassword(formItems: IForgotPasswordFormItems) {
    if (!formItems.code) {
      return Auth.forgotPassword(formItems.email.toLowerCase())
    }

    return Auth.forgotPasswordSubmit(
      formItems.email.toLowerCase(),
      formItems.code,
      formItems.password
    )
  },

  async resendConfirmationCode(email: string) {
    try {
      await Auth.resendSignUp(email.toLowerCase())
    } catch (error) {
      errorHandling(error)
    }
  },

  async signOut() {
    SocketService.disconnectSockets()

    return Auth.signOut()
  },

  async refreshSession(): Promise<IUser> {
    const cognitoUser = await Auth.currentUserPoolUser()

    return new Promise((resolve, reject) => {
      cognitoUser.getSession(
        (error: any, cognitoUserSession: CognitoUserSession) => {
          if (error) {
            return reject(error)
          }

          if (!cognitoUserSession.isValid()) {
            return reject(
              new Error(
                'Refresh session failed. Cognito user session is not valid.'
              )
            )
          }

          this.setupAuth(cognitoUserSession).then(resolve).catch(reject)
        }
      )
    })
  },

  async setupAuth(cognitoUserSession: CognitoUserSession): Promise<IUser> {
    const cognitoIdToken = cognitoUserSession.getIdToken()

    const user = await fetchUser(cognitoIdToken.payload.sub)
    if (user?.locale) {
      refreshLibsLocale(user.locale)
    }

    await SocketService.setup(user.id)

    return user
  },
}
