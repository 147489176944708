import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from '@/modules/auth/module'
import { app } from '@/modules/app/module'
import { notification } from '@/modules/notification/module'
import { materialType } from '@/modules/materialType/module'
import { subscription } from '@/modules/subscription/module'
import { materialFormatGroups } from '@/modules/materialFormatGroup/module'
import { transaction } from '@/modules/transaction/module'
import { marketplace } from '@/modules/marketplace/module'
import { offer } from '@/modules/offer/module'
import { contract } from '@/modules/contract/module'
import { organisation } from '@/modules/organisation/module'
import { user } from '@/modules/user/module'
import { station } from '@/modules/station/module'
import { priceIndex } from '@/modules/priceIndex/module'
import { delivery } from '@/modules/delivery/module'
import { rating } from '@/modules/rating/module'
import { dashboard } from '@/modules/dashboard/module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    notification,
    materialType,
    materialFormatGroups,
    priceIndex,
    subscription,
    transaction,
    marketplace,
    contract,
    offer,
    organisation,
    user,
    station,
    delivery,
    rating,
    dashboard,
  },
})
