import { ITransactionFilters, ITransaction } from './resources'
import { ITransactionState } from './state'
import { IOrganisation, ITableData } from '@/interfaces'

export const mutations = {
  SET_IS_LOADING: (
    state: ITransactionState,
    isTransactionsLoading: boolean
  ) => {
    state.isLoading = isTransactionsLoading
  },
  SET_FILTERS: (state: ITransactionState, filters: ITransactionFilters) => {
    state.filters = filters
  },
  SET_IS_LOADING_NEXT: (
    state: ITransactionState,
    isTransactionsLoading: boolean
  ) => {
    state.isLoadingNext = isTransactionsLoading
  },
  SET_ERROR: (state: ITransactionState, error: Error) => {
    state.error = error
  },
  SET_TABLE_DATA: (
    state: ITransactionState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    state.transactions = options.previousNext
      ? (state.transactions || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_ORGANISATION: (state: ITransactionState, result: IOrganisation) => {
    state.organisation = result
  },
  SET_IS_LOADING_EXPORT: (
    state: ITransactionState,
    isLoadingExport: boolean
  ) => {
    state.isLoadingExport = isLoadingExport
  },
  SET_IS_TRANSACTION_PRE_CALCULATION: (
    state: ITransactionState,
    result: ITransaction
  ) => {
    state.preCalculation = result
  },
  SET_IS_LOADING_OFFER_TRANSACTIONS: (
    state: ITransactionState,
    isLoadingTransactions: boolean
  ) => {
    state.isLoadingTransactions = isLoadingTransactions
  },
  SET_TRANSACTIONS_BY_OFFERS: (
    state: ITransactionState,
    { id, response }: { id: string; response: ITransaction[] }
  ) => {
    ;(state.transactionsByOffers || {})[id] = response
  },
  SET_OFFER_TRANSACTIONS: (
    state: ITransactionState,
    result: ITransaction[]
  ) => {
    state.offerTransactions = result
  },
  RESET_OFFER_TRANSACTIONS: (state: ITransactionState) => {
    state.offerTransactions = []
  },
}
