import HttpService, {
  HttpMethod,
  IHttpParams,
} from '@/services/HttpService/HttpService'
import {
  ICommon,
  IOffer,
  IOrganisation,
  IStation,
  IUser,
  RatedCategory,
  IBid,
  ICommonFetchQuery,
  ITableData,
} from '@/interfaces'
import { TransactionStatus } from '@/enums'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'
import moment from 'moment'

export interface IParticipant {
  organisation: IOrganisation
  user: IUser
  organisationId?: string
  station?: IStation
  name?: string
  contactPerson?: string
}

export interface ICalculation {
  unitFee: number
  totalFee: number
  invoicingFreeAmountUsed: number
  totalAmount: number
  indexedPrice?: number
}

export interface IKey {
  id: string
}

export interface IMeta {
  lastEvaluatedKey?: IKey
}

export interface IDeliveryAmount {
  unitAmount: number
  totalAmount: number
}

export interface ITransactionFilters {
  [name: string]: any
  deliveryFrom?: Date[] | string
  partnersOrganisationsId?: string[]
  materialTypeId?: string[]
  notRated?: boolean | string
}

export interface ITransaction extends ICommon {
  organisationId: string
  offerId: string
  transactionNumber: string
  buyer: IParticipant
  transactionStatus: TransactionStatus
  seller: IParticipant
  offer: IOffer
  deliveryFrom: number
  deliveryTo: number
  deliveryAmount: IDeliveryAmount
  calculation: ICalculation
  rating?: RatedCategory[]
  invoiceNumber?: string
  partner?: IParticipant
}

export interface IFetchQuery extends ICommonFetchQuery {
  deliveryFrom?: string
  tradingPartners?: string
  notRated?: string | boolean
}

const fetchOrganisationTransactions = async (
  organisationId: string,
  query?: ICommonFetchQuery
) => {
  const response = await HttpService.request({
    query,
    path: `/organisations/${organisationId}/transactions`,
  })

  return response as {
    transactions: ITransaction[]
    meta?: IMeta
  }
}

const fetchOrganisationTransactionsTableData = async (
  filters: ITransactionFilters,
  organisationId: string,
  next: string | null
): Promise<ITableData> => {
  const fetchQuery = Object.assign({}, filters) as IFetchQuery

  if (filters.deliveryFrom) {
    fetchQuery.deliveryFrom = (filters.deliveryFrom as Date[])
      .map((timestamp: Date) => moment(timestamp, 'YYYY-MM-DD').unix())
      .join(',')
  }

  return getTableData({
    fetchQuery,
    next,
    fetchRows: fetchOrganisationTransactions.bind(this, organisationId),
    rowsKey: 'transactions',
  })
}

/**
 * For the create bid routing, bid is not persisted on the server, and we don't have an id. So for that case we
 * have to make a POST request and send bid itself.
 * In case of the accept bid routing, we have a bid on the server, so we can do a GET request,
 * passing bid id to the server.
 */
const precalculateTransactionForBid = async (
  offerId: string,
  bid: IBid
): Promise<ITransaction[]> => {
  const method = bid.id ? HttpMethod.Get : HttpMethod.Post
  const httpParams: IHttpParams = {
    path: `/transactions/${offerId}/pre-calculate`,
    method,
  }

  if (method === HttpMethod.Post) {
    httpParams.body = bid
  }

  if (method === HttpMethod.Get) {
    httpParams.path += `/${bid.id}`
  }

  return HttpService.request(httpParams)
}

const precalculateTransactionForOffer = async (
  offerId: string
): Promise<ITransaction[]> => {
  const httpParams: IHttpParams = {
    path: `/transactions/${offerId}/pre-calculate`,
    method: HttpMethod.Get,
  }

  return HttpService.request(httpParams)
}

export default {
  fetchOrganisationTransactions,
  fetchOrganisationTransactionsTableData,
  precalculateTransactionForBid,
  precalculateTransactionForOffer,
}
