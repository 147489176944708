import HttpService from '@/services/HttpService/HttpService'
import { IPriceIndices } from '@/interfaces'

export const getPriceIndicesResource = async (): Promise<IPriceIndices> => {
  const response = await HttpService.request({
    path: '/price-indexes',
  })

  return response as IPriceIndices
}
