import { IFileListItem, IOrganisation, ICommonFetchQuery } from '@/interfaces'
import enums, {
  BankAccountType,
  OrganisationStatus,
  OrganisationType,
} from '@/enums'
import moment from 'moment'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'

interface IKey {
  id: string
}

export interface IMeta {
  lastEvaluatedKey?: IKey
}

export interface IOrganisationsFetchQuery extends ICommonFetchQuery {
  status?: OrganisationStatus
}

export interface ISaveFileQuery {
  id: string
  documents: IFileListItem[]
}

export interface IFormItems {
  id: string | null
  name: string
  description: string
  contactEmail: string
  contactPhoneNumber: string
  contactMobilePhoneNumber: string
  contactFaxNumber: string
  addressStreetName: string
  addressStreetNumber: string
  addressAdditional: string
  addressPostCode: string
  addressCity: string
  addressCountyCode: string
  bankAccountHolderName: string
  bankAccountIban: string
  bankAccountBic: string
  vatId: string
  commercialRegisterNumber: string
  localCourt: string
  invoiceEmail: string
  managingDirector: string
  documents: string[] | null
  invoicingStartsAt?: Date | null
  organisationStatus?: OrganisationStatus
  invoicingFreeAmount?: number | null
  organisationType?: OrganisationType | null
  certified?: boolean | null
  materialTypes?: string[]
  tradeVolume?: number
  materialFormatGroups?: string[]
  includedShippingRadius?: number
  percentageOfSpots?: number
  deliveryTypes?: string[]
  registrationGoal?: number | undefined
  receiveMatchingOffers?: boolean
}

type FormItemProp = keyof IFormItems

export const defaultFormItems: IFormItems = {
  id: null,
  name: '',
  description: '',
  contactEmail: '',
  contactPhoneNumber: '',
  contactMobilePhoneNumber: '',
  contactFaxNumber: '',
  addressStreetName: '',
  addressStreetNumber: '',
  addressAdditional: '',
  addressPostCode: '',
  addressCity: '',
  addressCountyCode: '',
  bankAccountHolderName: '',
  bankAccountIban: '',
  bankAccountBic: '',
  vatId: '',
  commercialRegisterNumber: '',
  localCourt: '',
  invoiceEmail: '',
  managingDirector: '',
  documents: null,
  invoicingStartsAt: null,
  organisationStatus: OrganisationStatus.Draft,
  invoicingFreeAmount: null,
  organisationType: null,
  certified: null,
  materialTypes: [],
  tradeVolume: 0,
  materialFormatGroups: [],
  deliveryTypes: [],
  registrationGoal: undefined,
  receiveMatchingOffers: true,
}

export interface ISaveQuery {
  id?: string
  name: string
  description: string | null
  contactEmail: string | null
  contactPhoneNumber: string | null
  contactMobilePhoneNumber: string | null
  contactFaxNumber: string | null
  addressStreetName: string
  addressStreetNumber: string
  addressAdditional: string | null
  addressPostCode: string
  addressCity: string
  addressCountyCode: string
  bankAccountType: BankAccountType
  bankAccountHolderName: string | null
  bankAccountIban: string | null
  bankAccountBic: string | null
  vatId: string | null
  commercialRegisterNumber: string | null
  localCourt: string
  invoiceEmail: string | null
  managingDirector: string
  invoicingStartsAt?: number
  organisationStatus?: OrganisationStatus
  invoicingFreeAmount?: number | null
  organisationType?: string | null
  materialTypes?: string[]
  tradeVolume?: number
  materialFormatGroupId?: string[]
  includedShippingRadius?: number
  percentageOfSpots?: number
  deliveryTypes?: string[]
  [key: string]: any
}

const saveOrganisation = async (
  query: ISaveQuery | ISaveFileQuery
): Promise<IOrganisation> => {
  const response = await HttpService.request({
    path: `/organisations/${query.id || ''}`,
    method: query.id ? HttpMethod.Put : HttpMethod.Post,
    body: query,
  })

  return response as IOrganisation
}

export const fetchOrganisations = async (query?: IOrganisationsFetchQuery) => {
  const response = await HttpService.request({
    query,
    path: '/organisations',
  })

  return response as {
    organisations: IOrganisation[]
    meta?: IMeta
  }
}

export const saveOrganisationFromFormItems = async (
  formItems: IFormItems,
  isGlobalAdmin: boolean = false
): Promise<IOrganisation> => {
  if (
    formItems.organisationStatus !== enums.OrganisationStatus.Disabled &&
    (!formItems.name ||
      !formItems.addressStreetName ||
      !formItems.addressStreetNumber ||
      !formItems.addressPostCode ||
      !formItems.addressCity ||
      !formItems.addressCountyCode ||
      !formItems.managingDirector ||
      (!formItems.vatId &&
        (!formItems.commercialRegisterNumber || !formItems.localCourt))) &&
    !isGlobalAdmin
  ) {
    return Promise.reject(new Error('Form items where not validated correctly'))
  }

  const saveQuery: ISaveQuery = {
    name: formItems.name,
    description: formItems.description || null,
    contactEmail: formItems.contactEmail
      ? formItems.contactEmail.toLowerCase()
      : null,
    contactPhoneNumber: formItems.contactPhoneNumber || null,
    contactMobilePhoneNumber: formItems.contactMobilePhoneNumber || null,
    contactFaxNumber: formItems.contactFaxNumber || null,
    addressStreetName: formItems.addressStreetName,
    addressStreetNumber: formItems.addressStreetNumber,
    addressAdditional: formItems.addressAdditional || null,
    addressPostCode: formItems.addressPostCode,
    addressCity: formItems.addressCity,
    addressCountyCode: formItems.addressCountyCode,
    bankAccountType: BankAccountType.Iban,
    bankAccountHolderName: formItems.bankAccountHolderName || null,
    bankAccountIban: formItems.bankAccountIban || null,
    bankAccountBic: formItems.bankAccountBic || null,
    vatId: formItems.vatId || null,
    commercialRegisterNumber: formItems.commercialRegisterNumber || null,
    localCourt: formItems.localCourt,
    invoiceEmail: formItems.invoiceEmail
      ? formItems.invoiceEmail.toLowerCase()
      : null,
    managingDirector: formItems.managingDirector,
    receiveMatchingOffers: formItems.receiveMatchingOffers,
    certified: formItems.certified,
  }

  if (formItems.invoicingStartsAt) {
    saveQuery.invoicingStartsAt = moment.utc(formItems.invoicingStartsAt).unix()
  }

  if (formItems.includedShippingRadius) {
    saveQuery.includedShippingRadius = Number(formItems.includedShippingRadius)
  }

  ;([
    'organisationStatus',
    'id',
    'invoicingFreeAmount',
    'organisationType',
    'materialTypes',
    'tradeVolume',
    'materialFormatGroups',
    'percentageOfSpots',
    'deliveryTypes',
    'registrationGoal',
  ] as FormItemProp[]).forEach((key) => {
    if (formItems[key]) {
      saveQuery[key] = formItems[key]
    }
  })

  return saveOrganisation(saveQuery)
}

export const fetchOrganisation = async (id: string): Promise<IOrganisation> => {
  const response = await HttpService.request({
    path: `/organisations/${id}`,
  })

  return response as IOrganisation
}

export const getFormItemsFromOrganisation = (
  organisation: IOrganisation
): IFormItems => {
  return {
    id: organisation.id,
    name: organisation.name || defaultFormItems.name,
    description: organisation.description || defaultFormItems.description,
    contactEmail: organisation.contactEmail || defaultFormItems.contactEmail,
    contactPhoneNumber:
      organisation.contactPhoneNumber || defaultFormItems.contactPhoneNumber,
    contactMobilePhoneNumber:
      organisation.contactMobilePhoneNumber ||
      defaultFormItems.contactMobilePhoneNumber,
    contactFaxNumber:
      organisation.contactFaxNumber || defaultFormItems.contactFaxNumber,
    addressStreetName:
      organisation.addressStreetName || defaultFormItems.addressStreetName,
    addressStreetNumber:
      organisation.addressStreetNumber || defaultFormItems.addressStreetNumber,
    addressAdditional:
      organisation.addressAdditional || defaultFormItems.addressAdditional,
    addressPostCode:
      organisation.addressPostCode || defaultFormItems.addressPostCode,
    addressCity: organisation.addressCity || defaultFormItems.addressCity,
    addressCountyCode:
      organisation.addressCountyCode || defaultFormItems.addressCountyCode,
    bankAccountHolderName:
      organisation.bankAccountHolderName ||
      defaultFormItems.bankAccountHolderName,
    bankAccountIban:
      organisation.bankAccountIban || defaultFormItems.bankAccountIban,
    bankAccountBic:
      organisation.bankAccountBic || defaultFormItems.bankAccountBic,
    documents: organisation.documents || defaultFormItems.documents,
    vatId: organisation.vatId || defaultFormItems.vatId,
    commercialRegisterNumber:
      organisation.commercialRegisterNumber ||
      defaultFormItems.commercialRegisterNumber,
    localCourt: organisation.localCourt || defaultFormItems.localCourt,
    invoiceEmail: organisation.invoiceEmail || defaultFormItems.invoiceEmail,
    managingDirector:
      organisation.managingDirector || defaultFormItems.managingDirector,
    invoicingStartsAt: organisation.invoicingStartsAt
      ? moment.unix(organisation.invoicingStartsAt).toDate()
      : defaultFormItems.invoicingStartsAt,
    invoicingFreeAmount:
      organisation.invoicingFreeAmount || defaultFormItems.invoicingFreeAmount,
    organisationStatus:
      organisation.organisationStatus || defaultFormItems.organisationStatus,
    organisationType:
      organisation.organisationType || defaultFormItems.organisationType,
    certified: organisation.certified || defaultFormItems.certified,
    materialTypes: organisation.materialTypes || defaultFormItems.materialTypes,
    tradeVolume: organisation.tradeVolume || defaultFormItems.tradeVolume,
    materialFormatGroups:
      organisation.materialFormatGroups ||
      defaultFormItems.materialFormatGroups,
    percentageOfSpots:
      organisation.percentageOfSpots || defaultFormItems.percentageOfSpots,
    includedShippingRadius:
      organisation.includedShippingRadius ||
      defaultFormItems.includedShippingRadius,
    deliveryTypes: organisation.deliveryTypes || defaultFormItems.deliveryTypes,
    registrationGoal:
      Number(organisation.registrationGoal) ||
      defaultFormItems.registrationGoal,
    receiveMatchingOffers:
      organisation.receiveMatchingOffers ??
      defaultFormItems.receiveMatchingOffers,
  }
}
