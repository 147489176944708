





import marked from 'marked'
import { PropType, VNode } from 'vue'

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false,
})

export default {
  name: 'MarkdownComponent',
  props: {
    cssClass: {
      type: String as PropType<string>,
      default: '',
    },
  },
  data(): any {
    return {
      html: '',
    }
  },
  watch: {
    '$i18n.locale': {
      deep: true,
      handler /* istanbul ignore next */() {
        this.createHTML()
      },
    },
  },
  beforeMount() {
    this.createHTML()
  },
  methods: {
    createHTML() {
      let text = ''
      this.$slots.default.forEach((slot: VNode) => {
        if (slot.tag === 'a') {
          const attrs = slot.data?.attrs
          const attributes: string = Object.keys(attrs || {})
            .map((attr: any) => `${attr}="${attrs?.[attr]}"`)
            .join(' ')

          text += `<a ${attributes}>${slot.children?.[0].text}</a>`
        } else if (slot.tag === 'br') {
          text += `<br />`
        } else {
          text += `${slot.text}`
        }
      })

      text = text.trim().replace(/\\n/g, '\n')

      this.html = marked(text)
    },
  },
}
