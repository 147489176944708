import { appConfig } from '@/config/config'

export interface IFetchQuery {
  startKey?: string
  limit?: number
}

export interface ITableData {
  rows: any[]
  next: string | null
}

interface IKey {
  id: string
}

export interface IMeta {
  lastEvaluatedKey?: IKey
}

interface ITableDataOptions {
  fetchRows: (...args: any[]) => Promise<any>
  fetchQuery: IFetchQuery
  rowsKey: string
  next: string | null
  limit?: number
}

export const getTableData = async (
  options: ITableDataOptions
): Promise<ITableData> => {
  return new Promise((resolve, reject) => {
    if (options.next) {
      options.fetchQuery.startKey = options.next
    }

    options.fetchQuery.limit = options.limit || appConfig.defaultLimit
    options
      .fetchRows(options.fetchQuery)
      .then((response) => {
        resolve({
          rows: response[options.rowsKey] || [],
          next: ((response.meta || {}).lastEvaluatedKey || {}).id || null,
        })
      })
      .catch(reject)
  })
}
