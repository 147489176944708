import { ActionContext } from 'vuex'
import { IRootState, IUser, IMeta } from '@/interfaces'
import { IUserState } from './interfaces'
import HttpService from '@/services/HttpService/HttpService'
import { IFetchQuery } from '@/modules/user/interfaces'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'

export const actions = {
  async fetchUsers(
    { commit }: ActionContext<IUserState, IRootState>,
    next?: string | null
  ): Promise<void> {
    try {
      await commit('SET_IS_LOADING', true)

      if (next === undefined) {
        next = null
      } else {
        await commit(next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING', true)
      }

      const fetchQuery = {} as IFetchQuery

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: '/users',
        })

        return response as {
          users: IUser[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next,
        fetchRows,
        rowsKey: 'users',
        limit: Number.MAX_SAFE_INTEGER,
      })

      await commit('SET_TABLE_DATA', { tableData, previousNext: next })
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    }
  },
}
