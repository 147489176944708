import { ITableData } from '@/utils/TableDataUtil/TableDataUtil'
import { IStationState } from './state'
import { replaceItemWithSameId, removeItemWithSameId } from '@/utils/utils'
import { IStation } from '@/interfaces'

export const mutations = {
  SET_TABLE_DATA: (
    state: IStationState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    state.stations = options.previousNext
      ? (state.stations || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_IS_LOADING: (state: IStationState, isStationsLoading: boolean) => {
    state.isLoading = isStationsLoading
  },
  SET_IS_LOADING_NEXT: (state: IStationState, isStationsLoading: boolean) => {
    state.isLoadingNext = isStationsLoading
  },
  SET_ERROR: (state: IStationState, error: Error) => {
    state.error = error
  },
  SET_STATION: (state: IStationState, station: IStation) => {
    state.stations = replaceItemWithSameId(state.stations || [], station)
  },
  REMOVE_STATION: (state: IStationState, station: IStation) => {
    state.stations = removeItemWithSameId(state.stations || [], station)
  },
}
