import {
  IDashboardState,
  IPartnerFilterItems,
  IPartnerItems,
  IMaterialTypeCodesFilterItems,
  IContractTypeChartData,
} from './interfaces'
import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from '@/modules/dashboard/rules'
import { i18n } from '@/i18n'

export const getters = {
  getIsLoading(state: IDashboardState): boolean {
    return state.isLoading
  },
  getFiltersFromQuery(state: IDashboardState) {
    return state.filters
  },
  getQueryFromFilters(state: IDashboardState) {
    return filterNormalizer(state.filters, normalizeFilterRules)
  },
  getAmountPerMonthChartData(state: IDashboardState) {
    return state.amountPerMonthChartData
  },
  getPartnerFilterItemsData(state: IDashboardState) {
    return state.partnerFilterItems?.map(
      (item: IPartnerItems) =>
        ({
          text: `${item.organisationName} (${item.transactionsCount ?? 0})`,
          value: item.organisationId,
        } as IPartnerFilterItems)
    )
  },
  getMaterialTypeCodeFilterItemsData(state: IDashboardState) {
    return state.materialTypeCodesFilterItems?.map(
      (item: IMaterialTypeCodesFilterItems) => item.materialTypeCode
    )
  },
  getAmountPerOrganisationChartData(state: IDashboardState) {
    return state.amountPerOrganisationChartData
  },
  getContractTypeChartData(state: IDashboardState) {
    return state.contractTypeChartData?.map((item: IContractTypeChartData) => {
      return {
        ...item,
        contractType: i18n.t(
          `dashboard.filter.contractType.${item.contractType}`
        ),
      }
    })
  },
  getPricePerMaterialTypeChartData(state: IDashboardState) {
    return state.pricePerMaterialTypeChartData
  },
  getPricePerRegionChartData(state: IDashboardState) {
    return state.pricePerRegionChartData
  },
  getPricePerPartnerChartData(state: IDashboardState) {
    return state.pricePerPartnerChartData
  },
}
