















import { computed, defineComponent, PropType } from '@vue/composition-api'

type ICheckboxValue = boolean | (number | string)[]

export default defineComponent({
  name: 'Checkbox',
  props: {
    value: {
      type: [Boolean, Array] as PropType<ICheckboxValue>,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    multiKey: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get(): ICheckboxValue {
        return props.value
      },
      set(newValue: ICheckboxValue) {
        emit('input', newValue)
        emit('change', newValue)
      },
    })

    return { model }
  },
})
