import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { FileAction, S3DocumentType } from '@/enums'

export interface IDocumentOptions {
  file: File
  url: string
}

const getFilePath = (id: string, type: S3DocumentType) => {
  switch (type) {
    case S3DocumentType.Organisation:
      return `organisations/${id}/documents`
    case S3DocumentType.Station:
      return `stations/${id}/documents`
    case S3DocumentType.Transaction:
      return `transactions/${id}/documents/confirmation`
    case S3DocumentType.DeliveryWeightNote:
      return `transactions/${id}/documents/deliveries`
    case S3DocumentType.DeliveryWeightNoteAll:
      return `transactions/${id}/documents/archives`
    case S3DocumentType.ConfirmedDelivery:
      return `confirmed-deliveries/${id}/documents`
  }
}

export const getFileUploadOptions = async (
  id: string,
  type: S3DocumentType,
  file: File,
  action: FileAction
): Promise<IDocumentOptions> => {
  const response = await HttpService.request({
    path: '/files/sign-request',
    method: HttpMethod.Post,
    body: {
      contentType: file.type,
      filePath: `${getFilePath(id, type)}/${file.name}`,
      action,
    },
  })

  return {
    file,
    url: response.url,
  } as IDocumentOptions
}

export const openUrl = (url: string, target?: boolean, fileName?: string) => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  const link = document.createElement('a')
  link.href = url
  // IOS devices blocks opening windows in separate tabs for security reasons
  if (!isIOS && !target) {
    link.target = '_blank'
  }

  if (fileName) {
    link.download = fileName
  }

  document.body.appendChild(link)

  link.click()
}

export const downloadFile = async (
  id: string,
  type: S3DocumentType,
  fileName: string | File
): Promise<IDocumentOptions> => {
  const response = await HttpService.request({
    path: '/files/sign-request',
    method: HttpMethod.Post,
    body: {
      filePath: `${getFilePath(id, type)}/${fileName}`,
      action: FileAction.Download,
    },
  })

  return {
    url: response.url,
  } as IDocumentOptions
}

export const uploadFile = async (options: IDocumentOptions) => {
  await fetch(options.url, {
    method: HttpMethod.Put,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': options.file.type,
      'Access-Control-Allow-Origin': '1',
    },
    body: options.file,
  })
}

export const deleteFile = async (options: IDocumentOptions) => {
  await fetch(options.url, {
    method: HttpMethod.Delete,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
}
