




















import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FloatingMenu',
  props: {
    isActivatedByClick: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
