import Vue from 'vue'
// @ts-ignore
import locale from 'element-ui/lib/locale'
import { i18n, elementLocaleMessages } from '@/i18n'

import {
  Autocomplete,
  DatePicker,
  Popover,
  Form,
  FormItem,
  Row,
  Col,
  Card,
  MessageBox,
  Message,
} from 'element-ui'

locale.use(elementLocaleMessages(i18n.locale))

export const refreshElementUI = () => {
  ;[Autocomplete, DatePicker, Popover, Form, FormItem, Row, Col, Card].forEach(
    (plugin) => {
      Vue.use(plugin, {
        i18n: (key: string, value: string) => i18n.t(key, value),
      })
    }
  )
}

refreshElementUI()

const _MessageBox = MessageBox
const { alert, confirm } = _MessageBox

Vue.prototype.$alert = alert
Vue.prototype.$confirm = confirm
Vue.prototype.$message = Message
