import store from '@/store'
import tracking from '@/tracking'

export default {
  name: 'ErrorBoundary',
  created: function () {
    store.subscribe((mutation) => {
      if (mutation.type.includes('SET_ERROR')) {
        const { payload: error } = mutation
        if (error) {
          this.showError(error)
        }
      }
    })
  },
  destroyed() {
    this.$message.closeAll()
    store.subscribe(() => {})
  },
  watch: {
    $route() {
      this.$message.closeAll()
    },
  },
  errorCaptured(error: Error) {
    if (error) {
      this.showError(error)
    }
  },
  methods: {
    showError(error: Error) {
      this.$message.closeAll()
      this.errorMessageRender(error)
      tracking.trackError(error)
    },
    errorMessageRender(error: Error) {
      this.$message.error({
        dangerouslyUseHTMLString: true,
        message: `${error.message}`,
        showClose: true,
        duration: 0,
      })
    },
  },
  render() {
    return this.$slots?.default?.[0]
  },
}
