import { ActionContext } from 'vuex'
import { IRootState } from '@/interfaces'
import { ISubscriptionState, getDefaultFilters } from './state'
import { IFilters } from './interfaces'

import { OfferStatus, OfferType, CreatedBy } from '@/enums'
import resources from '@/modules/subscription/resources'

export interface IQuery {
  materialTypes?: string // material types
  offerTypes?: string // offer types
  offerStatus?: OfferStatus // offer status
  createdBy?: CreatedBy // created by [ user | organisation ]
}

export interface IOption {
  next?: string | null
  hideLoader?: boolean
}

export const actions = {
  async fetchSubscriptions(
    { commit, state, rootState }: ActionContext<ISubscriptionState, IRootState>,
    option: IOption = {}
  ): Promise<void> {
    if (!option?.hideLoader) {
      await commit(
        option?.next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING',
        true
      )
    }

    try {
      const tableData = await resources.fetchSubscriptionsTableData(
        state.filters,
        rootState.auth.user,
        option?.next || null
      )
      await commit('SET_TABLE_DATA', {
        tableData,
        previousNext: option?.next || null,
      })
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
      await commit('SET_IS_LOADING_NEXT', false)
    }
  },
  async fetchFiltersFromQuery(
    { commit }: ActionContext<ISubscriptionState, IRootState>,
    query: IQuery
  ): Promise<void> {
    const filters = getDefaultFilters()

    if (query.offerStatus) {
      filters.offerStatus = query.offerStatus
    }

    if (query.offerTypes) {
      filters.offerTypes = []
      query.offerTypes.split('_').forEach((value: string) => {
        // @ts-expect-error
        if (Object.values(OfferType).includes(value)) {
          filters.offerTypes.push(value as OfferType)
        }
      })
    }

    if (query.createdBy) {
      filters.createdBy = query.createdBy
    }

    if (query.materialTypes) {
      filters.materialTypes = query.materialTypes
        .split('_')
        .filter((item: string) => !!item)
    }

    await commit('SET_FILTERS', filters)
  },
  async setSubscriptionFilters(
    { commit }: ActionContext<ISubscriptionState, IRootState>,
    filters: IFilters
  ): Promise<void> {
    await commit('SET_FILTERS', filters)
  },
}
