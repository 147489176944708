import HttpService from '@/services/HttpService/HttpService'
import { IMaterialType } from '@/interfaces'
import { appConfig } from '@/config/config'

export const materialTypesResource = async (): Promise<IMaterialType[]> => {
  const response = await HttpService.request({
    path: `/materials/${appConfig.paperMaterialId}/types`,
  })

  return response.materialTypes as IMaterialType[]
}
