






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'LoadingSpinner',
  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
