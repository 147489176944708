import { ActionContext } from 'vuex'
import { IDeliveriesByDate, IDelivery, IRootState } from '@/interfaces'
import {
  IDeliveryState,
  ISetAggregatedDeliveriesPayload,
} from '@/modules/delivery/state'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import union from 'lodash/union'
import moment from 'moment-timezone'

import { DeliveryRawSyncStatus } from '@/enums'

interface IScheduleChange {
  created: IDelivery[]
  deleted: IDelivery[]
}

function extractStatus(aggregated: IDeliveriesByDate, status: string) {
  return (cary: {}, idx: string) => ({
    ...cary,
    ...(aggregated[idx][status] ? { [idx]: aggregated[idx][status] } : {}),
  })
}

function composeForDeletion(raw: IDelivery[], date: string, length: number) {
  return raw.reduce(
    (rows: IDelivery[], row: IDelivery) => [
      ...rows,
      ...(rows.length < length &&
      row.deliverySyncStatus === DeliveryRawSyncStatus.Open &&
      date ===
        moment(row.expectedDeliveryDate)
          .tz('Europe/Berlin')
          .format('DD.MM.YYYY')
        ? [row]
        : []),
    ],
    []
  )
}

function composeForCreation(date: string, length: number) {
  const expectedDeliveryDate = moment(date, 'DD.MM.YYYY')
    .tz('Europe/Berlin')
    .valueOf()

  return new Array(length).fill({ expectedDeliveryDate }) as IDelivery[]
}

function interpretDiff(
  original: IDeliveriesByDate,
  updated: IDeliveriesByDate,
  raw: IDelivery[]
) {
  const originalFlat: { [date: string]: number } = Object.keys(original).reduce(
    extractStatus(original, DeliveryRawSyncStatus.Open),
    {}
  )
  const updatedFlat: { [date: string]: number } = Object.keys(updated).reduce(
    extractStatus(updated, DeliveryRawSyncStatus.Open),
    {}
  )

  return union(Object.keys(originalFlat), Object.keys(updatedFlat)).reduce(
    (changes: IScheduleChange, date: string) => {
      const totalChanged =
        date in originalFlat && date in updatedFlat
          ? updatedFlat[date] - originalFlat[date]
          : date in originalFlat
          ? -originalFlat[date]
          : updatedFlat[date]

      return {
        created: [
          ...changes.created,
          ...(totalChanged > 0 ? composeForCreation(date, totalChanged) : []),
        ],
        deleted: [
          ...changes.deleted,
          ...(totalChanged < 0
            ? composeForDeletion(raw, date, -totalChanged)
            : []),
        ],
      }
    },
    { created: [], deleted: [] } as IScheduleChange
  )
}

export const actions = {
  async applyChanges(
    { commit, state, dispatch }: ActionContext<IDeliveryState, IRootState>,
    {
      offerId,
      deliveriesAggregated: newDeliveriesAggregated,
    }: ISetAggregatedDeliveriesPayload
  ): Promise<void> {
    await commit('SET_IS_LOADING', true)

    const { created: toBeCreated, deleted: toBeDeleted } = interpretDiff(
      state.deliveriesAggregated,
      newDeliveriesAggregated,
      state.deliveries
    )

    try {
      if (toBeCreated.length) {
        await HttpService.request({
          path: `/offers/${offerId}/deliveries`,
          method: HttpMethod.Post,
          body: toBeCreated,
        })
      }

      if (toBeDeleted.length) {
        await HttpService.request({
          path: `/offers/${offerId}/deliveries?ids=${toBeDeleted
            .map((d: IDelivery) => d.id)
            .join(',')}`,
          method: HttpMethod.Delete,
        })
      }
    } catch (error) {
      await commit('SET_ERROR', error)
    }

    if (toBeCreated.length || toBeDeleted.length) {
      await dispatch('loadDeliveries', offerId)
    } else {
      await commit('SET_IS_LOADING', false)
    }
  },
  async loadDeliveries(
    { commit }: ActionContext<IDeliveryState, IRootState>,
    offerId: string
  ): Promise<void> {
    if (!offerId) {
      return
    }

    await commit('SET_IS_LOADING', true)
    try {
      const deliveries: IDelivery[] = await HttpService.request({
        path: `/offers/${offerId}/deliveries`,
      })
      await commit('SET_DELIVERIES', deliveries)
    } catch (error) {
      commit('SET_ERROR', error)
    }
    await commit('SET_IS_LOADING', false)
  },
  resetDeliveriesState({ commit }: ActionContext<IDeliveryState, IRootState>) {
    commit('SET_DEFAULT_STATE')
  },
}
