import {
  SET_IS_MATERIAL_FORMAT_GROUPS_LOADING,
  SET_MATERIAL_FORMAT_GROUPS,
} from '@/modules/materialFormatGroup/consts'
import { IMaterialFormatGroupState } from '@/modules/materialFormatGroup/state'
import { IMaterialFormatGroup } from '@/interfaces'

export const mutations = {
  [SET_MATERIAL_FORMAT_GROUPS]: (
    state: IMaterialFormatGroupState,
    materialFormatGroups: IMaterialFormatGroup[]
  ) => {
    state.materialFormatGroups = materialFormatGroups
  },
  [SET_IS_MATERIAL_FORMAT_GROUPS_LOADING]: (
    state: IMaterialFormatGroupState,
    isLoading: boolean
  ) => {
    state.isLoading = isLoading
  },
}
