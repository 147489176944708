import { ActionContext } from 'vuex'
import { INotification, IRootState, IMeta } from '@/interfaces'
import { INotificationState } from './state'
import { normalizeNotification } from '@/utils/NotificationUtil/NotificationUtil'
import { IFetchQuery } from '@/modules/station/interfaces'
import HttpService from '@/services/HttpService/HttpService'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'
import { NOTIFICATIONS_TO_SHOW } from '@/modules/notification/consts'
import resources from './resources'

export const actions = {
  async fetchPersonalNotifications({
    commit,
    state,
  }: ActionContext<INotificationState, IRootState>) {
    if (state.isLastPage) {
      return
    }

    await commit('SET_IS_LOADING', true)

    try {
      const response = await resources.fetchPersonalNotifications()
      await commit('SET_NOTIFICATIONS', response)
      if (response?.length === 0 || response?.length < state.pageSize) {
        await commit('SET_IS_LAST_PAGE', true)
      }
      await commit('SET_PAGE_SIZE', response?.length)
      await commit('SET_IS_LOADING', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
    }
  },

  async addNotification(
    { commit }: ActionContext<INotificationState, IRootState>,
    notification: INotification
  ) {
    normalizeNotification(notification)

    await commit('ADD_NOTIFICATION', notification)
  },
  async fetchAllNotifications(
    { commit }: ActionContext<INotificationState, IRootState>,
    option: {
      organisationId: string
      next?: string | null
    }
  ) {
    try {
      await commit('SET_IS_LOADING', true)

      if (option.next === undefined) {
        option.next = null
      } else {
        await commit(
          option.next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING',
          true
        )
      }

      const fetchQuery = {} as IFetchQuery

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: `/organisations/${option.organisationId}/events`,
        })

        return response as {
          events: INotification[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next: option.next,
        fetchRows,
        rowsKey: 'events',
      })
      await commit('SET_TABLE_DATA', { tableData, previousNext: option.next })
    } catch (error) {
      await commit('SET_ERROR', error)
    }
    await commit('SET_IS_LOADING', false)
    await commit('SET_IS_LOADING_NEXT', false)
  },

  async deleteNotification(
    { commit, state, dispatch }: ActionContext<INotificationState, IRootState>,
    notificationId: string
  ) {
    await commit('SET_IS_LOADING', true)
    try {
      await resources.deletePersonalNotification(notificationId)
      await commit('DELETE_NOTIFICATION', notificationId)
      const { notifications } = state

      if (notifications.length < NOTIFICATIONS_TO_SHOW) {
        await dispatch('fetchPersonalNotifications')
      }
      await commit('SET_IS_LOADING', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_LOADING', false)
    }
  },

  async deleteAllNotifications({
    commit,
  }: ActionContext<INotificationState, IRootState>) {
    await commit('SET_IS_LOADING', true)
    try {
      await resources.deleteAllPersonalNotifications()
      await commit('SET_NOTIFICATIONS', [])
    } catch (error) {
      await commit('SET_ERROR', error)
    }
    await commit('SET_IS_LOADING', false)
  },
}
