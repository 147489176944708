






















import NavBar from '@/components/common/NavBar.vue'
import { mapActions, mapGetters } from 'vuex'
import enums, { LocaleCode } from '@/enums'
import CookieBar from '@/components/common/CookieBar.vue'
import FooterComponent from '@/components/common/FooterComponent/FooterComponent.vue'
import ErrorBoundary from '@/errorBoundary'
import { RestrictedOrganisationModal } from '@/components/common/RestrictedOrganisationModal'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'

interface ISignState {
  isSignedIn: boolean
  isSignedUp: boolean
}

export default {
  name: 'App',
  components: {
    CookieBar,
    RestrictedOrganisationModal,
    NavBar,
    FooterComponent,
    ErrorBoundary,
    LoadingSpinner,
  },
  provide() {
    return {
      enums,
    }
  },
  data: () => {
    return {
      enums,
      isRedirectedToOrganisationOnce: false,
      areCookiesAccepted: false,
      isPublicRoute: false,
      homeRoute: 'home',
    }
  },
  computed: {
    ...mapGetters('auth', {
      isLoading: 'getIsLoading',
      isSignedIn: 'getIsSignedIn',
      organisationStatus: 'getOrganisationStatus',
      user: 'getUser',
    }),
    ...mapGetters('app', {
      hasCookieConsent: 'getCookieConsent',
    }),
  },
  watch: {
    $route: {
      deep: true,
      handler /* istanbul ignore next */() {
        this.isPublicRoute = this.$route.meta.isPublicRoute

        if (this.$route.query.invite) {
          this.$router.push({ name: 'signUp', query: this.$route.query })
        }

        this.redirectIfSignedIn()
      },
    },
    'user.locale'(newLocale: string) {
      this.setDocumentTitle(newLocale)
      const locale = newLocale ?? LocaleCode.DE
      this.$vuetify.lang.current = locale.toLowerCase()
    },
  },
  created: async function () {
    this.redirectIfSignedIn()
    this.$store.watch(
      (_: any, getters: { [x: string]: any }) => ({
        isSignedIn: getters['auth/getIsSignedIn'],
        isSignedUp: getters['auth/getIsSignedUp'],
      }),
      ({ isSignedIn, isSignedUp }: ISignState) => {
        if (!isSignedIn && !isSignedUp) {
          this.$router.push({ name: 'signIn' })
        }

        if (isSignedIn) {
          this.$recyfyNotificationService(this).subscribe('organisation')
          this.$recyfyNotificationService(this).subscribe('personal')
        }
      }
    )
  },
  methods: {
    ...mapActions('app', ['acceptCookies']),
    redirectIfSignedIn() {
      if (
        !this.isRedirectedToOrganisationOnce &&
        this.organisationStatus === this.enums.OrganisationStatus.Draft &&
        this.user
      ) {
        this.isRedirectedToOrganisationOnce = true
        this.$router.push({
          name: 'organisation',
          params: { id: this.user.organisationId },
        })
      }
    },
    setDocumentTitle(locale: string) {
      const appName = this.$appConfig.name
      const slogan = this.$t('common.slogan', locale)

      document.title = `${appName} - ${slogan}`
    },
  },
}
