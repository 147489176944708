import { IPriceIndices } from '@/interfaces'
import { IPriceIndexState } from './state'

export const mutations = {
  SET_PRICE_INDICES: (state: IPriceIndexState, priceIndices: IPriceIndices) => {
    state.priceIndices = priceIndices
  },
  SET_IS_LOADING: (state: IPriceIndexState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
}
