import { IOfferState } from './interfaces'

export const getters = {
  getOfferTemplates(state: IOfferState) {
    return state.offerTemplates
  },
  getIsLoading(state: IOfferState) {
    return state.isLoading
  },
  getError(state: IOfferState) {
    return state.error
  },
  getOffer(state: IOfferState) {
    return state.offer
  },
}
