import { IOfferState } from './interfaces'

export const getDefaultState = (): IOfferState => {
  return {
    offerTemplates: [],
    offer: null,
    isLoading: false,
    error: null,
  }
}
