import {
  IOrganisation,
  IOrganisationFilters,
  IPaymentCondition,
  IUser,
  IPartnersOrganisations,
} from '@/interfaces'
import { OrganisationStatus } from '@/enums'

export interface IOrganisationState {
  next: string | null
  usersNext: string | null
  filters: IOrganisationFilters
  organisations: IOrganisation[] | null
  users: IUser[] | null
  paymentConditions: IPaymentCondition[] | null
  partnersOrganisations: IPartnersOrganisations[] | null
  paymentConditionsNext: string | null
  isLoading: boolean
  isPaymentConditionsLoading: boolean
  isUsersLoading: boolean
  isLoadingNext: boolean
  isUsersLoadingNext: boolean
  isPaymentConditionsLoadingNext: boolean
  error: Error | null
}

export const getDefaultFilters = (): IOrganisationFilters => {
  return {
    status: OrganisationStatus.Validated,
  }
}

export const getDefaultState = (): IOrganisationState => {
  return {
    next: null,
    filters: getDefaultFilters(),
    organisations: null,
    users: null,
    paymentConditions: null,
    paymentConditionsNext: null,
    partnersOrganisations: null,
    isLoading: false,
    isUsersLoading: false,
    isPaymentConditionsLoading: false,
    isLoadingNext: false,
    isUsersLoadingNext: false,
    isPaymentConditionsLoadingNext: false,
    usersNext: null,
    error: null,
  }
}
