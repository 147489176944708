import { INotification, IUser } from '@/interfaces'
import {
  LocaleCode,
  NotificationAction,
  NotificationResource,
  S3DocumentType,
} from '@/enums'
import { getUserFullName } from '@/utils/UserUtil/UserUtil'
import { downloadFile } from '@/utils/DocumentUtil/DocumentUtil'
import { i18n } from '@/i18n'
import router from '@/router'
import { Store } from 'vuex'
import { IOffer } from '@/modules/contract/state'
import { Notification } from 'element-ui'

export interface INotificationDescription {
  title: string
  message: string
  user: IUser
}

export const normalizeNotification = (notification: INotification) => {
  if (
    notification.offer &&
    notification.organisation &&
    notification.resource !== NotificationResource.Contracts
  ) {
    notification.offer.organisation = notification.organisation
  }
}

export const redirectToNotificationRoute = async (
  notification: INotification
) => {
  notification.isUnread = false

  switch (notification.resource) {
    case NotificationResource.Offers:
      if (notification.action === NotificationAction.Accepted) {
        if (notification.organisation && notification.offer) {
          try {
            const transactionNumber = `${notification.organisation.customerNumber}-${notification.offer.offerNumber}`
            const userLocale = notification.user?.locale ?? LocaleCode.DE
            const pdfFileName = `${transactionNumber}.${userLocale.toLowerCase()}.pdf`
            const options = await downloadFile(
              transactionNumber,
              S3DocumentType.Transaction,
              pdfFileName
            )

            window.open(options.url, '_blank')
          } catch (e) {
            Notification.error({
              title: 'Error',
              message: e.message,
            })
          }
        }
      }
      break
    case NotificationResource.Contracts:
      router
        .push({
          name: 'contracts',
          params: { id: notification.offerId },
          query: { contractId: notification.contractId || '' },
        })
        .catch(() => {})
      break
    case NotificationResource.Deliveries:
      router
        .push({
          name: 'deliveries',
          params: { id: notification.offerId },
        })
        .catch(() => {})
      break
    case NotificationResource.Bids:
      if (notification.offer) {
        router
          .push({ name: 'contracts', params: { id: notification.offer.id } })
          .catch(() => {})
      }

      break
    case NotificationResource.Organisations:
      if (notification.organisationId) {
        router.push({ name: 'marketplace' }).catch(() => {})
      }

      break
  }
}

export const getNotificationDescription = (
  notification: INotification,
  user: IUser | null
): INotificationDescription | null => {
  if (!user || !notification) {
    return null
  }

  let name

  if (notification.resource === NotificationResource.Transactions) {
    name = notification?.offer?.unitPriceIndex?.group
  } else if (
    ![NotificationResource.Contracts, NotificationResource.Deliveries].includes(
      notification.resource
    )
  ) {
    if (!notification.organisation) {
      return null
    }

    name =
      user.organisation.id === notification?.organisationId
        ? getUserFullName(notification.user)
        : notification.organisation.name
  } else {
    name = getUserFullName(notification.user)
  }

  return {
    user,
    title: i18n
      .t(`notification.${notification.resource}.${notification.action}.title`)
      .toString(),
    message: i18n
      .t(
        `notification.${notification.resource}.${notification.action}.message`,
        { name }
      )
      .toString(),
  }
}

export const doActions = async (
  notification: INotification,
  store: Store<any>
) => {
  switch (notification.resource) {
    case NotificationResource.Deliveries: {
      const {
        name,
        params: { id },
      } = router.currentRoute

      if (
        notification.action === NotificationAction.Updated &&
        name === 'offer' &&
        id === notification.offerId
      ) {
        await store.dispatch('delivery/loadDeliveries', notification.offerId)
      }
      break
    }
    case NotificationResource.Organisations: {
      if (notification.organisation) {
        await store.commit(
          'auth/SET_USER_ORGANISATION',
          notification.organisation
        )
      }

      break
    }
    case NotificationResource.Contracts: {
      const {
        name,
        params: { id },
      } = router.currentRoute

      if (
        ['offer', 'contracts'].includes(name || '') &&
        notification.offerId === id
      ) {
        await store.dispatch('contract/fetchContracts', {
          id: notification.offerId,
        } as IOffer)
      }

      if (['trading'].includes(name || '')) {
        await store.dispatch('subscription/fetchSubscriptions', {
          hideLoader: true,
        })
      }

      break
    }
    case NotificationResource.Bids: {
      const {
        name = '',
        params: { id },
      } = router.currentRoute

      if (
        ['contracts', 'offer'].includes(name || '') &&
        notification.offerId === id
      ) {
        await store.dispatch('offer/fetchOffer', id)
      }

      break
    }
    case NotificationResource.Offers: {
      const {
        name = '',
        params: { id },
      } = router.currentRoute

      if (
        ['contracts', 'offer'].includes(name || '') &&
        notification.offerId === id
      ) {
        await store.dispatch('offer/fetchOffer', id)
      }

      if (['trading'].includes(name || '')) {
        await store.dispatch('subscription/fetchSubscriptions', {
          hideLoader: true,
        })
      }

      break
    }
  }
}
