import { IOrganisationState } from './state'

export const getters = {
  getOrganisations(state: IOrganisationState) {
    return state.organisations
  },
  getIsLoading(state: IOrganisationState) {
    return state.isLoading
  },
  getIsLoadingNext(state: IOrganisationState) {
    return state.isLoadingNext
  },
  getTableDataNext(state: IOrganisationState): string | null {
    return state.next
  },
  getFiltersFromQuery(state: IOrganisationState) {
    return state.filters
  },
  organisationUsers(state: IOrganisationState) {
    return state.users
  },
  usersTableDataNext(state: IOrganisationState) {
    return state.usersNext
  },
  isUsersLoadingNext(state: IOrganisationState) {
    return state.isUsersLoadingNext
  },
  isUsersLoading(state: IOrganisationState) {
    return state.isUsersLoading
  },
  paymentConditions(state: IOrganisationState) {
    return state.paymentConditions
  },
  paymentConditionsTableDataNext(state: IOrganisationState) {
    return state.paymentConditionsNext
  },
  isPaymentConditionsLoadingNext(state: IOrganisationState) {
    return state.isPaymentConditionsLoadingNext
  },
  isPaymentConditionsLoading(state: IOrganisationState) {
    return state.isPaymentConditionsLoading
  },
  partnersOrganisations(state: IOrganisationState) {
    return state.partnersOrganisations
  },
}
