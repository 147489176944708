import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { normalizeNotification } from '@/utils/NotificationUtil/NotificationUtil'

const fetchPersonalNotifications = async () => {
  const response = await HttpService.request({
    path: '/trading-updates',
  })

  return response.forEach(normalizeNotification)
}

const deletePersonalNotification = async (notificationId: string) => {
  return await HttpService.request({
    method: HttpMethod.Delete,
    path: `/trading-updates/${notificationId}`,
  })
}

const deleteAllPersonalNotifications = async () => {
  return await HttpService.request({
    method: HttpMethod.Delete,
    path: `/trading-updates`,
  })
}

export default {
  fetchPersonalNotifications,
  deletePersonalNotification,
  deleteAllPersonalNotifications,
}
