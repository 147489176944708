import { IUserState } from './interfaces'
import { ITableData } from '@/utils/TableDataUtil/TableDataUtil'

export const mutations = {
  SET_ERROR: (state: IUserState, error: Error) => {
    state.error = error
  },
  SET_IS_LOADING: (state: IUserState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_TABLE_DATA: (
    state: IUserState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    state.users = options.previousNext
      ? (state.users || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_IS_LOADING_NEXT: (state: IUserState, isUsersLoading: boolean) => {
    state.isLoadingNext = isUsersLoading
  },
}
