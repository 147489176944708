import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { IRootState } from '@/interfaces'
import { IStationState, getDefaultState } from './state'

export const station: Module<IStationState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getDefaultState(),
}
