import { ICommon, IBid } from '@/interfaces'
import { AttemptType, Gender } from '@/enums'

export interface IUser {
  hasAvatar?: boolean | undefined
  firstName: string
  lastName: string
  id: string
  gender?: Gender | undefined
  organisationId: string
}

export interface IOffer {
  id: string
  organisationId: string
}

export interface IAgreementAttempt {
  id: string
  createdAt: number
  type: AttemptType
  body: string | IBid
  sender: IUser
}

export interface IContract extends ICommon {
  offerId: string
  organisationId: string
  offer: {
    id: string
    organisationId: string
  }
  organisation: {
    id: string
    name: string
  }
  hasPendingAttempt?: boolean
  hasAcceptedAttempt?: boolean
}

export interface IContractAgreementAttempts {
  [contractId: string]: IAgreementAttempt[]
}

export interface IAddAgreementAttemptPayload {
  contractId: string
  attempt: IAgreementAttempt
}

export interface ISetAgreementAttemptsPayload {
  contractId: string
  attempts: IAgreementAttempt[]
}

export interface IContractState {
  isAttemptsLoading: boolean
  isAttemptLoading: boolean
  isContractsLoading: boolean
  contracts: IContract[]
  contractsAdmin: IContract[]
  agreementAttempts: IContractAgreementAttempts
  agreementAttemptsAdmin: IContractAgreementAttempts
  selectedContractId: string | null
  selectedContractIdAdmin: string | null
  error: Error | null
}

export const getDefaultState = (): IContractState => {
  return {
    isAttemptsLoading: false,
    isAttemptLoading: false,
    isContractsLoading: false,
    contracts: [],
    contractsAdmin: [],
    agreementAttempts: {},
    agreementAttemptsAdmin: {},
    selectedContractId: null,
    selectedContractIdAdmin: null,
    error: null,
  }
}
