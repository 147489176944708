var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"nav-bar",attrs:{"color":"white","flat":""}},[_c('div',{staticClass:"nav-container page-container"},[_c('div',{staticClass:"header-holder"},[_c('div',{staticClass:"logo-container"},[_c('router-link',{attrs:{"to":_vm.isSignedIn ? { name: 'marketplace' } : '/'}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png"),"alt":"logo"}})]),(_vm.env)?_c('small',{staticClass:"env-badge"},[_vm._v("("+_vm._s(_vm.env)+")")]):_vm._e()],1),(_vm.isSignedIn)?_c('v-tabs',{staticClass:"navigation"},[_vm._l((_vm.menuItems),function(item){return _c('v-tab',{key:item.path,attrs:{"to":item.path,"disabled":_vm.isOrganisationDisabled}},[_vm._v(" "+_vm._s(_vm.$t(("navBar." + (item.labelKey))))+" ")])}),(_vm.isDeliveriesMatchingAvailable)?_c('v-tab',{attrs:{"to":"/matching","disabled":_vm.isOrganisationDisabled}},[_vm._v(" "+_vm._s(_vm.$t('navBar.deliveryMatching'))+" ")]):_vm._e()],2):_vm._e(),(_vm.isSignedIn && !_vm.isOrganisationDisabled)?_c('v-menu',{staticClass:"mobile-navigation",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-down")])]}}],null,false,291313702)},[_c('v-list',_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.path,attrs:{"to":item.path}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(("navBar." + (item.labelKey))))+" ")])],1)}),1)],1):_vm._e()],1),_c('div',{ref:"secondaryContainer",staticClass:"nav-container secondary-container"},[_c('v-chip',{attrs:{"label":"","small":"","color":"warning"}},[_vm._v(_vm._s(_vm.$t('common.freeAmount', { freeAmount: _vm.freeAmount })))]),(!_vm.isOrganisationDisabled)?_c('notification-component',{attrs:{"user":_vm.user}}):_vm._e(),_c('v-menu',{attrs:{"content-class":"floating-menu","open-on-hover":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"user",attrs:{"data-wdio-menu":""}},'div',attrs,false),on),[_c('ul',{staticClass:"hidden-sm-and-down user-name"},[_c('li',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_c('li',[_c('organisation-avatar',{staticClass:"organisation-avatar",attrs:{"organisation":_vm.user.organisation,"size":_vm.enums.AvatarSize.Tiny}}),_vm._v(" "+_vm._s(_vm.user.organisation.name)+" ")],1)]),_c('user-avatar',{staticClass:"avatar",attrs:{"user":_vm.user,"size":_vm.enums.AvatarSize.Small,"has-organisation-fallback":false}}),_c('v-icon',{staticClass:"down-arrow",attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{staticClass:"menu-content",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"index":"user"},on:{"click":function($event){return _vm.$store.commit('auth/SET_USER_PROFILE_MODAL_VISIBILITY', true)}}},[_vm._v(" "+_vm._s(_vm.$t('navBar.user'))+" ")]),_c('v-list-item',{attrs:{"index":"dashboard","disabled":_vm.isOrganisationDisabled},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard' })}}},[_vm._v(" "+_vm._s(_vm.$t('navBar.dashboard'))+" ")]),_c('v-list-item',{attrs:{"index":"organisation","disabled":_vm.isOrganisationDisabled},on:{"click":function($event){return _vm.$router.push({
                name: 'organisation',
                params: { id: _vm.user.organisationId },
              })}}},[_vm._v(" "+_vm._s(_vm.$t('navBar.myOrganisation'))+" ")]),_c('v-list-item',{attrs:{"index":"transactions","disabled":_vm.isOrganisationDisabled},on:{"click":function($event){return _vm.$router.push({ name: 'transactions' })}}},[_vm._v(" "+_vm._s(_vm.$t('navBar.transactions'))+" ")]),(_vm.isGlobalAdmin)?_c('v-divider'):_vm._e(),(_vm.isGlobalAdmin)?_c('v-list-item',{attrs:{"index":"organisations"},on:{"click":function($event){return _vm.$router.push({ name: 'organisations' })}}},[_vm._v(" "+_vm._s(_vm.$t('navBar.organisations'))+" ")]):_vm._e(),(_vm.isGlobalAdmin)?_c('v-list-item',{attrs:{"index":"users"},on:{"click":function($event){return _vm.$router.push({ name: 'users' })}}},[_vm._v(" "+_vm._s(_vm.$t('navBar.users'))+" ")]):_vm._e(),_c('v-divider'),_c('v-list-item',{attrs:{"index":"signOut","data-wdio-sign-out":""},on:{"click":_vm.signOut}},[_vm._v(" "+_vm._s(_vm.$t('common.signOut'))+" ")])],1)],1)],1)]),_c('custom-dialog',{attrs:{"title":_vm.$t('user.form.title'),"is-visible":_vm.isUserProfileModalVisible},on:{"close":_vm.closeUserDialog}},[(_vm.isUserProfileModalVisible)?_c('user-form',{attrs:{"title":_vm.$t('user.form.title'),"user":_vm.user,"is-dialog":""},on:{"close-dialog":_vm.closeUserDialog}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }