import Vue from 'vue'
import store from '@/store'
import { RawLocation, Route } from 'vue-router'
import { Auth } from '@aws-amplify/auth'
import { emitter } from '@/emitter'
import { OrganisationStatus } from '@/enums'

export const toMarketplace = (
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
) => {
  const { query } = to

  if (store.getters['auth/getIsSignedIn']) {
    next({ name: 'marketplace', query })

    return
  }

  next({ query })
}

export const isProtected = async (
  to: Route,
  from: Route,
  next: (
    to?: { name: string } | RawLocation | false | ((vm: Vue) => any) | void
  ) => void
) => {
  const { query } = to
  if (
    !store.getters['auth/getIsSignedIn'] ||
    !(await Auth.currentSession()).isValid()
  ) {
    next({ name: 'signIn', params: { redirect: to } } as {
      name: string
      params: { redirect: RawLocation }
    })

    return
  }

  if (
    [
      OrganisationStatus.Disabled,
      OrganisationStatus.Draft,
      OrganisationStatus.Ready,
    ].includes(store.getters['auth/getOrganisationStatus'])
  ) {
    if (from.name === 'organisation') return
    if (to.name !== 'organisation') {
      next({
        name: 'organisation',
        params: {
          id: store.getters['auth/getOrganisationId'],
        },
      } as { name: string; params: { id: string } })
      return
    }
  }

  if (
    OrganisationStatus.Restricted ===
      store.getters['auth/getOrganisationStatus'] &&
    ['offer', 'contracts', 'deliveries', 'bids'].includes(to.name as string)
  ) {
    next({ name: 'marketplace' })

    emitter.emit('emmit:restricted')

    return
  }

  next({ query })
}

export const isGlobalAdmin = (
  to: Route,
  from: Route,
  next: (
    to?: { name: string } | RawLocation | false | ((vm: Vue) => any) | void
  ) => void
) => {
  if (!store.getters['auth/getIsGlobalAdmin']) {
    if (!from.name) {
      next({ name: 'marketplace' })

      return
    } else {
      next({ name: 'offer', params: from.params })

      return
    }
  }

  next()
}
