





















import { computed, defineComponent, PropType } from '@vue/composition-api'

type IInputType =
  | 'text'
  | 'password'
  | 'email'
  | 'number'
  | 'tel'
  | 'search'
  | 'url'

// When the validation function returns true, it is valid (no message)
// otherwise it is invalid and will show the string message
type IValidationFunction = (valueToAssess: string) => true | string

export default defineComponent({
  props: {
    name: {
      type: String,
      default: undefined,
    },
    // "value" is used for internal use of v-model
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    preventAutocomplete: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<IInputType>,
      default: 'text',
    },
    validationError: {
      type: Function as PropType<IValidationFunction>,
      default: () => true,
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isMultiline: {
      type: Boolean,
      default: false,
    },
    isGrowable: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 2,
    },
  },
  setup(props, { emit, attrs }) {
    const modelValue = computed({
      get: () => {
        return props.value
      },
      set: (newValue: string | number) => {
        emit('input', newValue)
        emit('change')
      },
    })

    const sharedProps = computed(() => ({
      class: `${attrs.class} text-field`,
      name: props.name,
      dense: true,
      outlined: true,
      hideDetails: 'auto',
      rules: [props.validationError],
      autocomplete: props.autocomplete,
      disabled: props.isDisabled,
      clearable: props.isClearable,
      placeholder: props.placeholder,
    }))

    const sharedEvents = {
      blur: (e: any) => emit('blur', e),
      focus: (e: any) => emit('focus', e),
      input: (e: any) => emit('input', e),
    }

    return { modelValue, sharedProps, sharedEvents }
  },
})
