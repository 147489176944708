import { INotification } from '@/interfaces'

export interface INotificationState {
  notifications: INotification[]
  allNotifications: INotification[] | null
  isLoading: boolean
  pageSize: number
  isLastPage: boolean
  isLoadingNext: boolean
  error: Error | null
  next: string | null
}

export const getDefaultState = (): INotificationState => {
  return {
    allNotifications: null,
    notifications: [],
    isLoading: false,
    isLoadingNext: false,
    next: null,
    pageSize: 0,
    isLastPage: false,
    error: null,
  }
}
