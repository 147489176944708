import Cookies from 'js-cookie'

export interface IAppState {
  hasCookieConsent: boolean
}

export const getAppState = (): IAppState => {
  return {
    hasCookieConsent: !!Cookies.get('consent') || false,
  }
}
