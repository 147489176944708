
























import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Dialog',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const internalModel = computed({
      get: () => props.isVisible,
      set: () => {
        emit('close')
      },
    })

    const handleClose = () => {
      emit('close')
    }

    return { internalModel, handleClose }
  },
})
