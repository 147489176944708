




import { AvatarSize } from '@/enums'
import { PropType } from 'vue'

export default {
  name: 'Thumbnail',
  props: {
    src: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    size: {
      type: Number as PropType<AvatarSize>,
      default: AvatarSize.Default,
    },
    isCircle: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  computed: {
    thumbnailStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        backgroundImage: this.src ? `url(${this.src})` : undefined,
        borderRadius: this.isCircle ? '50%' : '3px',
      }
    },
  },
}
