import { ITransactionFilters, ITransaction } from './resources'
import { IOrganisation } from '@/interfaces'

export interface ITransactionsByOffers {
  [key: string]: ITransaction[]
}

export interface ITransactionState {
  next: string | null
  filters: ITransactionFilters
  transactions: ITransaction[] | null
  isLoading: boolean
  isLoadingNext: boolean
  isLoadingExport: boolean
  error: Error | null
  organisation: IOrganisation | null
  preCalculation: ITransaction | null
  isLoadingTransactions: boolean
  offerTransactions: ITransaction[]
  transactionsByOffers: ITransactionsByOffers
}

export const getDefaultFilters = (): ITransactionFilters => {
  return {
    deliveryFrom: [],
    partnersOrganisationsId: [],
    materialTypeId: [],
    notRated: false,
  }
}

export const getDefaultState = (): ITransactionState => {
  return {
    next: null,
    filters: getDefaultFilters(),
    transactions: null,
    isLoading: false,
    isLoadingExport: false,
    isLoadingNext: false,
    error: null,
    organisation: null,
    preCalculation: null,
    isLoadingTransactions: false,
    offerTransactions: [],
    transactionsByOffers: {},
  }
}
