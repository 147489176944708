import { IMaterialType } from '@/interfaces'
import { IMaterialTypesState } from './state'

export const getters = {
  getMaterialTypes(state: IMaterialTypesState): IMaterialType[] {
    state.materialTypes.sort((a: IMaterialType, b: IMaterialType) => {
      if (a.code < b.code) {
        return -1
      }
      if (a.code > b.code) {
        return 1
      }

      return 0
    })

    return state.materialTypes
  },
  getIsLoading(state: IMaterialTypesState): boolean {
    return state.isLoading
  },
}
