






















import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
export default {
  name: 'CookieBar',
  components: { MarkdownComponent },
  methods: {
    onAcceptCookies(): void {
      this.$emit('accept')
    },
  },
}
