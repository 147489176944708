import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import moment from 'moment'
import { LocaleCode } from '@/enums'

Vue.use(VueI18n)

// export const getBrowserLocale = (options = {}): LocaleCode => {
//   const defaultOptions = { countryCodeOnly: true }

//   const opt = { ...defaultOptions, ...options }

//   const navigatorLocale =
//     // @ts-ignore
//     navigator.userLanguage ||
//     (navigator.languages !== undefined
//       ? navigator.languages[0]
//       : navigator.language)

//   if (!navigatorLocale) {
//     return LocaleCode.EN
//   }

//   const trimmedLocale = opt.countryCodeOnly
//     ? navigatorLocale.trim().split(/-|_/)[0]
//     : navigatorLocale.trim()

//   return trimmedLocale as LocaleCode
// }

export const locale: LocaleCode = LocaleCode.DE
// Hardcoded above. In the future implement for `locale`:
// getBrowserLocale() || process.env.VUE_APP_I18N_LOCALE || LocaleCode.EN

moment.locale(locale)

export const elementLocaleMessages = (locale: string) =>
  require(`element-ui/lib/locale/lang/${locale.toLowerCase()}`).default

export const loadLocaleMessages = (): LocaleMessages => {
  const i18nLocaleMessages = (locale: string) => {
    if (process.env.NODE_ENV === 'test') {
      const yaml = require('js-yaml')
      const fs = require('fs')

      return yaml.safeLoad(
        fs.readFileSync(`./src/locales/${locale.toLowerCase()}.yml`, 'utf8')
      )
    } else {
      return require(`./locales/${locale.toLowerCase()}.yml`)
    }
  }

  const accumulatedLocalMessages = Object.values(LocaleCode).reduce(
    (messages, lang) => ({
      ...messages,
      [lang]: {
        ...i18nLocaleMessages(lang),
        ...elementLocaleMessages(lang),
      },
    }),
    {}
  )

  return accumulatedLocalMessages as LocaleMessages
}

export const refreshLibsLocale = async (newLocale: string) => {
  // Those cannot be imported at the beginning
  const ElementUILocale = require('element-ui/lib/locale')
  const { refreshElementUI } = require('@/element-ui')

  refreshElementUI()
  ElementUILocale.use(elementLocaleMessages(newLocale))
  moment.locale(newLocale)
}

export const i18n = new VueI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || LocaleCode.EN,
  silentTranslationWarn: process.env.NODE_ENV === 'test',
  messages: loadLocaleMessages(),
})
