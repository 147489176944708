import { ActionContext } from 'vuex'
import { IRootState, IStation, IMeta } from '@/interfaces'
import { IFetchQuery } from './interfaces'
import { IStationState } from '@/modules/station/state'
import { getTableData } from '@/utils/TableDataUtil/TableDataUtil'
import HttpService from '@/services/HttpService/HttpService'

export const actions = {
  async fetchStations(
    { commit }: ActionContext<IStationState, IRootState>,
    option: {
      organisationId: string
      next?: string | null
    }
  ): Promise<void> {
    try {
      await commit('SET_IS_LOADING', true)

      if (option.next === undefined) {
        option.next = null
      } else {
        await commit(
          option.next ? 'SET_IS_LOADING_NEXT' : 'SET_IS_LOADING',
          true
        )
      }

      const fetchQuery = {} as IFetchQuery

      const fetchRows = async (query?: IFetchQuery) => {
        const response = await HttpService.request({
          query,
          path: `/organisations/${option.organisationId}/stations`,
        })

        return response as {
          station: IStation[]
          meta?: IMeta
        }
      }

      const tableData = await getTableData({
        fetchQuery,
        next: option.next,
        fetchRows,
        rowsKey: 'stations',
      })
      await commit('SET_TABLE_DATA', { tableData, previousNext: option.next })
    } catch (error) {
      await commit('SET_ERROR', error)
    }
    await commit('SET_IS_LOADING', false)
    await commit('SET_IS_LOADING_NEXT', false)
  },
  updateStation(
    { commit }: ActionContext<IStationState, IRootState>,
    station: IStation
  ) {
    commit('SET_STATION', station)
  },
  removeStation(
    { commit }: ActionContext<IStationState, IRootState>,
    station: IStation
  ) {
    commit('REMOVE_STATION', station)
  },
}
