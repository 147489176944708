import { IPriceIndices } from '@/interfaces'
import { IPriceIndexState } from './state'

export const getters = {
  priceIndices(state: IPriceIndexState): IPriceIndices {
    return state.priceIndices
  },
  isLoading(state: IPriceIndexState): boolean {
    return state.isLoading
  },
}
