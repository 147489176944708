import { IDashboardFilters, IDashboardState } from './interfaces'

export const getDefaultFilters = (): IDashboardFilters => {
  return {
    isShippingIncluded: null,
    contractType: null,
    transactionType: null,
    materialTypeCodes: null,
    organisationIds: null,
    dateRange: null,
  }
}

export const getDefaultState = (): IDashboardState => {
  return {
    filters: getDefaultFilters(),
    isLoading: true,
    error: null,
    organisation: null,
    amountPerMonthChartData: null,
    partnerFilterItems: null,
    isPartnerFilterItemsLoading: false,
    materialTypeCodesFilterItems: null,
    isMaterialTypeCodesFilterItemsLoading: false,
    amountPerOrganisationChartData: null,
    contractTypeChartData: null,
    pricePerMaterialTypeChartData: null,
    pricePerRegionChartData: null,
    pricePerPartnerChartData: null,
  }
}
