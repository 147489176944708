import Vue from 'vue'
import {
  formatPrice,
  formatDate,
  formatDateFromNow,
  formatDateTime,
  formatNumber,
  toUnixTime,
  formatDateFromNowUnix,
  currencyPerUnitType,
  formatDateMonth,
  formatDayOfWeek,
} from '@/utils/utils'

Vue.filter('formatDateFromNow', formatDateFromNow)
Vue.filter('formatDate', formatDate)
Vue.filter('formatDateMonth', formatDateMonth)
Vue.filter('formatDateTime', formatDateTime)
Vue.filter('formatPrice', formatPrice)
Vue.filter('formatNumber', formatNumber)
Vue.filter('toUnixTime', toUnixTime)
Vue.filter('formatDateFromNowUnix', formatDateFromNowUnix)
Vue.filter('currencyPerUnitType', currencyPerUnitType)
Vue.filter('formatDayOfWeek', formatDayOfWeek)
