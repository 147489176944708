import { Env } from '@/enums'

const packageConfig = require('../../package.json')

export interface IConfig {
  features: Record<string, any>
  aws: {
    baseUrl: string
    iotHost: string
    assetUrl: string
    userPoolId: string
    userPoolClientId: string
    identityPoolId: string
    region: string
    env: Env | string
  }
  sentry?: {
    publicKey: string
    projectId: string
  }
  googleAnalytics?: {
    id: string
  }
}

export interface IAppConfig {
  name: string
  unitAmountSteps: number
  guaranteedUnitAmount: number
  defaultLimit: number
  organisationValidationLimitDays: number
  googleMaps: {
    key: string
  }
  date: {
    firstDayOfWeek: number
    formatElementDate: string
    formatElementDateTime: string
    formatMomentDate: string
    formatMomentDateTime: string
    queryDateFormat: string
    formatDatePickerDefaultValue: string
    formatRecurringOfferDate: string
  }
  organisationsLimit: number
  deliveriesLimit: number
  paperMaterialId: string
}

export const appConfig: IAppConfig = {
  name: packageConfig.name,
  unitAmountSteps: 25,
  guaranteedUnitAmount: 25,
  defaultLimit: 50,
  organisationValidationLimitDays: 7,
  googleMaps: {
    key: 'AIzaSyCrsflUpx-iyg9aAmhVZOSc-d4JjGSzpM8',
  },
  date: {
    firstDayOfWeek: 1,
    formatElementDate: 'dd.MM.yyyy',
    formatElementDateTime: 'dd.MM.yyyy HH:mm',
    formatMomentDate: 'DD.MM.YYYY',
    formatMomentDateTime: 'DD.MM.YYYY HH:mm',
    formatRecurringOfferDate: 'MMMM YYYY',
    formatDatePickerDefaultValue: 'YYYY-MM-DD',
    queryDateFormat: 'YYYY-MM-DD',
  },
  organisationsLimit: 150,
  deliveriesLimit: 100,
  paperMaterialId: '598e0b45-b962-40fe-8798-dffe06e532e5',
}

// @ts-expect-error
export const env = Object.values(Env).includes(process.env.NODE_ENV)
  ? (process.env.NODE_ENV as Env)
  : Env.Development

export const config: IConfig = require(`./${env}.json`)

const {
  identityPoolId,
  userPoolId,
  userPoolClientId,
  region,
  baseUrl,
} = config.aws
export const awsConfig = {
  Auth: {
    identityPoolId,
    region,
    userPoolId,
    userPoolClientId,
    userPoolWebClientId: userPoolClientId,
  },
  API: {
    endpoints: [
      {
        name: 'RecyfyAPI',
        endpoint: baseUrl,
        region,
      },
    ],
  },
}
