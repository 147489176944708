import SocketService, {
  IMessageEvent,
} from '@/services/SocketService/SocketService'
import { Store } from 'vuex'
import { INotification } from '@/interfaces'
import { VueRouter } from 'vue-router/types/router'
import { ElNotification } from 'element-ui/types/notification'
import Vue from 'vue'
import {
  doActions,
  getNotificationDescription,
  redirectToNotificationRoute,
} from '@/utils/NotificationUtil/NotificationUtil'
import { NotificationPermissions } from '@/enums'
import { getUserAvatarUrl } from '@/utils/AssetUtil/AssetUtil'

interface IEventDescriptors {
  [name: string]: IMessageEvent
}

export class NotificationService {
  private readonly store: Store<any>
  private readonly router: VueRouter
  private readonly notify: ElNotification
  private static instance: NotificationService | null = null
  private readonly eventDescriptors: IEventDescriptors = {}

  private constructor(
    store: Store<any>,
    router: VueRouter,
    notify: ElNotification
  ) {
    this.store = store
    this.router = router
    this.notify = notify

    this.eventDescriptors.marketplace = {
      topic: 'marketplace',
      listener: async () => {
        await this.marketplaceListener()
      },
    }
    this.eventDescriptors.personal = {
      topic: `customers/${this.store.getters['auth/getUser']?.id}`,
      listener: async (notification: INotification) => {
        await this.personalListener(notification)
      },
    }
    this.eventDescriptors.organisation = {
      topic: `organisations/${this.store.getters['auth/getUser']?.organisationId}`,
      listener: async (notification: INotification) => {
        await this.organisationListener(notification)
      },
    }
  }

  subscribe(topic: string) {
    SocketService.subscribeMessageEvent(
      this.eventDescriptors[topic]
    ).catch((err) => console.log(err))
  }

  unsubscribe(topic: string) {
    SocketService.unsubscribeMessageEvent(
      this.eventDescriptors[topic]
    ).catch((err) => console.log(err))
  }

  async organisationListener(notification: INotification) {
    await doActions(notification, this.store)
  }

  async personalListener(notification: INotification) {
    await this.store.dispatch('notification/addNotification', notification)
    await this.showNotification(notification)
  }

  async marketplaceListener() {
    if (this.router.currentRoute.name === 'marketplace') {
      await this.store.dispatch('marketplace/fetchOffers')
    }
  }

  async showNotification(notification: INotification) {
    const user = this.store.getters['auth/getUser']
    const description = getNotificationDescription(notification, user)

    if (description) {
      let notify: Notification | any

      if (!isNativeNotificationSupported()) {
        notify = this.notify({
          title: description.title,
          message: description.message,
          type: 'success',
          onClick: async () => {
            if (notify) {
              notify.close()
            }

            await redirectToNotificationRoute(notification)
          },
        })
      } else {
        if (Notification.permission === NotificationPermissions.Granted) {
          notify = new Notification(description.title, {
            body: description.message,
            icon: getUserAvatarUrl({ user: notification.user }),
          })
        } else if (Notification.permission !== NotificationPermissions.Denied) {
          await Notification.requestPermission((permission: string) => {
            if (permission === NotificationPermissions.Granted) {
              notify = new Notification(description.title, {
                body: description.message,
                icon: getUserAvatarUrl({ user: notification.user }),
              })
            }
          })
        }

        if (notify) {
          notify.onclick = async (event: Event) => {
            event.preventDefault()
            await redirectToNotificationRoute(notification)
          }
        }
      }
    }
  }

  static install(VueConstructor: typeof Vue) {
    VueConstructor.prototype.$recyfyNotificationService = (vue: any) => {
      if (!NotificationService.instance) {
        NotificationService.instance = new NotificationService(
          vue.$store,
          vue.$router,
          vue.$notify
        )
      }

      return NotificationService.instance
    }
  }
}

function isNativeNotificationSupported() {
  if (!window?.Notification) {
    return false
  }

  try {
    // eslint-disable-next-line no-new
    new Notification('')
  } catch (e) {
    if (e.name === 'TypeError') {
      return false
    }
  }
  return true
}
