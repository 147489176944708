import { INotificationState } from './state'
import { INotification } from '@/interfaces'
import { ITableData } from '@/utils/TableDataUtil/TableDataUtil'

export const mutations = {
  SET_IS_LOADING: (state: INotificationState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_ERROR: (state: INotificationState, error: Error) => {
    state.error = error
  },
  SET_NOTIFICATIONS: (
    state: INotificationState,
    notifications: INotification[]
  ) => {
    state.notifications = notifications
  },
  SET_PAGE_SIZE: (state: INotificationState, pageSize: number) => {
    state.pageSize = pageSize
  },
  SET_IS_LAST_PAGE: (state: INotificationState, isLastPage: boolean) => {
    state.isLastPage = isLastPage
  },
  ADD_NOTIFICATION: (
    state: INotificationState,
    notification: INotification
  ) => {
    if (state.notifications) {
      state.notifications.unshift(notification)
    } else {
      state.notifications = [notification]
    }
  },
  DELETE_NOTIFICATION: (state: INotificationState, notificationId: string) => {
    state.notifications =
      state.notifications?.filter(
        ({ id }: INotification) => id !== notificationId
      ) || []
  },
  SET_TABLE_DATA: (
    state: INotificationState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    state.allNotifications = options.previousNext
      ? (state.allNotifications || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_IS_LOADING_NEXT: (
    state: INotificationState,
    isNotificationsLoading: boolean
  ) => {
    state.isLoadingNext = isNotificationsLoading
  },
}
