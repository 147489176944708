import { FilterType } from '@/enums'

export const normalizeFilterRules: { [name: string]: any } = {
  offerType: { type: FilterType.List },
  materialId: { type: FilterType.Input },
  materialTypeId: { type: FilterType.List },
  materialFormatGroupId: { type: FilterType.List },
  countryCode: { type: FilterType.List },
  postCode: { type: FilterType.Input },
  unitAmount: { type: FilterType.Range, min: 0, max: Number.MAX_SAFE_INTEGER },
  expiresAt: { type: FilterType.Input },
  deliveryDate: { type: FilterType.Range },
  offerStatus: { type: FilterType.Input },
  organisationId: { type: FilterType.List },
  unitPrice: { type: FilterType.Range, min: 0, max: Number.MAX_SAFE_INTEGER },
  isShippingIncluded: { type: FilterType.Flag },
  radius: { type: FilterType.List },
}
