




























































































































































import { mapActions, mapGetters } from 'vuex'
import UserForm from '@/components/user/UserForm.vue'
import { Dialog } from '@/components/molecules/Dialog'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import OrganisationAvatar from '@/components/common/OrganisationAvatar/OrganisationAvatar.vue'
import NotificationComponent from '@/components/common/NotificationComponent.vue'

export default {
  name: 'NavBar',
  components: {
    UserForm,
    CustomDialog: Dialog,
    OrganisationAvatar,
    NotificationComponent,
    UserAvatar,
  },
  inject: ['enums'],
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      isGlobalAdmin: 'getIsGlobalAdmin',
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isSignedIn: 'getIsSignedIn',
      isUserProfileModalVisible: 'getIsUserProfileModalVisible',
      isOrganisationDisabled: 'getIsOrganisationDisabled',
      isDeliveriesAvailable: 'isDeliveriesAvailable',
      isDeliveriesMatchingAvailable: 'isDeliveriesMatchingAvailable',
    }),
    env() {
      return this.$env !== this.enums.Env.Production ? this.$env : null
    },
    freeAmount() {
      return this.user?.organisation?.invoicingFreeAmount || 0
    },
    menuItems() {
      const items = [
        { labelKey: 'marketplace', path: '/marketplace' },
        { labelKey: 'subscription', path: '/trading' },
        { labelKey: 'tradingPartners', path: '/partners' },
      ]
      if (this.isDeliveriesAvailable) {
        items.push({ labelKey: 'deliveryManager', path: '/deliveries' })
      }
      return items
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    closeUserDialog() {
      this.$store.commit('auth/SET_USER_PROFILE_MODAL_VISIBILITY', false)
    },
  },
}
