import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { IRootState } from '@/interfaces'
import { ITransactionState, getDefaultState } from './state'

export const transaction: Module<ITransactionState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getDefaultState(),
}
