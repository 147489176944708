import { IMaterialType } from '@/interfaces'
import { IMaterialTypesState } from './state'

export const mutations = {
  SET_MATERIAL_TYPES: (
    state: IMaterialTypesState,
    materialTypes: IMaterialType[]
  ) => {
    state.materialTypes = materialTypes
  },
  SET_IS_LOADING: (
    state: IMaterialTypesState,
    isMaterialFormatGroupsLoading: boolean
  ) => {
    state.isLoading = isMaterialFormatGroupsLoading
  },
  SET_ERROR: (state: IMaterialTypesState, error: Error) => {
    state.error = error
  },
}
