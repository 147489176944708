import { IUserState } from './interfaces'

export const getters = {
  getUsers(state: IUserState) {
    return state.users
  },
  getIsLoading(state: IUserState) {
    return state.isLoading
  },
  getIsLoadingNext(state: IUserState) {
    return state.isLoadingNext
  },
  getTableDataNext(state: IUserState): string | null {
    return state.next
  },
}
