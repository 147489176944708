import { IDeliveryState } from './state'
import { IDeliveriesByDate } from '@/interfaces'
import cloneDeep from 'lodash/cloneDeep'

export const getters = {
  deliveries(state: IDeliveryState): IDeliveriesByDate {
    return cloneDeep(state.deliveriesAggregated)
  },
  isLoading(state: IDeliveryState): boolean {
    return state.isLoading
  },
}
