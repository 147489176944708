import { ISubscriptionState, getDefaultFilters } from './state'
import { IQuery } from './actions'

export const getters = {
  getSubscriptions(state: ISubscriptionState) {
    return state.subscriptions
  },
  getIsLoading(state: ISubscriptionState) {
    return state.isLoading
  },
  getError(state: ISubscriptionState) {
    return state.error
  },
  getIsLoadingNext(state: ISubscriptionState) {
    return state.isLoadingNext
  },
  getFiltersFromQuery(state: ISubscriptionState) {
    return state.filters
  },
  getQueryFromFilters(state: ISubscriptionState) {
    const query: IQuery = {}
    const defaultFilters = getDefaultFilters()

    if (state.filters.offerStatus !== defaultFilters.offerStatus) {
      query.offerStatus = state.filters.offerStatus
    }

    if (state.filters.materialTypes.length) {
      query.materialTypes = state.filters.materialTypes.join('_')
    }

    if (state.filters.offerTypes.length) {
      query.offerTypes = state.filters.offerTypes.join('_')
    }

    if (state.filters.createdBy) {
      query.createdBy = state.filters.createdBy
    }

    return query
  },
  getTableDataNext(state: ISubscriptionState): string | null {
    return state.next
  },
}
