






























































































































































































import tracking from '@/tracking'
import { mapActions, mapGetters } from 'vuex'
import { getDefaultFormItems } from '@/modules/user/resources'
import ImageUploadComponent from '@/components/common/ImageUploadComponent.vue'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import AvatarUploadMaskComponent from '@/components/common/AvatarUploadMaskComponent.vue'
import { Dialog } from '@/components/molecules/Dialog'
import { Checkbox } from '@/components/molecules/Checkbox'
import UserPasswordForm from '@/components/user/UserPasswordForm.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { LocaleSelect } from '@/components/common/LocaleSelect'
import { Dropdown } from '@/components/molecules/Dropdown'
import { AcademicRank, Gender, NotificationPermissions } from '@/enums'
import { IUser } from '@/interfaces'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'UserForm',
  components: {
    ImageUploadComponent,
    AvatarUploadMaskComponent,
    UserAvatar,
    CustomDialog: Dialog,
    UserPasswordForm,
    MarkdownComponent,
    LocaleSelect,
    TextField,
    Dropdown,
    Checkbox,
  },
  inject: ['enums'],
  props: {
    title: {
      type: String,
      default: '',
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formItems: getDefaultFormItems(),
      isUserPasswordDialogVisible: false,
      notificationPermission: window?.Notification?.permission,
      rules: {
        gender: [
          {
            required: true,
            message: this.$t('offerForm.form.gender.required'),
            trigger: 'blur',
          },
          {
            type: 'enum',
            enum: Object.values(Gender),
            message: this.$t('offerForm.form.gender.enum'),
            trigger: 'blur',
          },
        ],
        contactEmail: {
          type: 'email',
          required: false,
          message: this.$t('common.form.email.required'),
          trigger: 'blur',
        },
        firstName: {
          required: true,
          message: this.$t('common.form.firstName.required'),
          trigger: 'blur',
        },
        lastName: {
          required: true,
          message: this.$t('common.form.lastName.required'),
          trigger: 'blur',
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      isUserLoading: 'getIsUserLoading',
    }),
    desktopNotificationPermissionDisabled() {
      return this.notificationPermission !== NotificationPermissions.Default
    },
    desktopNotificationPermissionChecked() {
      return this.notificationPermission === NotificationPermissions.Granted
    },
    isUserDataFulfilled() {
      const { gender, firstName, lastName } = (this.user as IUser) || {}

      return !gender || !firstName || !lastName
    },
    genderSelectionItems() {
      const genders = Object.values(Gender)
      const commonItems = genders.map((gender) => ({
        text: this.$t(`common.gender.${gender}`),
        value: gender,
      }))
      return [
        { text: this.$t('common.selectNoGenderOption'), value: null },
        ...commonItems,
      ]
    },
    academicRankItems() {
      const ranks = Object.values(AcademicRank)
      const commonItems = ranks.map((rank) => ({
        text: this.$t(`common.academicRank.${rank}`),
        value: rank,
      }))
      return [
        { text: this.$t('common.selectNoAcademicRankOption'), value: null },
        ...commonItems,
      ]
    },
  },
  created() {
    tracking.trackPageView('/profile')

    Object.assign(this.formItems, this.user)
  },
  destroyed() {
    tracking.trackPageView()
  },
  methods: {
    ...mapActions('auth', ['saveUser', 'refreshUserAvatarUrl']),
    async onSubmit() {
      await this.$refs.form.validate(async (valid: boolean) => {
        if (valid) {
          await this.saveUser(this.formItems)
          await this.$message.success(
            this.$t('common.saveSuccessMessage', { title: this.title })
          )
          if (this.closeDialog) {
            this.closeDialog()
          }
        }
      })
    },
    isNotificationSupported(): boolean {
      return !!window?.Notification
    },
    grantDesktopNotificationPermission() {
      if (!window?.Notification) {
        return
      }

      if (Notification?.permission === NotificationPermissions.Granted) {
        return
      }

      Notification.requestPermission((permission: string) => {
        if (permission === NotificationPermissions.Granted) {
          // eslint-disable-next-line
          new Notification(this.$t('notification.activated'))
        }
        this.notificationPermission = permission
      })
    },
    onReset() {
      this.$refs.form.resetFields()
    },
    onImageUploadSuccess() {
      this.refreshUserAvatarUrl({
        userId: this.user.id,
      })
    },
    closeUserPasswordDialog() {
      this.isUserPasswordDialogVisible = false
    },
  },
}
