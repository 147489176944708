

























import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { Dialog } from '@/components/molecules/Dialog'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { IUser } from '@/interfaces'
import { Emitter } from '@/enums'

export default defineComponent({
  name: 'RestrictedOrganisationModal',
  components: {
    CustomDialog: Dialog,
    MarkdownComponent,
  },
  props: {
    user: {
      type: Object as PropType<IUser>,
      default: () => null,
    },
    isForcedVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance()?.proxy
    const isDialogVisible = ref(props.isForcedVisible)

    const toggleDialogVisibility = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    onMounted(() => {
      vm?.$emitter?.on(Emitter.Restricted, toggleDialogVisibility)
    })

    onUnmounted(() => {
      vm?.$emitter?.off(Emitter.Restricted, toggleDialogVisibility)
    })

    return { isDialogVisible, toggleDialogVisibility }
  },
})
