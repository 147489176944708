
















import {
  getImageUploadOptions,
  uploadImage,
} from '@/utils/ImageUploadUtil/ImageUploadUtil'
import { ImageType, FileAction } from '@/enums'
import { IFileListItem } from '@/interfaces'
import { PropType } from 'vue'

export default {
  name: 'ImageUploadComponent',
  props: {
    type: {
      type: String as PropType<ImageType>,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    onSuccess: {
      type: Function as PropType<() => Promise<void>>,
      default: null,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: '',
    },
    isAutoUpload: {
      type: Boolean,
      default: true,
    },
    isFileListShown: {
      type: Boolean,
      default: false,
    },
    fileList: {
      type: Array as PropType<Array<IFileListItem>>,
      default: () => [],
    },
    onRemove: {
      type: Function as PropType<() => boolean>,
      default: null,
    },
  },
  data() {
    return {
      imageUploadOptions: null,
      typeId: '',
    }
  },
  methods: {
    getFileList() {
      return this.$refs.upload.uploadFiles
    },
    submitUpload(id?: string) {
      this.typeId = id || this.id

      this.$emit('unload', this.typeId)
    },
    onImageUploadSuccess(res: any, file: any) {
      this.$emit('onImageUploadSuccess', file)
    },
    async updateImageUploadOptions(file: File) {
      try {
        this.imageUploadOptions = await getImageUploadOptions(
          this.typeId || this.id,
          this.type,
          file,
          FileAction.Upload
        )
      } catch (error: any) {
        this.$message.error(error.message)
      }
    },
    async uploadImage() {
      if (this.imageUploadOptions) {
        await uploadImage(this.imageUploadOptions)

        if (this.onSuccess) {
          this.onSuccess()
        }
      }
    },
    async onImageRemove(file: File, fileList: FileList) {
      await this.onRemove(file, fileList)
    },
  },
}
