import { ActionContext } from 'vuex'
import { IRelationPartner, IRelationRating, IRootState } from '@/interfaces'
import { IRatingState, ISaveRatingPayload } from './state'
import HttpService, {
  HttpMethod,
  StatusCode,
} from '@/services/HttpService/HttpService'
import { TradingStatus } from '@/enums'

export const actions = {
  async saveRatings(
    { commit }: ActionContext<IRatingState, IRootState>,
    { transaction, rating }: ISaveRatingPayload
  ): Promise<void> {
    const tradingStatus =
      transaction.buyer.organisation.id === transaction.organisationId
        ? TradingStatus.Seller
        : TradingStatus.Buyer

    const payload = rating.map(({ category, rating }) => ({
      category: `${tradingStatus}:${category}`,
      rating,
    }))

    await commit('SET_IS_LOADING', true)

    try {
      await HttpService.request({
        path: `/ratings/${transaction.id}`,
        method: HttpMethod.Put,
        body: payload,
      })

      await commit('SET_IS_LOADING', false)
    } catch (error) {
      await commit('SET_IS_LOADING', false)
    }
  },
  async fetchTradingPartners(
    { commit }: ActionContext<IRatingState, IRootState>,
    id: string
  ): Promise<void> {
    try {
      await commit('SET_IS_TRADING_PARTNERS_LOADING', true)

      const response = (await HttpService.request({
        path: `/organisation/${id}/partners-rating`,
      })) as IRelationPartner[]

      await commit('SET_TRADING_PARTNERS', response)
      await commit('SET_IS_TRADING_PARTNERS_LOADING', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_TRADING_PARTNERS_LOADING', false)
    }
  },
  async fetchOwnRating(
    { commit }: ActionContext<IRatingState, IRootState>,
    id: string
  ): Promise<void> {
    try {
      await commit('SET_IS_OWN_RATING_LOADING', true)

      const response = (await HttpService.request({
        path: `/public-ratings/${id}`,
      })) as IRelationRating

      await commit('SET_OWN_RATING', response)
      await commit('SET_IS_OWN_RATING_LOADING', false)
    } catch (error) {
      if (error?.response?.status !== StatusCode.PageNotFound) {
        await commit('SET_ERROR', error)
      }
      await commit('SET_IS_OWN_RATING_LOADING', false)
    }
  },
  async fetchPublicRatings({
    commit,
  }: ActionContext<IRatingState, IRootState>): Promise<void> {
    try {
      await commit('SET_IS_PUBLIC_RATINGS_LOADING', true)
      const response = (await HttpService.request({
        path: '/public-ratings',
      })) as IRelationRating[]

      await commit('SET_PUBLIC_RATINGS', response)
      await commit('SET_IS_PUBLIC_RATINGS_LOADING', false)
    } catch (error) {
      if (error?.response?.status !== StatusCode.PageNotFound) {
        await commit('SET_ERROR', error)
      }
      await commit('SET_IS_PUBLIC_RATINGS_LOADING', false)
    }
  },
  async onPublishRating(
    { commit }: ActionContext<IRatingState, IRootState>,
    { id, body }: { id: string; body: Object }
  ): Promise<void> {
    try {
      await commit('SET_IS_OWN_RATING_LOADING', true)

      const response = (await HttpService.request({
        method: HttpMethod.Patch,
        path: `/public-ratings/${id}`,
        body,
      })) as IRelationRating

      await commit('SET_OWN_RATING', response)
      await commit('SET_IS_OWN_RATING_LOADING', false)
    } catch (error) {
      await commit('SET_ERROR', error)
      await commit('SET_IS_OWN_RATING_LOADING', false)
    }
  },
}
