import { IRelationPartner, IRelationRating, RatedCategory } from '@/interfaces'
import { ITransaction } from '@/modules/transaction/resources'

export interface IRatingState {
  rating: RatedCategory[] | null
  isLoading: boolean
  isTradingPartnersLoading: boolean
  isOwnRatingLoading: boolean
  ownRating: IRelationRating | null
  tradingPartners: IRelationPartner[] | null
  error: Error | null
  isPublicRatingLoading: boolean
  publicRatings: { [organisationId: string]: IRelationRating } | null
}

export interface ISaveRatingPayload {
  transaction: ITransaction
  rating: RatedCategory[]
}

export const getDefaultState = (): IRatingState => {
  return {
    rating: null,
    isLoading: false,
    isTradingPartnersLoading: false,
    isOwnRatingLoading: false,
    ownRating: null,
    tradingPartners: null,
    error: null,
    isPublicRatingLoading: false,
    publicRatings: null,
  }
}
