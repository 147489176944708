import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { IRootState } from '@/interfaces'
import { getDefaultState } from './state'
import { IMarketplaceState } from './interfaces'

export const marketplace: Module<IMarketplaceState, IRootState> = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state: getDefaultState(),
}
