import { Auth } from '@aws-amplify/auth'
import AwsIot from 'aws-iot-device-sdk'
import { IClientSubscribeOptions } from 'mqtt'
import { config } from '@/config/config'
import { INotification } from '@/interfaces'
import AuthService from '@/services/AuthService/AuthService'

export interface IMessageEvent {
  topic: string
  listener: (notification: INotification) => void
}

const SOCKET_RECONNECT_ERRORS = ['403', 'not connected']

class SocketService {
  public isConnected = false
  public device?: AwsIot.device
  public isRefreshingSession = false
  public messageEventSubscriptions: IMessageEvent[] = []

  public async setup(userId: string) {
    const credentials = await Auth.currentUserCredentials()

    if (!credentials || !credentials.sessionToken) {
      return Promise.reject(new Error('There are no AWS config credentials'))
    }

    if (this.device) {
      this.device.updateWebSocketCredentials(
        credentials.accessKeyId,
        credentials.secretAccessKey,
        credentials.sessionToken,
        new Date(Date.now() + 1000 * 60 * 60 * 24)
      )
    } else {
      const clientId = Math.random().toString(36).substring(2)

      const awsDeviceOptions: AwsIot.DeviceOptions = {
        region: config.aws.region,
        host: config.aws.iotHost,
        clientId: `${userId}-${clientId}`,
        protocol: 'wss',
        accessKeyId: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      }

      // eslint-disable-next-line
      this.device = new AwsIot.device(awsDeviceOptions)
      this.device.on('connect', this.onSocketConnect.bind(this))
      this.device.on('reconnect', this.onSocketReconnect.bind(this))
      this.device.on('offline', this.onSocketOffline.bind(this))
      this.device.on('error', () => this.onSocketError.bind(this))
      this.device.on('close', () => this.onSocketClose.bind(this))
      this.device.on('message', this.onSocketMessage.bind(this))

      const subscribePromisses = this.messageEventSubscriptions.map(
        async (event: any) => {
          return this.subscribeMessageEvent(event, true)
        }
      )

      try {
        await Promise.all(subscribePromisses)
      } catch (error) {
        // TODO: add error logging!
        return Promise.reject(error)
      }
    }
  }

  public async subscribeMessageEvent(
    messageEvent: IMessageEvent,
    isReSubscribe?: boolean
  ) {
    return new Promise<void>((resolve, reject) => {
      if (!this.device) {
        return reject(new Error('There is no AWS IoT device'))
      }

      const options: IClientSubscribeOptions = {
        qos: 0, // Quality of Service
      }

      const topic = `${config.aws.env}/${messageEvent.topic}`

      this.device.subscribe(topic, options, (error) => {
        if (error) {
          // TODO: add error logging!
          return reject(error)
        }

        resolve()
      })

      if (!isReSubscribe) {
        this.messageEventSubscriptions.push(messageEvent)
      }
    })
  }

  public async unsubscribeMessageEvent(messageEvent?: IMessageEvent) {
    return new Promise<void>((resolve, reject) => {
      if (!messageEvent || !this.device) {
        return resolve()
      }

      this.messageEventSubscriptions = this.messageEventSubscriptions.filter(
        (event) => event !== messageEvent
      )
      this.device.unsubscribe(
        `${config.aws.env}/${messageEvent.topic}`,
        (error: any) => {
          if (error) {
            // TODO: add error logging!
            return reject(error)
          }

          resolve()
        }
      )
    })
  }

  public disconnectSockets() {
    this.messageEventSubscriptions = []
    if (this.device) {
      this.device.end()
      this.device = undefined
    }
  }

  public async refreshSession() {
    if (this.isRefreshingSession) {
      return
    }

    try {
      this.isRefreshingSession = true
      await AuthService.refreshSession()
      this.isRefreshingSession = false
    } catch (error) {
      // TODO: add error logging!

      this.isRefreshingSession = false
      setTimeout(() => this.refreshSession.bind(this), 8e3)
    }
  }

  public onSocketConnect() {
    this.isConnected = true
  }

  public onSocketReconnect() {
    this.isConnected = false
  }

  public onSocketOffline() {
    this.isConnected = false
  }

  public async onSocketClose() {
    this.isConnected = false
    await this.refreshSession()
  }

  public async onSocketError(error?: string | Error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
        ? error
        : ''

    // There is no way I found, to get the real status code.
    // The complete error messages are e.g.
    // - "received bad response code from server 403"
    // - "Expected HTTP 101 response but was '403 Forbidden'"
    // - "The operation couldn’t be completed. Socket is not connected"
    // - "failed: Error during WebSocket handshake: Unexpected response code: 403"
    const unauthorizedMessages = SOCKET_RECONNECT_ERRORS.filter((message) =>
      errorMessage.includes(message)
    )

    if (unauthorizedMessages.length) {
      this.isConnected = false
      await this.refreshSession()
    }
  }

  public onSocketMessage(topic: string, payload: any) {
    this.messageEventSubscriptions.forEach((messageEvent) => {
      if (topic === `${config.aws.env}/${messageEvent.topic}`) {
        try {
          const notification: INotification = JSON.parse(payload)
          messageEvent.listener(notification)
        } catch (error) {
          console.error(error)
          // TODO: add error logging
        }
      }
    })
  }
}

export default new SocketService()
