import { ITableData } from '@/utils/TableDataUtil/TableDataUtil'
import { IOrganisationState } from './state'
import { IPartnersOrganisations, IPaymentCondition } from '@/interfaces'
import { removeItemWithSameId, replaceItemWithSameId } from '@/utils/utils'

export const mutations = {
  SET_TABLE_DATA: (
    state: IOrganisationState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.next = options.tableData.next
    state.organisations = options.previousNext
      ? (state.organisations || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_IS_LOADING: (
    state: IOrganisationState,
    isOrganisationsLoading: boolean
  ) => {
    state.isLoading = isOrganisationsLoading
  },
  SET_IS_LOADING_NEXT: (
    state: IOrganisationState,
    isOrganisationsLoading: boolean
  ) => {
    state.isLoadingNext = isOrganisationsLoading
  },
  SET_ERROR: (state: IOrganisationState, error: Error) => {
    state.error = error
  },
  SET_FILTERS: (state: IOrganisationState, filters: any) => {
    state.filters = filters
  },
  SET_USERS_TABLE_DATA: (
    state: IOrganisationState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.usersNext = options.tableData.next
    state.users = options.previousNext
      ? (state.users || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_USERS_IS_LOADING: (
    state: IOrganisationState,
    isOrganisationUsersLoading: boolean
  ) => {
    state.isUsersLoading = isOrganisationUsersLoading
  },
  SET_USERS_IS_LOADING_NEXT: (
    state: IOrganisationState,
    isOrganisationUsersLoading: boolean
  ) => {
    state.isUsersLoadingNext = isOrganisationUsersLoading
  },
  SET_PAYMENT_CONDITIONS_TABLE_DATA: (
    state: IOrganisationState,
    options: { tableData: ITableData; previousNext: string | null }
  ) => {
    state.paymentConditionsNext = options.tableData.next
    state.paymentConditions = options.previousNext
      ? (state.paymentConditions || []).concat(options.tableData.rows)
      : options.tableData.rows
  },
  SET_PAYMENT_CONDITIONS_IS_LOADING: (
    state: IOrganisationState,
    isPaymentConditionsLoading: boolean
  ) => {
    state.isPaymentConditionsLoading = isPaymentConditionsLoading
  },
  SET_PAYMENT_CONDITIONS_IS_LOADING_NEXT: (
    state: IOrganisationState,
    isPaymentConditionsLoadingNext: boolean
  ) => {
    state.isPaymentConditionsLoadingNext = isPaymentConditionsLoadingNext
  },
  UPDATE_PAYMENT_CONDITION: (
    state: IOrganisationState,
    paymentCondition: IPaymentCondition
  ) => {
    state.paymentConditions = replaceItemWithSameId(
      state.paymentConditions || [],
      paymentCondition
    )
  },
  REMOVE_PAYMENT_CONDITION: (
    state: IOrganisationState,
    paymentCondition: IPaymentCondition
  ) => {
    state.paymentConditions = removeItemWithSameId(
      state.paymentConditions || [],
      paymentCondition
    )
  },
  ADD_PAYMENT_CONDITION: (
    state: IOrganisationState,
    paymentCondition: IPaymentCondition
  ) => {
    state.paymentConditions = (state.paymentConditions || []).concat(
      paymentCondition
    )
  },
  PARTNERS_ORGANISATIONS: (
    state: IOrganisationState,
    partnersOrganisations: IPartnersOrganisations[]
  ) => {
    state.partnersOrganisations = partnersOrganisations
  },
}
