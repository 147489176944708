import { IUser } from '@/interfaces'
import {
  getUserAvatarUrl,
  getOrganisationAvatarUrl,
} from '@/utils/AssetUtil/AssetUtil'

export interface IAuthState {
  isSignedIn: boolean
  isSignedUp: boolean
  isLoading: boolean
  isUserLoading: boolean
  user: IUser | null
  userAvatarUrl: string
  organisationAvatarUrl: string
  notConfirmedUserEmail: string
  isUserProfileModalVisible: boolean
}

export const getDefaultState = (): IAuthState => {
  return {
    isSignedIn: false,
    isSignedUp: false,
    isLoading: false,
    isUserLoading: false,
    user: null,
    userAvatarUrl: getUserAvatarUrl({}),
    organisationAvatarUrl: getOrganisationAvatarUrl(),
    notConfirmedUserEmail: '',
    isUserProfileModalVisible: false,
  }
}
