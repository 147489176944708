import { config } from '@/config/config'
import * as Sentry from '@sentry/browser'

declare let window: {
  gtag: (command: string, eventName: string, eventParams?: object) => void
  addEventListener: (name: string, callback: (error: Error) => false) => void
  location: { pathname: string }
}

interface ITrackingEvent {
  message: string
}

export default class TrackingService {
  constructor() {
    // Google Analytics
    if (config.googleAnalytics) {
      window.addEventListener('error', (error: Error) => {
        window.gtag('event', 'exception', {
          description: error.message,
          fatal: true,
        })

        return false
      })
    }

    // Sentry
    if (config.sentry) {
      Sentry.init({
        dsn: `https://${config.sentry.publicKey}@sentry.io/${config.sentry.projectId}`,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
      })
    }
  }

  public trackEvent(event: ITrackingEvent) {
    // Google Analytics
    if (config.googleAnalytics) {
      window.gtag('event', event.message)
    }

    // Sentry
    if (config.sentry) {
      Sentry.captureMessage(event.message)
    }
  }

  public trackError(error: Error) {
    if (config.sentry) {
      Sentry.captureException(error)
    }
  }

  public trackPageView(path?: string) {
    if (!path) {
      path = window.location.pathname
    }

    // Google Analytics
    if (config.googleAnalytics) {
      window.gtag('config', config.googleAnalytics.id, {
        page_path: path,
      })
    }
  }
}
