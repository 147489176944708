import { IContractState } from '@/modules/contract/state'

export const getters = {
  getContracts(state: IContractState) {
    return state.contracts
  },
  getContractsAdmin(state: IContractState) {
    return state.contractsAdmin
  },
  getAgreementAttempts(state: IContractState) {
    return state.agreementAttempts
  },
  getAgreementAttemptsAdmin(state: IContractState) {
    return state.agreementAttemptsAdmin
  },
  getAttemptLoader(state: IContractState) {
    return state.isAttemptLoading
  },
  getAttemptsLoader(state: IContractState) {
    return state.isAttemptsLoading
  },
  getError(state: IContractState) {
    return state.error
  },
  getSelectedContractId(state: IContractState) {
    return state.selectedContractId
  },
  getSelectedContractIdAdmin(state: IContractState) {
    return state.selectedContractIdAdmin
  },
  getContractsLoader(state: IContractState) {
    return state.isContractsLoading
  },
}
