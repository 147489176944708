import { IMaterialFormatGroup } from '@/interfaces'

export interface IMaterialFormatGroupState {
  materialFormatGroups: IMaterialFormatGroup[]
  isLoading: boolean
}

export const getDefaultState = (): IMaterialFormatGroupState => {
  return {
    materialFormatGroups: [],
    isLoading: false,
  }
}
