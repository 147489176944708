
























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { LocaleCode } from '@/enums'
import { countryFlagEmoji } from '@/utils/utils'
import { refreshLibsLocale } from '@/i18n'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'

export default defineComponent({
  name: 'LocaleSelect',
  components: { MarkdownComponent },
  props: {
    name: {
      type: String as PropType<string>,
      default: '',
    },
    standalone: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    size: {
      type: String as PropType<string>,
      default: 'mini',
    },
  },
  setup(props, { root, emit }) {
    const locale = computed({
      get: () => root.$i18n.locale,
      set: async (val: string) => {
        root.$i18n.locale = val
        refreshLibsLocale(val)

        if (props.standalone) {
          const user = root.$store.getters['auth/getUser']
          await root.$store.dispatch('auth/saveUser', { ...user, locale: val })
        } else {
          emit('input', val)
        }
      },
    })

    return {
      locale,
      locales: Object.values(LocaleCode),
      countryFlagEmoji,
    }
  },
})
