import { IOfferState, IOfferTemplate } from './interfaces'
import { IOffer } from '@/interfaces'

export const mutations = {
  SET_OFFER_TEMPLATES: (
    state: IOfferState,
    offerTemplates: IOfferTemplate[]
  ) => {
    state.offerTemplates = offerTemplates
  },
  SET_ERROR: (state: IOfferState, error: Error) => {
    state.error = error
  },
  SET_IS_LOADING: (state: IOfferState, isLoading: boolean) => {
    state.isLoading = isLoading
  },
  SET_OFFER: (state: IOfferState, offer: IOffer) => {
    state.offer = offer
  },
}
