import { IUser } from '@/interfaces'
import { IAuthState } from './state'
import { LocaleCode, OrganisationStatus } from '@/enums'
import { config } from '@/config/config'

export const getters = {
  getIsSignedIn(state: IAuthState): boolean {
    return state.isSignedIn
  },
  getIsLoading(state: IAuthState): boolean {
    return state.isLoading
  },
  getIsUserLoading(state: IAuthState): boolean {
    return state.isUserLoading
  },
  getIsUserCompleted(state: IAuthState): boolean {
    return state.user ? state.user.isCompleted : false
  },
  getUser(state: IAuthState): IUser | null {
    return state.user
  },
  getIsSignedUp(state: IAuthState): boolean {
    return state.isSignedUp
  },
  getNotConfirmedUserEmail(state: IAuthState): string {
    return state.notConfirmedUserEmail
  },
  getIsGlobalAdmin(state: IAuthState): boolean {
    return state.user ? state.user.isGlobalAdmin : false
  },
  getIsOrganisationAdmin(state: IAuthState): boolean {
    return state.user ? state.user.isOrganisationAdmin : false
  },
  getOrganisationStatus(state: IAuthState): OrganisationStatus {
    return state.user
      ? state.user.organisation.organisationStatus
      : OrganisationStatus.Draft
  },
  getIsOrganisationDisabled(state: IAuthState): boolean {
    return [
      OrganisationStatus.Disabled,
      OrganisationStatus.Draft,
      OrganisationStatus.Ready,
    ].includes(
      state.user?.organisation.organisationStatus || OrganisationStatus.Disabled
    )
  },
  isOrganisationRestricted(state: IAuthState): boolean {
    return (
      state.user?.organisation.organisationStatus ===
      OrganisationStatus.Restricted
    )
  },
  getOrganisationId(state: IAuthState): string | null {
    return state.user ? state.user.organisation.id : null
  },
  getIsUserProfileModalVisible(state: IAuthState): boolean {
    return state.isUserProfileModalVisible
  },
  isDeliveriesAvailable(state: IAuthState) {
    return (
      state.user?.isGlobalAdmin ||
      (config.features?.deliveries || []).includes(state.user?.organisationId)
    )
  },
  isDeliveriesMatchingAvailable(state: IAuthState) {
    return (
      state.user?.isGlobalAdmin ||
      (config.features?.matching || []).includes(state.user?.organisationId)
    )
  },
  getUserLocale(state: IAuthState): LocaleCode | null {
    return state.user?.locale || null
  },
}
