import { ActionContext } from 'vuex'
import { IAppState } from './state'
import { IRootState } from '@/interfaces'
import Cookies from 'js-cookie'

export const actions = {
  acceptCookies({ commit }: ActionContext<IAppState, IRootState>): void {
    Cookies.set('consent', true.toString(), { expires: 365 })
    commit('SET_HAS_COOKIE_CONSENT', true)
  },
}
