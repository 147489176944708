import keyBy from 'lodash/keyBy'
import { IRelationPartner } from '@/interfaces'
import { IRatingState } from './state'

export const getters = {
  rating(state: IRatingState) {
    return state.rating
  },
  isLoading(state: IRatingState): boolean {
    return state.isLoading
  },
  isOwnRatingLoading(state: IRatingState): boolean {
    return state.isOwnRatingLoading
  },
  ownRating(state: IRatingState) {
    return state.ownRating
  },
  isTradingPartnersLoading(state: IRatingState): boolean {
    return state.isTradingPartnersLoading
  },
  tradingPartners(state: IRatingState) {
    return state.tradingPartners
  },
  isPublicRatingLoading(state: IRatingState): boolean {
    return state.isPublicRatingLoading
  },
  publicRatings(state: IRatingState) {
    return state.publicRatings
  },
  partnerRatings({ tradingPartners }: IRatingState) {
    return tradingPartners
      ? keyBy(
          tradingPartners.map((partner: IRelationPartner) => ({
            toOrganisationId: partner.toOrganisationId,
            ...partner.merged,
          })),
          'toOrganisationId'
        )
      : {}
  },
}
