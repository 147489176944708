import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { FileAction, ImageType } from '@/enums'

export interface IImageUploadOptions {
  file: File
  url: string
  uid: number
  name: string
}

const getFilePath = (
  id: string,
  type: ImageType,
  file?: File | IImageUploadOptions
) => {
  switch (type) {
    case ImageType.User:
      return `users/${id}/avatar`
    case ImageType.Organisation:
      return `organisations/${id}/avatar`
    case ImageType.Offer:
      return `offers/${id}/images`
    case ImageType.OfferDelete:
      return `offers/${id}/images/${file?.name}`
  }
}

export const getImageUploadOptions = async (
  id: string,
  type: ImageType,
  file: File,
  action: FileAction
) => {
  const response = await HttpService.request({
    path: '/files/sign-request',
    method: HttpMethod.Post,
    body: {
      contentType: file.type,
      filePath: getFilePath(id, type, file),
      action,
    },
  })

  return {
    file,
    url: response.url,
  } as IImageUploadOptions
}

export const uploadImage = async (options: IImageUploadOptions) => {
  await fetch(options.url, {
    method: HttpMethod.Put,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': options.file.type,
      'Access-Control-Allow-Origin': '1',
    },
    body: options.file,
  })
}

export const deleteImage = async (options: IImageUploadOptions) => {
  await fetch(options.url, {
    method: HttpMethod.Delete,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
}
