import { IDeliveriesByDate, IDelivery } from '@/interfaces'

export interface IDeliveryState {
  deliveries: IDelivery[]
  deliveriesAggregated: IDeliveriesByDate
  isLoading: boolean
  error: Error | null
}

export interface ISetAggregatedDeliveriesPayload {
  deliveriesAggregated: IDeliveriesByDate
  offerId: string
}

export const getDefaultState = (): IDeliveryState => {
  return {
    deliveries: [],
    deliveriesAggregated: {},
    isLoading: false,
    error: null,
  }
}
