import { IMaterialType } from '@/interfaces'

export interface IMaterialTypesState {
  materialTypes: IMaterialType[]
  isLoading: boolean
  error: Error | null
}

export const getDefaultState = (): IMaterialTypesState => {
  return {
    materialTypes: [],
    isLoading: false,
    error: null,
  }
}
