import { INotificationState } from './state'
import { sortBy } from '@/utils/utils'
import { NotificationAction } from '@/enums'

export const getters = {
  notifications(state: INotificationState) {
    if (!state.notifications?.length) {
      return null
    }

    return sortBy(state.notifications, 'createdAt')
  },
  getIsLoading(state: INotificationState) {
    return state.isLoading
  },
  getError(state: INotificationState) {
    return state.error
  },
  allNotifications(state: INotificationState) {
    return (state.allNotifications || []).filter(
      (notification) =>
        notification.action !== NotificationAction.BidAttemptCreated
    )
  },
  next(state: INotificationState): string | null {
    return state.next
  },
  isLoadingNext(state: INotificationState) {
    return state.isLoadingNext
  },
}
